<template>
<div class="w-full flex flex-wrap">
    <div class="flex flex-col mt-6 pr-4">
        <r-e-label for="filter_start" value="inicio" />
        <vue-date-picker
        name="filter_start"
        v-model="filter_start"
        model-type="yyyy-MM-dd"
        format="yyyy-MM-dd"
        :enable-time-picker="false"
        locale="es"
        input-class-name="w-full"
         @update:model-value="updateFilters()">
        </vue-date-picker>
    </div>
    <div class="flex flex-col mt-6 pr-4">
        <r-e-label for="filter_end" value="fin" />
        <vue-date-picker
        name="filter_end"
        v-model="filter_end"
        model-type="yyyy-MM-dd"
        format="yyyy-MM-dd"
        :enable-time-picker="false"
        locale="es"
        input-class-name="w-full"
         @update:model-value="updateFilters()">
        </vue-date-picker>
    </div>

    <select-input v-model="filter_month" @change="setMonthFilter(updateFilters)" placeholder="Mes">
        <option value="">Filtrar Mes</option>
        <option value="01">Enero</option>
        <option value="02">Febrero</option>
        <option value="03">Marzo</option>
        <option value="04">Abril</option>
        <option value="05">Mayo</option>
        <option value="06">Junio</option>
        <option value="07">Julio</option>
        <option value="08">Agosto</option>
        <option value="09">Septiembre</option>
        <option value="10">Octubre</option>
        <option value="11">Noviembre</option>
        <option value="12">Diciembre</option>
        <option value="13">Todo el año</option>
    </select-input>

    <select-input v-model="filter_year" @change="setMonthFilter(updateFilters)" placeholder="Año">
        <option value="">Filtrar año</option>
        <option v-for="year in available_years" :key="year" :value="year">{{ year }}</option>
    </select-input>
</div>
</template>

<script>
import moment from "moment";
import SelectInput from '@/components/SelectInput.vue';
import activeFiltersMixin from "@/mixins/activeFilters";

export default {
name: "DateFilters",

components: {
    SelectInput
},

mixins: [activeFiltersMixin],

props: ['modelvalue', 'onLoad'],

data() {
    return {
        date_object: {},
    };
},

created() {
    this.getFilters();
},

methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.filter_start = filters.filter_start || moment().startOf('year').format('yyyy-MM-DD');
      this.filter_end = filters.filter_end || moment().endOf('year').format('yyyy-MM-DD');
      this.filter_month = filters.filter_month || 13;
      if (filters.filter_year) this.filter_year = filters.filter_year;

      this.date_object = {
        filter_start: this.filter_start,
        filter_end: this.filter_end,
        filter_year: this.filter_year,
        filter_month: this.filter_month,
      }

      this.emitDates();
      this.$emit("onLoad");
    },

    updateFilters() {
        this.date_object = {
          filter_start: this.filter_start,
          filter_end: this.filter_end,
          filter_year: this.filter_year,
          filter_month: this.filter_month,
        };
        let filters = this.getActiveFilters();
        this.setActiveFilters({
            ...filters,
            ...this.date_object,
        });
        this.emitDates();
    },

    formatDate(date) {
        return moment(date).locale("es").format("LL");
    },

    emitDates() {
        this.$emit("update:modelValue", this.date_object);
    },
},
};
</script>