<template>
  <div class="">
    <div v-if="event">
      <div class="md:columns-2 sm:columns-1 shadow-lg my-3 p-4 rounded w-full border">
        <div class="flex mt-3">
          <div class="font-bold">Nombre del evento:</div>
          <div class="ml-2">{{ event.name }}</div>
        </div>

        <div class="flex mt-3 justify-between items-center">
          <button class="primary-button" @click="showCloneEventModal = true">Clonar Evento</button>
        </div>
      </div>
      <div class="md:columns-2 sm:columns-1 shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Detalles del evento</h3>

        <div class="flex mt-3">
          <div class="font-bold">Cliente:</div>
          <div class="ml-2">{{ event.customer.full_name }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Vendedor:</div>
          <div class="ml-2" v-if="event.seller">{{ event.seller.name }}</div>
          <div class="ml-2" v-else></div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Correo:</div>
          <div class="ml-2">{{ event.customer.email }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Teléfono:</div>
          <div class="ml-2">{{ event.customer.phone }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Tipo de evento:</div>
          <div class="ml-2">{{ event.event_type }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Duración del evento:</div>
          <div class="ml-2">{{ event.event_days }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Fecha del evento:</div>
          <div class="ml-2">{{ formatDate(event.event_date) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Estatus:</div>
          <div class="ml-2">{{ event.status.display_name }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descripción (pública):</div>
          <div class="ml-2">{{ event.description }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descripción (interna):</div>
          <div class="ml-2">{{ event.setup_instructions }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Color del evento:</div>
          <div :style="{
            height: '25px',
            width: '70px',
            margin: '10px',
            'background-color': event.background_color
          }"></div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Es REPSE:</div>
          <div class="ml-2">{{ event.is_repse ? 'Si' : 'No' }}</div>
        </div>

        <div class="flex mt-3 justify-between items-center">
          <router-link class="primary-button"
                :to="{
                    name: 'quotes_quote',
                    params: {
                      id: this.event.id,
                      event: this.event,
                    },
                  }">Editar Cotizacion/Evento</router-link>
        </div>

        <div class="flex mt-3 justify-between items-center">
          <button class="primary-button" @click="showChangeStatusModal = true">Cambiar Estatus</button>
        </div>

        <div class="flex mt-3 justify-between items-center">
          <button class="primary-button" @click="generatePDF">Generar PDF</button>
        </div>
      </div>

      <div v-if="event.status.id == 5 || event.status.id == 6" class="md:columns-2 sm:columns-1 shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Motivo de cancelación o rechazo</h3>
        <div class="flex mt-3">
          <div class="ml-2">{{ event.status_comment }}</div>
        </div>
       </div>

      <div class="md:columns-2 sm:columns-1 shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Logística</h3>

        <div class="flex mt-3">
          <div class="font-bold">Fecha de inicio de montaje:</div>
          <div class="ml-2">{{ formatDate(event.setup_date_start) }}</div>
        </div>
        <div class="flex mt-3">
          <div class="font-bold">Fecha de fin de montaje:</div>
          <div class="ml-2">{{ formatDate(event.setup_date_end) }}</div>
        </div>
        <div class="flex mt-3">
          <div class="font-bold">Fecha de inicio de desmontaje:</div>
          <div class="ml-2">{{ formatDate(event.dismount_date_start) }}</div>
        </div>
        <div class="flex mt-3">
          <div class="font-bold">Fecha de fin de desmontaje:</div>
          <div class="ml-2">{{ formatDate(event.dismount_date_end) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Dirección del evento:</div>
          <div class="ml-2">{{ event.event_address }}</div>
        </div>

        <a
          href="event.location_url"
          target="_blank"
          rel="noopener noreferrer"
          class="mt-2 underline button-interactive"
          >{{ event.location_url }}</a
        >

        <router-link class="flex mt-3 primary-button"
            :to="{
              name: 'events_edit_logistics',
              params: {
                id: this.event.id
              }
            }">
            Editar Logística
        </router-link>
      </div>

      <div class="md:columns-2 sm:columns-1 flex-col shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Finanzas</h3>

        <div class="flex mt-3">
          <div class="font-bold">Precio primer día:</div>
          <div class="ml-2">{{ formatPrice(event.first_day_price) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Precio día adicional:</div>
          <div class="ml-2">{{ formatPrice(event.additional_day_price) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descuento día adicional:</div>
          <div class="ml-2">{{ formatPercentage(event.additional_day_discount) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Subtotal:</div>
          <div class="ml-2">{{ formatPrice(event.subtotal) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Envío:</div>
          <div class="ml-2">{{ formatPrice(event.shipping_total) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descuento General:</div>
          <div class="ml-2">{{ formatPercentage(event.discount_percentage) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">IVA:</div>
          <div class="ml-2">{{ formatPrice(event.taxes) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Retención de impuestos:</div>
          <div class="ml-2">{{ formatPrice(event.tax_retention) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Requiere factura:</div>
          <div class="ml-2">{{ event.cfdi_required ? 'Si' : 'No' }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Total:</div>
          <div class="ml-2">{{ formatPrice(event.total) }}</div>
        </div>
      </div>

      <div class="md:columns-2 sm:columns-1 flex-col shadow-lg my-3 p-4 rounded w-full border">
        <div class="flex mt-3">
          <div class="font-bold">Pagos:</div>
          <div class="ml-2">{{ formatPrice(totalPayed) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Resta por pagar:</div>
          <div class="ml-2">{{ formatPrice(balance) }}</div>
        </div>
      </div>

    </div>

  <div v-if="showChangeStatusModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
    <div class="form relative">
        <div>Estatus actual: {{ event.status.display_name }}</div>

        <select-input v-model="newStatus">
          <option value="1">Cotización</option>
          <option value="7">Cotización Destacada</option>
          <option value="2">Aceptado</option>
          <option value="3">Montado</option>
          <option value="4">Desmontado/Terminado</option>
          <option value="5">Rechazado</option>
          <option value="6">Cancelado</option>
          <optgroup label="____Admin____" v-if="user.role == 'admin'">
            <option value="delete" v-if="user.role == 'admin'">Borrar Cotización/Evento</option>
          </optgroup>
        </select-input>

        <div class="flex flex-col mt-6" v-if="newStatus == 5 || newStatus == 6">
          <r-e-label for="description" value="Razón / Comentarios" />
          <textarea class="border border-gray-700 py-2 px-3 outline-none hover:border-secondary-500 focus:border-secondary-500 smooth" v-model="status_comment"></textarea>
        </div>

        <r-e-primary-button
          class="w-full mt-4"
          @buttonClicked="confirmStatusChange"
          >Cambiar Estatus
        </r-e-primary-button>

        <button class="link-button mt-4 mx-auto w-full" @click.prevent="showChangeStatusModal = false">
          Cancelar
        </button>
    </div>
  </div>

  <div v-if="showCloneEventModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
    <div class="form relative">
        <p>Confirmación de clonar evento.</p>

        <r-e-primary-button
          class="w-full mt-4"
          @buttonClicked="cloneEvent"
          >Clonar Evento
        </r-e-primary-button>

        <button class="link-button mt-4 mx-auto w-full" @click.prevent="showCloneEventModal = false">
          Cancelar
        </button>
    </div>
  </div>
  </div>
</template>

<script>
import moment from "moment";
import { integerToCurrency } from "@/utils/currency";
import SelectInput from '@/components/SelectInput.vue';
import clientRequest from "@/api/clientRequest";
import { is422 } from "@/utils/response";

export default {
  components: { SelectInput },
  name: "Detalle",

  props: {
    setEvent: Object,
  },

  data() {
    return {
      newStatus: 0,
      showChangeStatusModal: false,
      showCloneEventModal: false,
      totalPayed: 0,
      balance: 0,
      event: {},
      error_message: null,
      response: null,
      user: null,
    };
  },

  created() {
    this.user = this.$store.state.user;
    this.event = this.setEvent;
    this.newStatus = this.event.status.id;
    this.status_comment = this.event.status_comment;

    this.event.payments.forEach((payment) => {
      this.totalPayed += payment.amount;
    })

    this.balance = this.event.total - this.totalPayed;
  },

  methods: {
    formatDate(date) {
      return moment(date).locale("es").format("LLL");
    },
    formatPrice(price) {
      return integerToCurrency(price);
    },
    formatPercentage(percentage) {
      return percentage + "%";
    },
    async confirmStatusChange() {
      if (this.newStatus == 'delete') {
        this.deleteProject();
        return;
      }
      this.loading = true;

      try {
          let data = await clientRequest.changeEventStatus(this.event.id, {
            status_id: this.newStatus,
            status_comment: this.status_comment,
          });

          this.$store.dispatch("successSnack", "Guardado exitosamente.");
          this.event.status = data.data.data.status;
          this.event.status_comment = data.data.data.status_comment;
          this.showChangeStatusModal = false;
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    },

    async deleteProject() {
      this.loading = true;

      try {
          let data = await clientRequest.deleteEvent(this.event.id);

          this.$store.dispatch("successSnack", "Evento eliminado");
          this.$router.push({ name: "events_index" });
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    },
    
    async generatePDF() {
      // await clientRequest.generateEventPDF(this.event.id);
      await clientRequest.generateEventPDF(this.event.id).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        let event_date = moment(this.event.event_date).format('DD-MMM-YYYY');
        link.setAttribute('download', this.event.customer.full_name+'-'+event_date+'.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },

    async cloneEvent() {
      this.loading = true;

      try {
          let new_event = await clientRequest.cloneEvent({project_id: this.event.id});

          if(new_event.data.status == 'success') {
          this.$store.dispatch("successSnack", "Evento Clonado");
          this.$router.push({
              name: 'events_dashboard',
              params: {
                id: new_event.data.cloned_project_id,
              }
            });
          }
          this.$emit('reload', { event_id: new_event.data.cloned_project_id });
          this.showCloneEventModal = false;
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    },
  },
};
</script>