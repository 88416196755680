// Clients
import ClientsRouter from "./../views/dashboard/clients/ClientsRouter";
import ClientsIndex from "./../views/dashboard/clients/Index";
import ClientsForm from "./../views/dashboard/clients/Form";

// Detail
import ClientsDetailRouter from "./../views/dashboard/clients/detail/DetailRouter";
import ClientsDetailIndex from "./../views/dashboard/clients/detail/Index";

// Contacts
import ContactsRouter from "./../views/dashboard/clients/detail/contacts/ContactsRouter";
import ContactsIndex from "./../views/dashboard/clients/detail/contacts/Index";
import ContactsForm from "./../views/dashboard/clients/detail/contacts/Form";

export const clients_routes = {
    path: "/clientes",
    component: ClientsRouter,
    name: "clients",
    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
    redirect: { name: "clients_index" },
    children: [
        {
            path: "indice",
            component: ClientsIndex,
            name: "clients_index",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
        },
        {
            path: "agregar",
            component: ClientsForm,
            name: "clients_add",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
        },
        {
            path: "editar/:id",
            component: ClientsForm,
            name: "clients_edit",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
        },
        {
            path: "detalle/:id",
            component: ClientsDetailRouter,
            name: "clients_detail",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
            redirect: { name: "clients_detail_index" },
            children: [
                {
                    path: "",
                    component: ClientsDetailIndex,
                    name: "clients_detail_index",
                    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                },
                {
                    path: "contactos",
                    component: ContactsRouter,
                    name: "contacts",
                    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                    redirect: { name: "contacts_index" },
                    children: [
                        {
                            path: "indice",
                            component: ContactsIndex,
                            name: "contacts_index",
                            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                        },
                        {
                            path: "agregar",
                            component: ContactsForm,
                            name: "contacts_add",
                            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                        },
                        {
                            path: "editar/:id",
                            component: ContactsForm,
                            name: "contacts_edit",
                            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                        },
                    ]
                },
            ]
        },
    ]
};