<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteContact"
      ></delete-modal>
    </transition>

    <router-link class="primary-button mt-6 w-56" :to="{ name: 'contacts_add' }">
      <span class="text-xl mr-2">+</span>Agregar Contacto</router-link
    >

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Nombre</th>
          <th class="p-2 border-x">Correo</th>
          <th class="p-2 border-x"># de celular</th>
          <th class="p-2 border-x">Forma de contacto</th>
          <th class="p-2 border-x">Puesto</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="contact in contacts" :key="contact.id">
          <td class="p-2 border-r">{{ contact.id }}</td>
          <td class="p-2 border-x">{{ contact.name }}</td>
          <td class="p-2 border-x">{{ contact.email }}</td>
          <td class="p-2 border-x">{{ contact.phone }}</td>
          <td class="p-2 border-x">{{ contact.contact_method }}</td>
          <td class="p-2 border-x">{{ contact.job_title }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'contacts_edit',
                  params: {
                    id: contact.id,
                    contact: contact,
                  },
                }"
              />
              <r-e-delete-button @buttonClicked="openDelete(contact.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import deleteMixin from "../../../../../mixins/deleteMixin";
export default {
  name: "Indice",

  data() {
    return {
      contacts: [
        {
          id: 1,
          name: "Contacto uno",
          phone: "31414123",
          email: "contactouno@test.com",
          contact_method: "WhatsApp",
          job_title: "Ventas" 
        }
      ]
    };
  },

  mixins: [deleteMixin],

  methods: {
    openDelete(id) {
      this.openDeleteModal();
    },

    deleteRole() {
      this.closeDeleteModal();
    },
  },
};
</script>