<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteCategory"
      ></delete-modal>
    </transition>

    <div class="flex justify-between items-center mt-12">
      <router-link
        class="primary-button w-72"
        :to="{ name: 'categories_add' }"
      >
        <span class="text-xl mr-2">+</span>Registrar una nueva
        Categoría</router-link
      >

      <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
        Descargar Excel
      </button> -->
    </div>

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Nombre</th>
          <th class="p-2 border-x">Descripción</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="category in categories" :key="category.id">
          <td class="p-2 border-r">{{ category.id }}</td>
          <td class="p-2 border-x">{{ category.name }}</td>
          <td class="p-2 border-x">{{ category.description }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'categories_edit',
                  params: {
                    id: category.id,
                    category: category,
                  },
                }"
              />

              <r-e-delete-button @buttonClicked="openDelete(category.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import clientRequest from "../../../../api/clientRequest";
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
export default {
  name: "Indice",

  data() {
    return {
      categories: [],
      loading: false,
    };
  },

  mixins: [deleteMixin],

  created() {
    this.loadCategories();
  },

  methods: {
    async loadCategories() {
      this.loading = true;

      try {
        this.categories = (await clientRequest.getPaymentCategories()).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    openDelete(id) {
      this.openDeleteModal();
    },

    deleteIncome() {
      this.closeDeleteModal();
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    formatBool(val) {
      return val ? "Si" : "No";
    },
  },
};
</script>