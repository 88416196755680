<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteClient(selectedClientId)"
      ></delete-modal>
    </transition>

    <div v-if="!loading">
      <div class="flex justify-between items-center">
        <router-link class="primary-button" :to="{ name: 'clients_add' }">
          <span class="text-xl mr-2">+</span>Registrar nuevo
          cliente</router-link
        >
      </div>

      <r-e-input
        class="w-full mt-6"
        type="text"
        v-model="search"
        placeholder="Buscar cliente"
        clear-text="true"
      />

      <table class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Cliente</th>
            <th class="p-2 border-x">Correo electrónico</th>
            <th class="p-2 border-x">Teléfono</th>
            <th class="p-2 border-x">Forma de contacto</th>
            <th class="p-2 border-x">Referencia</th>
            <!-- <th class="p-2 border-x">Eventos activos</th>
          <th class="p-2 border-x">Eventos cerrados</th>
          <th class="p-2 border-x">Eventos cotizados</th> -->
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="client in filteredClients" :key="client.id">
            <td class="p-2 border-r">{{ client.id }}</td>
            <td class="p-2 border-x">{{ client.full_name }}</td>
            <td class="p-2 border-x">{{ client.email }}</td>
            <td class="p-2 border-x">{{ client.phone }}</td>
            <td class="p-2 border-x">{{ client.channel }}</td>
            <td class="p-2 border-x">{{ client.referred_by }}</td>
            <!-- <td class="p-2 border-x">{{ getActiveEvents(client.quotes) }}</td>
          <td class="p-2 border-x">{{ getClosedEvents(client.quotes) }}</td>
          <td class="p-2 border-x">{{ getQuoteEvents(client.quotes) }}</td> -->
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'clients_edit',
                    params: {
                      id: client.id,
                    },
                  }"
                />

                <r-e-detail-button
                  class="mr-2"
                  :to="{
                    name: 'clients_detail_index',
                    params: {
                      id: client.id,
                    },
                  }"
                />

                <r-e-delete-button @buttonClicked="openDelete(client.id)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import deleteMixin from "../../../mixins/deleteMixin";
import activeFiltersMixin from "@/mixins/activeFilters";

export default {
  name: "Indice",

  data() {
    return {
      clients: [],
      loading: false,
      search: "",
      selectedClientId: 0
    };
  },

  mixins: [deleteMixin, activeFiltersMixin],

  computed: {
    filteredClients() {
      this.setActiveFilters({query_text: this.search});
      if (this.search.length === 0) {
        return this.clients;
      }

      return this.clients.filter((client) => {
        return (
          client.full_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          (client.phone ? client.phone.toLowerCase().indexOf(this.search.toLowerCase()) > -1 : false)
        );
      });
    },
  },

  created() {
    this.getFilters();
    this.loadClients();
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.search = filters.query_text || '';
    },
    async loadClients() {
      this.loading = true;

      try {
        this.clients = (await clientRequest.getClients()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    openDelete(id) {
      this.selectedClientId = id;
      this.openDeleteModal();
    },

    deleteClient() {
      this.closeDeleteModal();
      this.deleteCustomer();
    },

    async deleteCustomer() {
      this.loading = true;

      try {
        await clientRequest.deleteClient(this.selectedClientId);
        this.loadClients();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
      this.loading = false;
    },

    getActiveEvents(events) {
      return events.filter((event) => {
        return event.status.slug === "active";
      }).length;
    },

    getClosedEvents(events) {
      return events.filter((event) => {
        return event.status.slug === "closed";
      }).length;
    },

    getQuoteEvents(events) {
      return events.filter((event) => {
        return event.status.slug === "quote";
      }).length;
    },
  },
};
</script>