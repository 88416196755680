export default [
    '#900C3F',
    '#eb6134',
    '#FF6969',
    '#FF9B50',
    '#ebd834',
    '#34eb95',
    '#4F709C',
    '#009FBd',
    '#9eeb34',
    '#00c851',
    '#94A684',
    '#3446eb',
    '#b134eb',
    '#9376E0',
    '#AAC4FF',
    '#CDF0EA',
    '#35155D',
    '#eb34b1',
    '#FFACC7',
    '#F07DEA',
    '#e2c799',
    '#6C3428',
];
