import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './tailwind.css'

//Components
import RELabel from "./components/RELabel";
import REInput from "./components/REInput";
import RESearchBox from "./components/RESearchBox";
import REPrimaryButton from "./components/REPrimaryButton";
import REEditButton from "./components/REEditButton";
import REDeleteButton from "./components/REDeleteButton";
import REDetailButton from "./components/REDetailButton";
import LoaderIcon from "./icons/LoaderIcon";
import Closable from "./directives/closable";

// import Autocomplete from '@trevoreyre/autocomplete-vue'
// import '@trevoreyre/autocomplete-vue/dist/style.css'

import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const app = createApp(App)
    .use(store)
    .use(router)
    .use(SimpleTypeahead)
    .use(Vue3ColorPicker)

app.component('r-e-label', RELabel)
    .component('r-e-input', REInput)
    .component('r-e-search-box', RESearchBox)
    .component('r-e-primary-button', REPrimaryButton)
    .component('r-e-edit-button', REEditButton)
    .component('r-e-delete-button', REDeleteButton)
    .component('r-e-detail-button', REDetailButton)
    .component('loader-icon', LoaderIcon)
    .component('VueDatePicker', VueDatePicker)

app.directive('closable', Closable);

app.mount('#app')