import { createStore } from 'vuex'
import { isLoggedIn, logIn, logOut } from "./../utils/auth";
import clientRequest from "./../api/clientRequest";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = createStore({
  plugins: [vuexLocal.plugin],
  state: {
      isLoggedIn: false,
      user: {},
      selectedTab: 'home',
      financesSelectedTab: 'income',
      snack: null,
      expiredPaymentsCount: 0,
  },

  mutations: {
      setUser(state, payload) {
          state.user = payload;
      },

      setLoggedIn(state, payload) {
          state.isLoggedIn = payload;
      },

      setSelectedTab(state, payload) {
          state.selectedTab = payload;
      },

      setFinancesSelectedTab(state, payload) {
          state.financesSelectedTab = payload;
      },

      setReportsSelectedTab(state, payload) {
        state.reportsSelectedTab = payload;
      },

      setSnack(state, payload) {
          state.snack = payload;
          setTimeout(() => (state.snack = null), 3000);
      },

      setExpiredPaymentsCount(state, payload) {
        state.expiredPaymentsCount = payload;
    },
  },

  actions: {
      setUser({ commit }, payload) {
          logIn(payload);
          let user = payload.user;
          user.role = user.roles[0].name;
          commit("setUser", payload.user);
          commit("setLoggedIn", true);
      },

      async loadUser({ commit, dispatch }) {
          if (isLoggedIn()) {
              try {
                  const user = (await clientRequest.me()).data.data;
                  commit("setUser", user);
                  commit("setLoggedIn", true);
              } catch (error) {
                  dispatch("logout");
              }
          }
      },

      logout({ commit }) {
          commit("setUser", {});
          commit("setLoggedIn", false);
          logOut();
      },

      errorSnack({ commit }, payload) {
          commit("setSnack", {
              message: payload,
              type: "error",
          });
      },

      successSnack({ commit }, payload) {
          commit("setSnack", {
              message: payload,
              type: "success",
          });
      },

  },
});

export default store
