<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="amount" value="Monto" />
          <r-e-input id="amount" type="number" min="1" step="any" v-model="form.amount"/>
        </div>

        <select-input v-model="form.bank_account_id" placeholder="Cuenta de ingreso">
          <option v-for="bank_account in bank_accounts" :key="bank_account.id" :value="bank_account.id">
            {{ bank_account.name }}
          </option>
        </select-input>

        <!-- <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Cliente o nombre de quien paga" />
          <r-e-input id="client" type="text" v-model="form.client" />
        </div> -->

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="date" value="Fecha de pago" />
          <r-e-input id="date" type="date" v-model="form.payed_at" />
        </div>

        <!-- <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Facturado" />
          <div class="flex items-center">
            <input
              class="mr-2"
              type="radio"
              v-model="form.invoice"
              :value="true"
            />Si
          </div>
          <div class="flex items-center mt-2">
            <input
              class="mr-2"
              type="radio"
              v-model="form.invoice"
              :value="false"
            />No
          </div>
        </div> -->

        <select-input v-model="form.project_id" placeholder="Evento">
          <option v-for="project in events" :key="project.id" :value="project.id">
            {{ project.id }}: {{ project.name }}
          </option>
        </select-input>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <!-- <div v-if="form.invoice" class="mt-6">
        <span class="mr-2">Subir XML</span>

        <input
          class=""
          type="file"
          name="xml"
          id="xml"
          ref="xml"
          accept="text/xml"
        />
      </div>

      <div v-if="form.invoice" class="mt-6">
        <span class="mr-2">Subir PDF</span>

        <input
          class=""
          type="file"
          name="pdf"
          id="pdf"
          ref="pdf"
          accept="application/pdf"
        />
      </div> -->

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>

  <div v-if="showDuplicatedModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
    <div class="form relative">
        <p>Pago posiblemente duplicado.</p>

        <p>Relación con el pago ID: {{ duplicatedPayment.id }} <br>
            <router-link
              class="mr-2 link-button"
              :to="{
                name: 'income_edit',
                params: {
                  id: duplicatedPayment.id,
                },
              }"
            >Ver Pago</router-link>
          </p>

        <r-e-primary-button
          class="w-full mt-4"
          @buttonClicked="saveIncome(true)"
          >Guardar de todos modos
        </r-e-primary-button>

        <button class="link-button mt-4 mx-auto w-full" @click.prevent="showDuplicatedModal = false">
          Cancelar
        </button>
    </div>
  </div>
</template>

<script>
import clientRequest from "@/api/clientRequest";
import { is422 } from "@/utils/response";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Formulario",

  components: {SelectInput},

  data() {
    return {
      isEditing: false,
      form: {
        amount: "",
        account: {},
        events: {},
        client: "",
        date: "",
        invoice: false,
        tag: {},
        description: "",
      },
      sellers: [],
      accounts: [],
      tags: [],
      events: [],
      bank_accounts: [],
      return_on_save: { name: "income_index" },
      showDuplicatedModal: false,
      duplicatedPayment: null,
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadIncome(this.$route.params.id);
    }
    if (this.$route.query.event_id) {
      this.form.project_id = this.$route.query.event_id;
      this.return_on_save = {
        name: 'events_dashboard',
        params: {
          id: this.form.project_id,
        },
      }
    }
    this.loadEvents();
    this.loadBankAccounts();
  },

  methods: {
    async loadIncome(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getPayment(id)).data.data;

        if (this.$route.query.event_id) {
          this.form.project_id = this.$route.query.event_id;
        }
        this.form.invoice = this.form.cfdi_required
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    // getImage(e) {
    //   let image = e.target.files[0];
    //   let reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.form.user_image = e.target.result;
    //   };
    // },

    async loadEvents() {
      this.loading = true;

      try {
        this.events = (await clientRequest.getEventsIds({})).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadBankAccounts() {
      this.loading = true;

      try {
        this.bank_accounts = (await clientRequest.getBankAccounts({type: 'income'})).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    save() {
      if (!this.isEditing) {
        this.saveIncome();
      } else {
        this.updateIncome();
      }
    },

    async saveIncome(override) {
      this.loading = true;
      this.form.is_income = true;
      if (override) {
        this.form.override_check = true;
      }

      try {
          await clientRequest.storePayment(this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push(this.return_on_save);
        } catch (error) {
          if (error.response.data.duplicated) {
            this.showDuplicatedModal = true;
            this.duplicatedPayment = error.response.data.duplicated;
          }
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    },

    async updateIncome() {
      this.loading = true;
      this.form.is_income = true;
      
      try {
          await clientRequest.updatePayment(this.form.id, this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push(this.return_on_save);
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    }
  },
};
</script>
