<template>
  <div
    class="
      flex flex-col
      justify-center
      items-center
      fixed
      inset-0
      bg-opacity-25 bg-black
    "

    style="z-index: 10000;"
  >
    <div class="form relative">
      <form action="">
        <div>¿Estás seguro que deseas eliminar este elemento?</div>

        <r-e-primary-button
          class="w-full mt-4"
          @buttonClicked="confirmDelete"
          >Eliminar
        </r-e-primary-button>

        <button class="link-button mt-4 mx-auto w-full" @click.prevent="close">
          Cancelar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
    
    confirmDelete() {
      this.$emit("confirmDelete");
    },

  },
};
</script>