import axios from "axios";
import store from "../store";
import router from "../router";

let BaseApi = axios.create({
    baseURL: "https://api.relumbra.com/ichizen/api/v1",
    headers: { 'Content-Type' : 'application/json', 'Accept' : 'application/json' }
});

BaseApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch("logout");
            router.push({ name: "unauthenticated"} );
        } else if (error.response.status === 403) {
            router.push({ name: "unverified"} );
        }

        return Promise.reject(error);
    }
);

let ClientApi = function() {
    let token = localStorage.getItem("token");

    if (token) {
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseApi;
};


export default ClientApi;
