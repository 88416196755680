<template>
    <div>
      <div class="flex justify-between items-center">
        <!-- <date-filter /> -->
  
        <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
          Descargar desglose de egresos
        </button> -->
        <select-input v-model="filter_year" @change="setMonthFilter()" placeholder="Año">
          <option value="">Filtrar año</option>
          <option v-for="year in available_years" :key="year" :value="year">{{ year }}</option>
        </select-input>
        <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadBalances()">Filtrar</r-e-primary-button>
      </div>
  
      <table class="table-auto mt-8 text-sm md:text-base w-full">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r"></th>
            <th class="p-2 border-x">ENE</th>
            <th class="p-2 border-x">FEB</th>
            <th class="p-2 border-x">MAR</th>
            <th class="p-2 border-x">ABR</th>
            <th class="p-2 border-x">MAY</th>
            <th class="p-2 border-x">JUN</th>
            <th class="p-2 border-x">JUL</th>
            <th class="p-2 border-x">AGO</th>
            <th class="p-2 border-x">SEP</th>
            <th class="p-2 border-x">OCT</th>
            <th class="p-2 border-x">NOV</th>
            <th class="p-2 border-x">DIC</th>
            <th class="p-2 border-x">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="balance in balances.bank_accounts" :key="balance.bank_account.id">
            <td class="p-2 border-r">{{ balance.bank_account.name }}</td>
            <td class="p-2 border-r" v-for="month in balance.months" :key="month">
              balance: {{ formatPrice(month.balance) }}<br>
              <span style="font-size: 12px;">
                ingreso: {{ formatPrice(month.income_abs) }}<br>
                egreso: {{ formatPrice(month.outcome_abs) }}<br>
                transfer ingreso: {{ formatPrice(month.transfer_in_abs) }}<br>
                transfer egreso: {{ formatPrice(month.transfer_out_abs) }}<br>
              </span>
            </td>
            <td class="p-2 border-r bold-text">{{ formatPrice(balance.total) }}</td>
          </tr>
          <tr class="bold-text">
            <td class="p-2 border-r">Total</td>
            <td class="p-2 border-r" v-for="total in balances.total_per_month" :key="total">{{ formatPrice(total.total) }}</td>
            <td class="p-2 border-r"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
<script>
import { integerToCurrency } from "@/utils/currency";
import clientRequest from "@/api/clientRequest";
import activeFiltersMixin from "@/mixins/activeFilters";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Balances",
  components: {SelectInput},
  mixins: [activeFiltersMixin],

  data() {
    return {
      balances: {},
    };
  },

  created() {
    this.getFilters();
    this.loadBalances();
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
    },
    async loadBalances() {
      this.loading = true;

      let params = {
        filter_year: this.filter_year,
      };
      this.setActiveFilters(params);

      try {
        this.balances = {};
        this.balances = (await clientRequest.getBankAccountBalances(params)).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },
    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>

<style>
.bold-text {
  font-weight: bold;
}
</style>