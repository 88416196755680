<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Nombre" />
          <r-e-input id="client" type="text" v-model="form.name" />
        </div>
      </div>

      <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="category" value="Categoría" />
        <div class="relative">
          <select
            class="
              border border-gray-700
              py-2
              px-3
              outline-none
              focus:border-secondary-500
              smooth
              appearance-none
              w-full
            "
            id="category"
            v-model="form.category_id"
          >
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import { is422 } from '../../../..//utils/response';
import clientRequest from "../../../../api/clientRequest";

export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        category_id: null,
        description: "",
      },
      categories: [],
    };
  },

  created() {
    if (this.$route.params.subcategory) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.subcategory));
      this.form.category_id = this.form.category.id;
    }

    this.loadCategories();
  },

  methods: {
    async loadCategories() {
      try {
        this.categories = (
          await clientRequest.getPaymentCategories()
        ).data.data;
      } catch (error) {
        this.$store.dispatch(
          "errorSnack",
          "Error, favor de contactar a soporte."
        );
      }
    },

    save() {
      if (!this.isEditing) {
        this.saveSubcategory();
      } else {
        this.updateSubcategory();
      }
    },

    async saveSubcategory() {
      this.loading = true;

      try {
        await clientRequest.storePaymentSubcategories(this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "subcategories_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }
    },

    async updateSubcategory() {
      this.loading = true;

      try {
        await clientRequest.updatePaymentSubcategories(this.form.id, this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "subcategories_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }
    },
  },
};
</script>