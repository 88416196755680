// Events
import EventsRouter from "./../views/dashboard/events/EventsRouter";
import EventsIndex from "./../views/dashboard/events/Index";
import EventsDashboard from "./../views/dashboard/events/Dashboard";
import EventsEditProducts from "./../views/dashboard/events/EditProducts";
import EventsEditLogistics from "./../views/dashboard/events/EditLogistics";

export const events_routes = {
    path: "/eventos",
    component: EventsRouter,
    name: "events",
    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
    redirect: { name: "events_index", roles: ['admin', 'seller', 'procurement'] },
    children: [
        {
            path: "indice",
            component: EventsIndex,
            name: "events_index",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
        },
        // {
        //   path: "editar/:id",
        //   component: EventsCreate,
        //   name: "events_edit",
        //   meta: { requiresLogin: true, roles: ['admin'] }
        // },
        {
            path: "editar_logistica/:id",
            component: EventsEditLogistics,
            name: "events_edit_logistics",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] }
        },
        // {
        //   path: "agregar/paso_dos",
        //   component: EventsProducts,
        //   name: "events_items",
        //   meta: { requiresLogin: true, roles: ['admin'] }
        // },
        {
            path: "dashboard/:id",
            component: EventsDashboard,
            name: "events_dashboard",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
        },
        {
            path: "editar_productos/:event",
            component: EventsEditProducts,
            name: "events_edit_products",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
        },
    ]
};