<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteClient"
      ></delete-modal>
    </transition>

    <transition name="modal">
      <delete-modal
        v-if="showDeleteEventModal"
        @close="closeDeleteEventModal"
        @confirmDelete="deleteEvent"
      ></delete-modal>
    </transition>


    <div v-if="client">
      <img class="w-36 bg-black" src="../../../../assets/relumbra_logo.png" alt="" />

      <div class="flex mt-3">
        <div class="font-bold">Cliente:</div>
        <div class="ml-2">{{ client.name }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Correo electrónico:</div>
        <div class="ml-2">{{ client.email }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Telefono cliente:</div>
        <div class="ml-2">{{ client.phone }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Planner:</div>
        <div class="ml-2">{{ client.planner_name }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Planner Telefono:</div>
        <div class="ml-2">{{ client.planner_phone }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Descuento default:</div>
        <div class="ml-2">{{ client.default_discount }}%</div>
      </div>

      <router-link class="link-button mt-6 block" :to="{ name: 'contacts' }"
        >Administrar Contactos</router-link
      >

      <div class="flex mt-6">
        <r-e-edit-button
          class="mr-2"
          :to="{
            name: 'clients_edit',
            params: {
              id: client.id,
              client: client,
            },
          }"
        />

        <r-e-delete-button @buttonClicked="openDeleteModal" />
      </div>

      <div class="mt-6 font-bold text-lg">Cotizaciones</div>

      <div class="flex justify-between items-center mt-3">
        <router-link
          class="primary-button w-56"
          :to="{ 
            name: 'quotes_quote',
            query: {
              customer_id: client.id,
            }, 
          }">
          <span class="text-xl mr-2">+</span>Agregar Cotización
        </router-link>
      </div>

      <r-e-input class="w-full mt-6" placeholder="Buscar cotización" clear-text="true" />

      <table class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">Estatus</th>
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Fecha Evento</th>
            <th class="p-2 border-x">Nombre del cliente</th>
            <th class="p-2 border-x">Tipo del evento</th>
            <th class="p-2 border-x">Nombre evento</th>
            <th class="p-2 border-x">Monto</th>
            <th class="p-2 border-l">Restante</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in events" :key="event.id">
            <td :class="{'p-2 border-r': true, 'yellow-background': event.status.name == 'mounted', 'gray-background': event.status.name == 'unmounted', ' bg-complement-500 text-white': event.status.name == 'accepted'}">{{ event.status.display_name }}</td>
            <td class="p-2 border-r">{{ event.id }}</td>
            <td class="p-2 border-r">{{ moment(event.event_date).format('DD MMM YYYY') }}</td>
            <td class="p-2 border-r">{{ event.customer ? event.customer.full_name : null }}</td>
            <td class="p-2 border-r">{{ event.event_type }}</td>
            <td class="p-2 border-r">{{ event.name }}</td>
            <td class="p-2 border-r">{{ formatPrice(event.total) }}</td>
            <td class="p-2 border-r">{{ formatPrice(calculateDifference(event)) }}</td>
            <td class="p-2 border-l">
              <div class="flex">

                <r-e-detail-button
                  class="mr-2"
                  :to="{
                    name: 'events_dashboard',
                    params: {
                      id: event.id
                    },
                  }"
                />

                <r-e-delete-button v-if="user.role == 'admin'" @buttonClicked="openDelete(event)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import clientRequest from "../../../../api/clientRequest";
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
import { is422 } from "@/utils/response";
import moment from 'moment';

export default {
  name: "Indice",

  data() {
    return {
      user: {},
      client: null,
      events: [],
      selectedEvent: {},
      moment: null,
      showDeleteEventModal: false,
    };
  },

  mixins: [deleteMixin],

  created() {
    this.user = this.$store.state.user;
    if (this.$route.params.id) {
      this.loadClient(this.$route.params.id);
    }
    this.moment = moment;
  },

  methods: {
    async loadClient(id) {
      this.loading = true;

      try {
        this.client = (await clientRequest.viewClient(id)).data.data;
        this.loadEvents();
      } catch (error) {
        console.log('error', error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    
    async loadEvents() {
      this.loading = true;

      try {
        this.events = (await clientRequest.getEventsFromClient(this.client.id)).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    openDelete(event) {
      this.selectedEvent = event;
      this.showDeleteEventModal = true;
    },

    closeDeleteEventModal() {
      this.selectedEvent = {};
      this.showDeleteEventModal = false;
    },

    async deleteEvent() {
      this.loading = true;

      try {
          let data = await clientRequest.deleteEvent(this.selectedEvent.id);

          this.$store.dispatch("successSnack", "Evento eliminado");
          this.events.splice(this.events.indexOf(this.selectedEvent), 1);
          this.selectedEvent = {};
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
      this.loading = false;
    },

    calculateTotalPayments(event) {
      return event.payments.reduce((total, payment) => total + payment.amount, 0);
    },
    calculateDifference(event) {
      return event.total - this.calculateTotalPayments(event);
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    deleteClient() {
      this.closeDeleteModal();
    },
  },
};
</script>

<style scoped>
.yellow-background {
  background-color: #f4fd68;
}

.negativeDifference {
  background-color: #00ffff !important;
  color: black;
}

.pink-background {
  background-color: #ff577f;
}

.gray-background {
  background-color: #f1f1f1;
}
</style>