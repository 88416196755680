<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre de ubicación" />
          <r-e-input id="name" type="text" v-model="form.name" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="address" value="Dirección" />
          <r-e-input id="address" type="text" v-model="form.address" />
        </div>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        address: ""
      },
    };
  },

  created() {
    if (this.$route.params.location) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.location));
    }
  },

  methods: {
    async save() {
      this.$router.push({ name: "locations" });
    },
  },
};
</script>