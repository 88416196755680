<template>
    <div class="w-full flex flex-wrap">
        <div class="">
          <date-filters v-model="date_filters" @onLoad="loadReport()"></date-filters>
          <div class="w-full flex flex-wrap">
            <select-input v-model="filterStatuses">
              <option value="">Todos</option>
              <option :value="['quote', 'quote_important']">Cotización</option>
              <option :value="['accepted', 'mounted']">Aceptados/Montados</option>
              <option :value="['unmounted']">Desmontados/Finalizados</option>
              <option :value="['cancelled', 'rejected']">Cancelados/Rechazados</option>
            </select-input>
          </div>

          <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadReport()">Filtrar</r-e-primary-button>
        </div>
      </div>
      <div class="w-full flex flex-wrap">
        <div class="w-full graph_container">
            <div class="font-bold text-lg">Ventas mensuales</div>
            <div class="">Sumatoria del total de cada evento según la fecha del evento</div>
            <Bar
                :options="chartOptions"
                :data="report"
            />
        </div>
    </div>
</template>
  
<script>
  import { integerToCurrency } from "@/utils/currency";
  import clientRequest from "@/api/clientRequest";
  import moment from "moment";
  import { Bar } from "vue-chartjs";
  import SelectInput from '@/components/SelectInput.vue';
  import DateFilters from '@/components/DateFilters.vue';
  
  export default {
    name: "SaleChannel",

    components: {
        Bar,
        SelectInput,
        DateFilters
    },
  
    data() {
      return {
        report: {labels: ['a'], datasets:[ {data: [1]}]},
        chartOptions: {
            responsive: true,
        },
        filter_status_id: [],
        date_filters: {},
        users: [],
      };
    },
  
    created() {
    },
  
    methods: {
        async loadReport() {
        this.loading = true;

        let params = {
          start_date: this.date_filters.filter_start,
          end_date: this.date_filters.filter_end,
          status_ids: this.filter_status_id,
        };
  
        try {
          this.report = (await clientRequest.ReportRequests.salesMonths(params)).data.data;
        } catch (error) {
          console.log('error', error)
        }
  
        this.loading = false;
      },

      async loadUsers() {
        this.loading = true;

        let params = {
          filter_roles: ['seller', 'admin', 'procurement'],
        };

        try {
          this.users = (await clientRequest.getUsers(params)).data.data;
        } catch (error) {
          console.log('error', error);
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }

        this.loading = false;
      },

      formatPrice(price) {
        return integerToCurrency(price);
      },
      formatDate(date) {
        return moment(date).locale("es").format("LL");
      },
      formatBool(val) {
        return val ? "Si" : "No";
      },
    },
  };
  </script>
  
  <style>
  .bold-text {
  font-weight: bold;
  }
  </style>