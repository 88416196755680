<template>
  <div class="">
    <div class="title">Horarios</div>
    <loader-icon v-if="loading" class="h-8 w-8 mx-auto"></loader-icon>
    <full-calendar 
      ref="fullCalendar" 
      :options="calendarOptions" 
      @clickDate="handleEventClick"
      class="mt-6 h-full" />


      <div v-if="showProjectModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
      <div class="form relative">
          <div>
            <button class="link-button mt-4 float-right" style="width: fit-content;" @click.prevent="showProjectModal = false">
              Cerrar
            </button>
            <table class="details-table">
              <tr>
                <td>Id del evento</td>
                <td>{{ selectedEvent.id }}</td>
              </tr>
              <tr>
                <td>Evento</td>
                <td>{{ selectedEvent.name }}  ( <router-link
                    class="mr-2 link-button"
                    :to="{
                      name: 'events_dashboard',
                      params: {
                        id: selectedEvent.id,
                      },
                    }"
                  >Ver Evento</router-link>)</td>
              </tr>
              <tr>
                <td>Cliente</td>
                <td>{{ selectedEvent.customer.full_name }}</td>
              </tr>
              <tr v-if="user.role != 'installer'">
                <td>Teléfono del cliente</td>
                <td>{{ selectedEvent.customer.phone }}</td>
              </tr>
              <tr v-if="user.role == 'installer' && selectedEvent.customer.type && selectedEvent.customer.type.toLowerCase() == 'planner'">
                <td>Teléfono del "Planner"</td>
                <td>{{ selectedEvent.customer.phone }}</td>
              </tr>
              <tr v-if="selectedEvent.customer.planner_phone">
                <td>Teléfono del planner</td>
                <td>{{ selectedEvent.customer.planner_phone }} ({{ selectedEvent.customer.planner_name }})</td>
              </tr>
              <tr>
                <td>Fecha del evento</td>
                <td>{{ formatDate(selectedEvent.event_date) }}</td>
              </tr>
              <tr>
                <td>Fecha de montaje</td>
                <td>{{ formatDate(selectedEvent.setup_date_start) }}</td>
              </tr>
              <tr>
                <td>Fecha de desmontaje</td>
                <td>{{ formatDate(selectedEvent.dismount_date_start) }}</td>
              </tr>
              <tr>
                <td>Dirección del evento</td>
                <td>{{ selectedEvent.event_address }}</td>
              </tr>
              <tr>
                <td>Diseñador(a)</td>
                <td>{{ selectedEvent.seller ? selectedEvent.seller.name : null }}</td>
              </tr>
              <tr>
                <td>Notas</td>
                <td>{{ selectedEvent.setup_instructions }}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>
                  <Vue3ColorPicker format="rgb" shape="square" v-model:pureColor="selectedEvent.background_color" @update:pureColor="updateEventColor()"/>
                </td>
              </tr>
            </table>

            <div v-if="selectedEvent.files.length > 0" class="flex-col shadow-lg my-3 p-4 rounded w-full border">
              <h3 class="font-bold text-lg">Fotos de referencia</h3>
              <ul class="file-list" style="margin-bottom: 15px;">
                <li v-for="file in selectedEvent.files" v-bind:key="file.id">
                  <a :href="file.url" target="_blank">{{ file.name }}</a>
                </li>
              </ul>
            </div>

            <div class="flex-col shadow-lg my-3 p-4 rounded w-full border">
              <h3 class="font-bold text-lg">Productos para el evento</h3>

              <table class="table-auto w-full text-sm md:text-base">
                <thead class="text-left">
                  <tr class="border-b">
                    <th class="p-2 border-r">SKU</th>
                    <th class="p-2 border-r">Nombre</th>
                    <th class="p-2 border-x">Color</th>
                    <th class="p-2 border-x">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" v-for="product in selectedEvent.leasing.products" :key="product.id">
                    <td class="p-2 border-r">{{ product.id }}</td>
                    <td class="p-2 border-r" v-if="!product.compound_product.deleted_at">{{ product.compound_product.name }}</td>
                    <td class="p-2 border-r" v-else>{{ product.compound_product.name }} (Producto Borrado!)</td>
                    <td class="p-2 border-r">{{ product.color }}</td>
                    <td class="p-2 border-r">{{ product.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import clientRequest from "@/api/clientRequest";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Agenda",

  components: {
    FullCalendar,
  },

  data() {
    return {
      loading: false,
      selectedEvent: {},
      showProjectModal: false,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: "listWeek",
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        allDaySlot: true,
        eventMaxStack: 1,
        slotDuration: '00:15:00',
        slotEventOverlap: false,
        eventMinHeight: 90,
        eventShortHeight: 90,
        events: [],
        locale: esLocale,
        customButtons: {
          
        },
        headerToolbar: {
          end: "today prev,next dayGridMonth timeGridWeek listWeek",
          center: "",
          start: "title",
        },
        
        
        eventContent: function (eventInfo) {
          return { html: eventInfo.event.extendedProps.customHtml };
        },
        eventDidMount: (info) => {
          let color = info.event.extendedProps.eventColor;
          // check color contrast
          // var r = parseInt(color.substring(1,3),16);
          // var g = parseInt(color.substring(3,5),16);
          // var b = parseInt(color.substring(5,7),16);
          // if(info.event.extendedProps.eventId == 888) {
          //   console.log('color', color);
          //   let hex = this.parseRgbToHex(color);
          //   console.log('hex', hex);
          // }
          // var yiq = ((r*299)+(g*587)+(b*114))/1000;
          // let font_color = (yiq >= 128) ? 'black' : 'white';
          let font_color = this.getFontColorToContrast(color);
          // Change background color of row
          info.el.style.backgroundColor = color;
          info.el.style.color = font_color;
        },
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        }
      },
    };
  },

  props: ["event"],

  computed: {
    ...mapState({
      user: "user",
    }),
  },

  mounted() {
    // this.loadPurchaseOrders();
    this.loadEvents();
  },

  created() {
    this.$store.commit("setSelectedTab", "agenda");
  },

  methods: {
    componentToHex(c) {
      var str_num = parseInt(c.replace(' ', ''))
      var hex = str_num.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    parseRgbToHex (str) {
      str = str.trim();
      if (str.substring(0, 4) == 'rgba') {
        let rgb = str.substring(4).split(',');
        return this.rgbToHex(
          rgb[0].replace('(', ''),
          rgb[1],
          rgb[2].replace(')', ''),
        );
      } else if (str.substring(0, 3) == 'rgb') {
        let rgb = str.substring(3).split(',');
        return this.rgbToHex(
          rgb[0].replace('(', ''),
          rgb[1],
          rgb[2].replace(')', ''),
        );
      }
    },
    calculateContrastColorByHex(hex) {
      var r = parseInt(hex.substring(1,3),16);
      var g = parseInt(hex.substring(3,5),16);
      var b = parseInt(hex.substring(5,7),16);
      var yiq = ((r*299)+(g*587)+(b*114))/1000;
      return (yiq >= 128) ? 'black' : 'white';
    },
    getFontColorToContrast(background_color) {
      let font_color = 'black';
      if (background_color.substring(0, 1) == '#') {
        font_color = this.calculateContrastColorByHex(background_color);
      } else {
        let hex = this.parseRgbToHex(background_color);
        font_color = this.calculateContrastColorByHex(hex)
      }
      return font_color;
    },
    async loadEvents() {
      this.loading = true;

      try {
        this.events = (await clientRequest.getEvents({
          statuses: ['accepted', 'mounted', 'unmounted'],
          products: true, 
          no_past: true,
        })).data.data;
        this.events.forEach((event) => {
        if (event != null) {
          let color = event.background_color;

          // montaje 
          if (event.setup_date_start && event.setup_date_end) {
            this.calendarOptions.events.push({
              customHtml: '<p class="text-lg uppercase text-clip overflow-hidden "><strong>Montaje:</strong></p>'+ '<p class=" text-xs text-clip overflow-hidden ">' + event.name + '</p>' + '<p class=" text-xs text-clip overflow-hidden ">' + (event.customer.full_name ? event.customer.full_name : '') + (event.seller ? '<br>' + event.seller.name : null) + '<br>' + event.event_address + '<br><span style="font-size: 15px;">Fecha de evento: ' + this.showDate(event.event_date)+ ', <strong>'+this.showTime(event.event_date)+'</strong></span>',
              start: event.setup_date_start,
              end: event.setup_date_end,
              // allDay: true,
              backgroundColor: color,
              textColor: '#000',
              borderColor: color,
              extendedProps: {
                // type: "production",
                eventId: event.id,
                eventColor: color,
              },
              classNames: ["cursor-pointer", "p-2", "shadow-sm", "break-all"],
            });
          }

          if (event.dismount_date_start && event.dismount_date_end) {
            this.calendarOptions.events.push({
              customHtml: '<p class=" text-lg uppercase text-clip overflow-hidden "><strong>Desmontaje:</strong></p>'+ '<p class=" text-xs text-clip overflow-hidden ">' + event.name+ '</p>' + '<p class=" text-xs text-clip overflow-hidden ">' + (event.customer.full_name ? event.customer.full_name : '') + (event.seller ? '<br>' + event.seller.name : null) + '<br>' + event.event_address,
              start: event.dismount_date_start,
              end: event.dismount_date_end,
              // allDay: true,
              backgroundColor: color,
              textColor: '#000',
              borderColor: color,
              extendedProps: {
                // type: "production",
                eventId: event.id,
                eventColor: color,
              },
              classNames: [ "cursor-pointer", "p-2", "shadow-sm", "break-all"],
            });
          }

          if (!event.setup_date_start && !event.dismount_date_start) {
            color = (event.status.id == 1 ? "rgb(59 130 246 / 0.5)" : "#fee4f8");
            this.calendarOptions.events.push({
              customHtml: '<p class=" text-xs text-clip overflow-hidden ">Evento:</p>'+ '<p class=" text-xs text-clip overflow-hidden ">' + event.name+ '</p>' + '<p class=" text-xs text-clip overflow-hidden ">' + (event.customer.full_name ? event.customer.full_name : '') + (event.seller ? '<br>' + event.seller.name : null) + '<br>' + event.event_address + '<br><span style="font-size: 15px;">Fecha de evento: ' + this.showDate(event.event_date)+ ', <strong>'+this.showTime(event.event_date)+'</strong></span>',
              start: event.event_date,
              // allDay: true,
              backgroundColor: color,
              textColor: (event.status.id == 1 ? "#000" : "#000"),
              borderColor: color,
              extendedProps: {
                // type: "production",
                eventId: event.id,
                eventColor: color,
              },
              classNames: [ "cursor-pointer", "p-2", "shadow-sm", "break-all"],
            });
          }
          
        }
      });
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    openProject(project) {
      this.selectedEvent = project;
      this.showProjectModal = true;
    },
    async updateEventColor() {
      try {
        let response = await clientRequest.updateEventBackgroundColor(this.selectedEvent.id, {
          background_color: this.selectedEvent.background_color
        });
        let calendarEvents = this.calendarOptions.events.filter(event => event.extendedProps.eventId === this.selectedEvent.id);
        calendarEvents.forEach((event) => {
          event.extendedProps.eventColor = this.selectedEvent.background_color;
        })
        this.$refs.fullCalendar.getApi().render();
      } catch (error) {
        console.log(error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
        return;
      }
    },
    addEvent: function(arg) {
      console.log(arg);
    },

    // handleEventClick: function(arg) {
    //   // console.log(arg);
    //   console.log(arg.event.extendedProps.eventId);
    //   this.$router.push({ name: "events_edit_logistics", params: { id: arg.event.extendedProps.eventId } });
    // },

    handleEventClick: function (arg) {
      // this.$router.push({ name: "events_dashboard", params: { id: arg.event.extendedProps.eventId} });
      let eventId = arg.event.extendedProps.eventId;
      let project = this.events.filter(event => event.id === eventId);
      this.openProject(project[0]);
    },
    formatDate(date) {
      return moment(date).locale("es").format("dddd, MMMM D YYYY, h:mm:ss a");
    },
    showDate(date) {
      return moment(date).locale("es").format("dddd, MMMM D YYYY");
    },
    showTime(date) {
      return moment(date).locale("es").format("h:mm:ss a");
    },
  }
};
</script>

<style>
.fc-timegrid-slots{
  background-color: #fff !important;
}
a.fc-timegrid-more-link.fc-more-link {
  background-color: #00fd50 !important;
  color: #000;
}
.right-enter-active,
.right-leave-active {
  transition: all 0.2s ease;
}
.right-enter,
.right-leave-to {
  transform: translateX(100%);
}

:root {
    --fc-list-event-dot-width: 10px;
    --fc-list-event-hover-bg-color: initial;
}

@media (max-width: 767px) {
  .fc .fc-toolbar {
    display: block;
  }
}

</style>