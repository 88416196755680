<template>
  <div>
    <div class="font-bold text-lg">Próximos Eventos</div>

    <div class="mt-6 shadow-primary">
      <loader-icon v-if="loading" class="h-8 w-8 mx-auto"></loader-icon>
      <full-calendar
        ref="fullCalendar"
        :options="calendarOptions"
        class="my-6"
      />
    </div>
  </div>
</template>

<script>
// import ArrowIcon from "../../../../icons/ArrowIcon";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import clientRequest from "../../../../api/clientRequest";
import moment from 'moment';
import { integerToCurrency } from "@/utils/currency";

export default {
  components: {
    // ArrowIcon,
    FullCalendar,
  },

  data() {
    return {
      loading: false,
      calendarOptions: {
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        allDaySlot: true,
        eventMaxStack: 2,
        slotDuration: '00:15:00',
        eventMinHeight: 90,
        eventShortHeight: 90,
        events: [],
        headerToolbar: {
          left: 'prev,next',
          center: 'title',

        },
        eventContent: function (eventInfo) {
          return { html: eventInfo.event.extendedProps.customHtml };
        },
        locale: esLocale,
      },
      dateTitle: "",
    };
  },

  mounted() {
    this.dateTitle = this.$refs.fullCalendar?.getApi()?.view.title;
    this.loadEvents();
  },

  methods: {
    async loadEvents() {
      this.loading = true;

      let filters = {
        statuses: ['quote','accepted', 'mounted', 'unmounted', 'cancelled', 'rejected'],
        query_params: {
          start_date: moment().subtract(5, 'months').format('yyyy-MM-DD')
        }
      };

      try {
        this.events = (await clientRequest.getEvents(filters)).data.data;
        this.events.forEach((event) => {
        if(event != null){

          let custom_html = '';
          let background_color = '';
          let text_color = '#000';

          let event_text = '<p class=" text-xs text-clip overflow-hidden ">' + event.name + '</p>' + '<p class=" text-xs text-clip overflow-hidden ">' + (event.customer.full_name ? event.customer.full_name : '') + '<p class=" text-xs text-clip overflow-hidden ">' + event.seller?.name + '</p>' + '<p class=" text-xs text-clip overflow-hidden ">' + integerToCurrency(event.total) + '</p>';

          if(event.status.id == 1) {
            custom_html = '<p class=" text-xs ">Cotizacion:</p>' + event_text
            background_color = '#feccd4';
            text_color = '#000';

          } else if(event.status.id == 2) {
            custom_html = '<p class=" text-xs">Aceptado:</p>' + event_text;
            background_color = 'hsla(52, 95%, 59%, 1)';
            text_color = '#000';
          } else if(event.status.id == 3) {
            custom_html = '<p class=" text-xs">Montado:</p>' + event_text;
            background_color = 'rgba(59, 130, 246, 0.5)';
            text_color = '#000';
          } else if(event.status.id == 4) {
            custom_html = '<p class=" text-xs">Desmontado:</p>' + event_text;
            background_color = 'rgba(0, 0, 0, 0.5)';
            text_color = '#000';
          } else if(event.status.id == 5) {
            custom_html = '<p class=" text-xs">Rechazado:</p>' + event_text;
            background_color = '#ff4d4d';
            text_color = '#000';
          } else if(event.status.id == 6) {
            custom_html = '<p class=" text-xs">Cancelado:</p>' + event_text;
            background_color = '#ff4d4d';
            text_color = '#000';
          }

          this.calendarOptions.events.push({
            customHtml: custom_html,
            start: event.event_date,
            status: event.status.id,
            allDay: true,
            backgroundColor: background_color,
            textColor: text_color,
            borderColor: "#000",
            extendedProps: {
              eventId: event.id,
            },
            classNames: ["hover: opacity-80", "cursor-pointer", "p-2", "shadow-sm", "break-all"],
          });
        }
      });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    handleDateClick: function (arg) {},

    handleEventClick: function (arg) {
      this.$router.push({ name: "events_dashboard", params: { id: arg.event.extendedProps.eventId} });
    },

    previous() {
      this.$refs.fullCalendar.getApi().prev();
      this.dateTitle = this.$refs.fullCalendar?.getApi()?.view.title;
    },

    next() {
      this.$refs.fullCalendar.getApi().next();
      this.dateTitle = this.$refs.fullCalendar?.getApi()?.view.title;
    },

    getDateTitle() {
      return this.$refs.fullCalendar.getApi().view.title;
    },
  },
};
</script>

<style>
.fc .fc-daygrid-day.fc-day-today {
  background-color: #fff;
}
.right-enter-active,
.right-leave-active {
  transition: all 0.2s ease;
}
.right-enter,
.right-leave-to {
  transform: translateX(100%);
}
</style>