<template>
  <div>
    <form action="">
      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="name" value="Nombre del rol" />
        <r-e-input id="name" type="text" v-model="form.name" />
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../../api/clientRequest";
import { is422 } from "../../../../utils/response";
export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
      },
    };
  },

  created() {
    if (this.$route.params.id) {
      if (!this.$route.params.role) {
        this.$router.push({ name: "role_index" });
      }
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.role));
    }
  },

  methods: {
    save() {
      if (!this.isEditing) {
        this.saveRole();
      } else {
        this.updateRole();
      }
    },

    async saveRole() {
      this.loading = true;

      try {
        await clientRequest.storeRole(this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "role_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },

    async updateRole() {
      this.loading = true;

      try {
        await clientRequest.updateRole(this.form.id, this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "role_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    }

  },
};
</script>