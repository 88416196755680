<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="full_name" value="Nombre del cliente" />
          <r-e-input id="full_name" type="text" v-model="form.full_name" />
          <v-errors :errors="errorFor('full_name')"></v-errors>
        </div>

        <select-input v-model="form.channel" placeholder="Forma de contacto">
          <option v-for="sale_channel in sale_channels" :key="sale_channel" :value="sale_channel">
            {{ sale_channel }}
          </option>
        </select-input>

        <div class="flex flex-col w-72 mt-6 mr-4" v-if="form.channel == 'Otro' || form.channel == 'Expo'">
          <r-e-label for="channel" value="Detalle de forma de contacto" />
          <r-e-input id="channel" type="text" v-model="form.channel_other" />
          <v-errors :errors="errorFor('channel')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="phone" value="Telefono del cliente" />
          <r-e-input id="phone" type="text" v-model="form.phone" />
          <v-errors :errors="errorFor('phone')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="email" value="Correo electrónico" />
          <r-e-input id="email" type="text" v-model="form.email" />
          <v-errors :errors="errorFor('email')"></v-errors>
        </div>

        <!-- <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="zone" value="Zona" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.zone.id"
            >
              <option v-for="zone in zones" :key="zone.id" :value="zone.id">
                {{ zone.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div> -->

        <!-- <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="client_type" value="Tipo cliente" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.client_type.id"
            >
              <option v-for="client_type in client_types" :key="client_type.id" :value="client_type.id">
                {{ client_type.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div> -->

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="referred_by" value="Referencia" />
          <r-e-input id="referred_by" type="text" v-model="form.referred_by" />
          <v-errors :errors="errorFor('referred_by')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="type" value="Tipo de cliente" />
          <r-e-input id="type" type="text" v-model="form.type" />
          <v-errors :errors="errorFor('type')"></v-errors>
        </div>
      </div>

      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="full_name" value="Nombre del planner" />
          <r-e-input id="full_name" type="text" v-model="form.planner_name" />
          <v-errors :errors="errorFor('full_name')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="phone" value="Telefono del planner" />
          <r-e-input id="phone" type="text" v-model="form.planner_phone" />
          <v-errors :errors="errorFor('phone')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="default_discount" value="Descuento Default" />
          <r-e-input id="default_discount" type="number" min="0" max="100" step="0.01" v-model="form.default_discount" />
          <v-errors :errors="errorFor('default_discount')"></v-errors>
        </div>
      </div>

      <r-e-primary-button
        class="w-72 mt-6"
        :loading="loading"
        @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import validationErrors from "../../../mixins/validationErrors";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Formulario",

  components: {SelectInput},

  data() {
    return {
      isEditing: false,
      form: {
        full_name: "",
        business_as: "",
        channel: "",
        rfc: "",
        phone: "",
        email: "",
        billing_email: "",
        referred_by: "",
        type: "",
        billing_address: "",
        billing_zipcode: "",
        client_image: "",
      },
      loading: false,
      sale_channels: [
        'Instagram',
        'Facebook',
        'Recomendación',
        'Pacto',
        'Google',
        'Página web',
        'Mail',
        'Expo',
        'Bodas.mx',
        'Engaged',
      ],
    };
  },

  mixins: [validationErrors],

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadClient(this.$route.params.id);
    }
  },

  methods: {
    async loadClient(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.viewClient(id)).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    getImage(e) {
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.client_image = e.target.result;
      };
    },

    async save() {
      this.loading = true;

      if (!this.isEditing) {
        try {
          await clientRequest.storeClient(this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "clients_index" });
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
      } else {
        try {
          await clientRequest.updateClient(this.$route.params.id, this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "clients_index" });
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
      }

      this.loading = false;
    },
  },
};
</script>