<template>
    <div>
      <transition name="modal">
        <delete-modal
          v-if="showDeleteModal"
          @close="closeDeleteModal"
          @confirmDelete="deleteExpense"
        ></delete-modal>
      </transition>
  
      <transition name="modal">
        <delete-modal
          v-if="showDeleteFileModal"
          @close="showDeleteFileModal = false"
          @confirmDelete="deleteFile"
        ></delete-modal>
      </transition>
  
      <div v-if="showDetailsModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
        <div class="form relative">
            <div>
              <button class="link-button mt-4 float-right" style="width: fit-content;" @click.prevent="showDetailsModal = false">
                Cerrar
              </button>
              <table class="details-table">
                <tbody>
                <tr>
                  <td>Id del pago</td>
                  <td>{{ selectedPayment.id }}</td>
                </tr>
                <tr>
                  <td>Categoria</td>
                  <td>{{ selectedPayment.category.name }}</td>
                </tr>
                <tr>
                  <td>Subcategoria</td>
                  <td>{{ selectedPayment.subcategory.name }}</td>
                </tr>
                <tr>
                  <td>Fecha de creación</td>
                  <td>{{ formatDate(selectedPayment.created_at) }}</td>
                </tr>
                <tr>
                  <td>Fecha programada de pago</td>
                  <td>{{ formatDate(selectedPayment.will_pay_at) }}</td>
                </tr>
                <tr>
                  <td>Fecha de pago</td>
                  <td>{{ formatDate(selectedPayment.payed_at) }}</td>
                </tr>
                <tr>
                  <td>Cuenta</td>
                  <td>{{ selectedPayment.bank_account?.name }}</td>
                </tr>
                <tr>
                  <td>Monto</td>
                  <td>{{ formatPrice(selectedPayment.amount) }}</td>
                </tr>
                <tr>
                  <td>Descripción</td>
                  <td>{{ selectedPayment.description }}</td>
                </tr>
                <tr>
                  <td>Ingresado por</td>
                  <td>{{ selectedPayment.created_by.name }}</td>
                </tr>
              </tbody>
              </table>
  
              Archivos
              <ul class="file-list">
                <li v-for="file in selectedPayment.files" v-bind:key="file.id">
                  <a :href="file.url" target="_blank">{{ file.name }}</a>
                  &nbsp;<r-e-delete-button @buttonClicked="openDeleteFile(file)" /> 
                </li>
              </ul>
  
              <div>
                <input type="file" @change="handleFileUpload($event)">
                <r-e-primary-button
                  class="mt-4"
                  @buttonClicked="submitFile"
                  >Subir archivo
                </r-e-primary-button>
              </div>
            </div>
        </div>
      </div>
  
      <div class="w-full flex flex-wrap">
        <date-filters v-model="date_filters"></date-filters>
      </div>
      <r-e-input class="w-full mt-6" placeholder="Buscar por nombre, categoria, monto " v-model="search" clear-text="true"/>
      <div class="w-full flex flex-wrap">
        <select-input v-model="bank_account_is_cash" placeholder="Tipo de cuenta de egreso">
          <option value="0">Todo</option>
          <option value="1">Efectivo</option>
          <option value="2">Cuenta bancaria</option>
          <option value="3">Reinversión</option>
          <option value="4">Utilidades</option>
        </select-input>
      </div>
      <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadAccountsPayable()">Filtrar</r-e-primary-button>
  
      <!-- <charts class="mt-12" /> -->
  
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col mt-6 pr-4">
          Monto Total: {{ formatPrice(totalSum) }}
        </div>
      </div>
      <div class="" style="float: right;">
        <table>
          <tr>
            <td rowspan="2">Referencia</td>
            <td class="danger-background">Fecha pasada / vencido</td>
          </tr>
          <tr>
            <td class="aqua-background">A tiempo</td>
          </tr>
        </table>
      </div>
      <loader-icon v-if="loading" class="h-8 w-8 mx-auto"></loader-icon>
      <table v-else class="table-auto mt-8 text-sm md:text-base w-full">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x cursor-pointer" @click="orderColumn('amount')">*Monto</th>
            <th class="p-2 border-x">Cuenta</th>
            <th class="p-2 border-x">Categoría</th>
            <th class="p-2 border-x">Subcategoría</th>
            <th class="p-2 border-x cursor-pointer" @click="orderColumn('payed_at')">*Fecha de pago</th>
            <th class="p-2 border-x">Ingresado Por</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr :class="rowColor(payment)" v-for="payment in payments" :key="payment.id">
            <td class="p-2 border-r">
              {{ payment.id }}
              <!-- <br> -->
              <!-- <input type="checkbox" v-model="payment.selected"> -->
            </td>
            <td class="p-2 border-x">{{ formatPrice(payment.amount) }}</td>
            <td class="p-2 border-x">{{ payment.bank_account?.name }}</td>
            <td class="p-2 border-x">{{ payment.category ? payment.category.name : '' }}</td>
            <td class="p-2 border-x">{{ payment.subcategory ? payment.subcategory.name : ''}}</td>
            <td class="p-2 border-x">
              <span style="font-size: 12px;">Pagado:</span> {{ payment.payed_at ? formatDate( payment.payed_at) : '' }}
              <br> <span style="font-size: 12px;">Fecha programada:</span> {{ payment.will_pay_at ? formatDate( payment.will_pay_at) : '' }}
            </td>
            <td class="p-2 border-x" style="font-size: 12px;">{{ payment.created_by ? payment.created_by.name : '' }} <br> <span>{{ formatDate(payment.created_at) }}</span></td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'expenses_edit',
                    params: {
                      id: payment.id,
                      payment: payment,
                    },
                  }"
                />
  
                <r-e-detail-button class="mr-2" @buttonClicked="openDetails(payment)"/>
  
                <r-e-delete-button @buttonClicked="openDelete(payment)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import deleteMixin from "@/mixins/deleteMixin";
  import activeFiltersMixin from "@/mixins/activeFilters";
  import { integerToCurrency } from "@/utils/currency";
  import clientRequest from "@/api/clientRequest";
  import DateFilters from '@/components/DateFilters.vue';
  import SelectInput from '@/components/SelectInput.vue';
  
  export default {
    name: "Indice",
  
    components: {
      SelectInput,
      DateFilters
    },
  
    data() {
      return {
        payments: [],
        search: '',
        selectedPayment: {},
        selectedFile: {},
        to_pay: true,
        showDetailsModal: false,
        showDeleteFileModal: false,
        date_filters: {},
        loading: false,
        orientationAsc: true,
        bank_account_is_cash: 0,
      };
    },
  
    mixins: [deleteMixin, activeFiltersMixin],
    computed: {
      totalSum() {
        if(!this.payments) return;
        if(this.payments.length <= 0) return;
        return this.payments.map((item) => item.amount).reduce((total, item) => total + parseFloat(item));
      }
    },
    created() {
      this.getFilters();
      this.loadAccountsPayable();
    },
    methods: {
      getFilters() {
        let filters = this.getActiveFilters();
        this.date_filters.filter_start = filters.start_date || moment().startOf('month').format('yyyy-MM-DD');
        this.date_filters.filter_end = filters.end_date || moment().endOf('month').format('yyyy-MM-DD');
        this.search = filters.query_text || '';
        this.to_pay = true;
        this.bank_account_is_cash = filters.bank_account_is_cash || 0;
      },
      async loadAccountsPayable() {
        this.loading = true;
  
        let params = {
          start_date: this.date_filters.filter_start,
          end_date: this.date_filters.filter_end,
          query_text: this.search,
          bank_account_is_cash: this.bank_account_is_cash,
          to_pay: true,
        };
  
        this.setActiveFilters(params);
  
        try {
          this.payments = (await clientRequest.getOutcomes(params)).data.data;
        } catch (error) {
          console.log('error', error)
        }
  
        this.loading = false;
      },
      orderColumn(field) {
        this.orientationAsc = !this.orientationAsc;
        if (this.orientationAsc) {
          this.payments.sort((a, b) => {
            if (a[field] < b[field]) return -1
            else return 1;
          });
        } else {
          this.payments.sort((a, b) => {
            if (a[field] > b[field]) return -1
            else return 1;
          })
        }
      },
      openDetails(payment) {
        this.selectedPayment = payment;
        this.showDetailsModal = true;
      },
  
      openDelete(payment) {
        this.selectedPayment = payment;
        this.openDeleteModal();
      },
  
      async deleteExpense() {
        this.loading = true;
  
        try {
          await clientRequest.deletePayment(this.selectedPayment.id);
          this.payments.splice(this.payments.indexOf(this.selectedPayment), 1);
          this.selectedPayment = {};
        } catch (error) {
          console.log(error);
        }
  
        this.loading = false;
        this.closeDeleteModal();
      },
  
      formatPrice(price) {
        return integerToCurrency(price);
      },
  
      formatDate(date) {
        return moment(date).locale("es").format("LL");
      },
  
      formatBool(val) {
        return val ? "Si" : "No";
      },
  
      handleFileUpload(event) {
        this.file = event.target.files[0];
      },
  
      async submitFile() {
        try{
          let response = await clientRequest.uploadPaymentFile(this.selectedPayment.id, this.file);
          this.selectedPayment.files = response.data.data.files;
        } catch (error) {
          console.log(error);
        }
      },
  
      openDeleteFile(file) {
        this.selectedFile = file;
        this.showDeleteFileModal = true;
      },
  
      async deleteFile() {
        this.loading = true;
  
        try {
          let response = await clientRequest.deletePaymentFile(this.selectedFile.id);
          this.selectedPayment.files = response.data.data.files;
        } catch (error) {
          console.log(error);
        }
  
        this.loading = false;
        this.showDeleteFileModal = false;
      },
      rowColor(payment) {
        if (payment.payed_at) {
          return null;
        }
  
        if (moment().isBefore(payment.will_pay_at)) {
          return 'aqua-background';
        } else {
          return 'danger-background';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  table.details-table td {
    padding: 10px;
  }
  ul.file-list {
    list-style: disc;
    margin-left: 25px;
    margin-bottom: 15px;
  }
  ul.file-list li {
    padding: 5px;
  }
  
  .danger-background {
    background-color: #f891ff;
  }
  .green-background {
    background-color: #a6f8a4;
  }
  .aqua-background {
    background-color: #76dfff;
  }
  </style>