<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteLocation"
      ></delete-modal>
    </transition>

    <router-link class="primary-button mt-6 w-56" :to="{ name: 'locations_add' }">
      <span class="text-xl mr-2">+</span>Agregar Ubicación</router-link
    >

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Nombre</th>
          <th class="p-2 border-x">Dirección</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="location in locations" :key="location.id">
          <td class="p-2 border-r">{{ location.id }}</td>
          <td class="p-2 border-x">{{ location.name }}</td>
          <td class="p-2 border-x">{{ location.address }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'locations_edit',
                  params: {
                    id: location.id,
                    location: location,
                  },
                }"
              />
              <r-e-delete-button @buttonClicked="openDelete(location.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import deleteMixin from "../../../../mixins/deleteMixin";
export default {
  name: "Indice",

  data() {
    return {
      locations: [
        {
          id: 1,
          name: "Bodega",
          address: "Dirección Uno"
        },
        {
          id: 2,
          name: "Local",
          address: "Dirección Dos"
        },
      ]
    };
  },

  mixins: [deleteMixin],

  methods: {
    openDelete(id) {
      this.openDeleteModal();
    },

    deleteLocation() {
      this.closeDeleteModal();
    },
  },
};
</script>