<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="branch" value="Selecciona una sucursal" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.branch_id"
            >
              <option
                v-for="branch in branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.description }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Selecciona un cliente" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.client"
              @change="updateContacts"
            >
              <option
                v-for="client in clients"
                :key="client.id"
                :value="client"
              >
                {{ client.full_name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="phone" value="Contacto" />
          <r-e-input
            id="phone"
            type="text"
            v-model="form.phone"
          />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="seller" value="Selecciona un vendedor encargado" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="seller"
              v-model="form.seller"
            >
              <option
                v-for="seller in sellers"
                :key="seller.id"
                :value="seller.name"
              >
                {{ seller.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="event_type" value="Selecciona un tipo de evento" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.event_type"
            >
              <option
                v-for="event_type in event_types"
                :key="event_type.id"
                :value="event_type.name"
              >
                {{ event_type.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre del evento" />
          <r-e-input
            id="name"
            type="text"
            v-model="form.name"
          />
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="installer" value="Instalador encargado" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.installer"
            >
              <option
                v-for="installer in installers"
                :key="installer.id"
                :value="installer.name"
              >
                {{ installer.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Direccion del evento (escrita)" />
          <r-e-input id="name" type="text" v-model="form.event_address" />
        </div>
        <div class="flex flex-col w-100 mt-6 pr-4">
          <r-e-label
            for="location_url"
            value="Dirección del evento (URL de Google Maps)"
          />
          <r-e-input
            id="location_url"
            type="text"
            v-model="form.location_url"
          />
        </div>
        
      </div>
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="event_date" value="Fecha del evento" />
          <vue-date-picker
            name="event_date"
            v-model="form.event_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="setup_start_date" value="Fecha montaje" />
          <vue-date-picker
            name="setup_start_date"
            v-model="form.setup_start_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="dismount_finish_date" value="Fecha desmontaje" />
          <vue-date-picker
            name="dismount_finish_date"
            v-model="form.dismount_finish_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        
      </div>
      <div class="flex flex-col mt-6">
          <r-e-label for="setup_instructions" value="Instrucciones para montaje" />
          <textarea
            class="
              border border-gray-700
              py-2
              px-3
              outline-none
              hover:border-secondary-500
              focus:border-secondary-500
              smooth
            "
            v-model="form.setup_instructions"
            id="setup_instructions"
          ></textarea>
      </div>
      <div class="flex flex-col mt-6">
          <r-e-label for="description" value="Descripción del evento" />
          <textarea
            class="
              border border-gray-700
              py-2
              px-3
              outline-none
              hover:border-secondary-500
              focus:border-secondary-500
              smooth
            "
            v-model="form.description"
            id="description"
          ></textarea>
      </div>

      <!-- <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-200">
          <li v-for="product in products" :key="product.id">
            <a href="#" class="block hover:bg-gray-50">
              <div class="px-4 py-4 flex items-center sm:px-6">
                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div class="truncate">
                    <div class="flex text-sm">
                      <p class="font-medium text-indigo-600 truncate">{{ product.name }}</p>
                      <p class="ml-1 flex-shrink-0 font-normal text-gray-500">{{ product.description }}</p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          {{ product.availability }} disponibles para la fecha
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div class="flex overflow-hidden -space-x-1">
                    </div>
                  </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div> -->

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="saveEvent">Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import validationErrors from "../../../mixins/validationErrors";

export default {
  name: "Formulario",

  components: {
  },

  mixins: [validationErrors],

  data() {
    return {
      isEditing: false,
      form: {
        event_date: null,
        setup_start_date: null,
        dismount_finish_date: null,
        location_url: null,
        client_id: 0,
        branch_id: 0,
        name: "",
        seller: "",
        phone: "",
        description: "",
        event_address: "",
        event_type: "",
        contact_method: "",
        installer: "",
        setup_instructions: "",
      },
      timePickerOptions: {
        start: "00:00",
        step: "01:00",
        end: "23:00",
        format: "HH:mm",
      },
      branches: [],
      clients: [],
      installers: [
        {
          id: 0,
          name: "TBD",
        },
        {
          id: 1,
          name: "Mario",
        },
        {
          id: 2,
          name: "Juan",
        },
        {
          id: 3,
          name: "Don Pepe",
        },
      ],
      sellers: [
        {
          id: 0,
          name: "TBD",
        },
        {
          id: 1,
          name: "Marlene",
        },
        {
          id: 2,
          name: "Nicole",
        },
        {
          id: 3,
          name: "Valeria",
        },
      ],
      event_types: [
        {
          id: 1,
          name: "Boda",
        },
        {
          id: 2,
          name: "Corporativo",
        },
        {
          id: 3,
          name: "XV",
        },
        {
          id: 4,
          name: "Baby Shower",
        },
        {
          id: 5,
          name: "Graduación",
        },
      ],
    };
  },



  computed: {
    getContactsOfClient() {
      if (!this.form.client.id) {
        return [];
      }

      return this.clients.filter((client) => {
        return client.id === this.form.client.id;
      })[0].contacts;
    },
  },

  created() {
    if (this.$route.params.events) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.events));
    }

    this.loadClients();
    this.loadBranches();
  },

  methods: {
    async loadClients() {
      this.loading = true;

      try {
        this.clients = (await clientRequest.getClients()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadBranches() {
      this.loading = true;

      try {
        this.branches = (await clientRequest.getBranches()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    updateContacts() {
      if (this.form.client) {
        this.form.phone = this.form.client.phone;
        this.form.client_id = this.form.client.id;
      }
    },

    // async next() {
    //   this.$router.push({ name: "events_add_final" });
    // },

    async saveEvent() {
      this.loading = true;

      try {
          let data = await clientRequest.storeEvent(this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "events_dashboard", params: { id: data.id} });
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            console.log('error', error);
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    }
  },
};
</script>