<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre del item" />
          <r-e-input id="name" type="text" v-model="form.name" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="sku" value="SKU" />
          <r-e-input id="sku" type="text" v-model="form.sku" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="cost" value="Costo" />
          <r-e-input id="cost" type="number" v-model="form.cost" />
        </div>


        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="color" value="Color" />
          <r-e-input id="color" type="text" v-model="form.color" />
        </div>
      </div>

      <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="category" value="Categoría" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.category_id"
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <div class="w-full flex flex-wrap" v-for="warehouse in form.warehouses" :key="warehouse.id">
          <p class="flex flex-col w-72 mt-6 pr-4">{{ warehouse.name }}</p>

          <div class="flex flex-col w-72 mt-6 pr-4">
            <r-e-label for="stock" value="Cantidad en Inventario" />
            <r-e-input
              id="quantity"
              type="number"
              min="1"
              step="1"
              v-model="warehouse.quantity"
            />
          </div>
        </div>

      <r-e-primary-button class="w-72 mt-6" :loading="loading" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        sku: "",
        price: "",
        color: "",
        description: "",
        warehouses: [],
      },
      loading: false,
      categories: [],
      warehouses: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadItem(this.$route.params.id);
      // this.form.warehouse_id = this.form.warehouse.id;
    }
    this.loadCategories();
    this.loadWarehouses();
  },

  methods: {
    async loadItem(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getProduct(id)).data.data;
        this.form.warehouses = [];
      } catch (error) {
        console.log('error', error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadCategories() {
      this.loading = true;

      try {
        this.categories = (await clientRequest.getCategories('Hagane\\Ichizen\\Models\\Product')).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadWarehouses() {
      this.loading = true;

      try {
        this.warehouses = (await clientRequest.getWarehouses()).data.data;

        this.warehouses.forEach((warehouse) => {
          warehouse.quantity = 0;
          if (this.form.inventory) {
            this.form.inventory.forEach((inventory) => {
              if (inventory.id == warehouse.id) {
                warehouse.quantity = inventory.pivot.quantity;
              }
            });
          }
          this.form.warehouses.push(warehouse);
        })
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async save() {
      if(this.isEditing) {
        this.update();
        return;
      }
      this.loading = true;

      try {
        await clientRequest.storeProduct(this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "items_index" });
      } catch(error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
    async update() {
      this.loading = true;

      try {
        await clientRequest.updateProduct(this.form.id, this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "items_index" });
      } catch(error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          console.log('error', error);
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
  },
};
</script>