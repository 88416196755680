<template>
  <div class="flex justify-evenly">
    <div>
      <div class="font-bold text-lg">Vendedor</div>
      <Bar
        :chart-options="chartOptions"
        :chart-data="topSellers"
        :width="300"
        :height="300"
      />
    </div>
    <div>
      <div class="font-bold text-lg">Canales de venta</div>
      <Pie
        :chart-options="chartOptions"
        :chart-data="saleChannels"
        :width="300"
        :height="300"
      />
    </div>
    <div>
      <div class="font-bold text-lg">Cuentas</div>
      <Bar
        :chart-options="chartOptions"
        :chart-data="topClients"
        :width="300"
        :height="300"
      />
    </div>
  </div>
</template>

<script>
import { Bar, Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
);
export default {
  components: {
    Bar,
    Pie,
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
      },
      topSellers: {
        labels: ["Juan Pérez", "María Rodríguez", "Roberto Muñoz", "Rodrigo Cantú", "Fernanda Gómez"],
        datasets: [
          {
            label: "$MXN",
            backgroundColor: "#ecfc03",
            data: [1000, 2023, 1233, 25000, 12233],
          },
        ],
      },
      saleChannels: {
        labels: ["Instagram", "facebook", "local", "web", "expo", "recomendacion", "pacto"],
        datasets: [
          {
            backgroundColor: "#5a54fc",
            data: [10, 40, 50, 15, 10, 20, 40, 10],
          },
        ],
      },
      topClients: {
        labels: ["Cuenta Uno", "Cuenta Dos", "Cuenta Tres"],
        datasets: [
          {
            label: "$MXN",
            backgroundColor: "#fc54a3",
            data: [1000, 2023, 1233],
          },
        ],
      },
    };
  },
};
</script>
