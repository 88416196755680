import VErrors from "../components/ValidationErrors";
export default {
  components: {
    VErrors
  },

  data() {
      return {
          errors: {}
      }
  },
  methods: {
      errorFor(field) {
          return this.errors[field]
              ? this.errors[field]
              : null;
      },
  }
};