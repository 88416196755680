<template>
  <div class="">
    <div v-if="compound_product">
      <div class="flex-col shadow-lg p-4 rounded w-full">
        <h3 class="font-bold text-lg">Detalles del producto</h3>

        <div class="flex mt-3">
          <div class="font-bold">Nombre:</div>
          <div class="ml-2">{{ compound_product.name }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Precio:</div>
          <div class="ml-2">{{ formatPrice(compound_product.price) }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descripción:</div>
          <div class="ml-2">{{ compound_product.description }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Fecha de creación:</div>
          <div class="ml-2">{{ formatDate(compound_product.created_at) }}</div>
        </div>

        <h3 class="font-bold text-lg mt-3">Items</h3>

        <div
          v-for="product in compound_product.products"
          :key="product.id"
          class="flex flex-col mt-3 border p-3"
        >
          <div class="flex">
            <div class="font-bold">Producto:</div>
            <div class="ml-2">{{ product.name }}</div>
          </div>

          <div class="flex mt-3">
            <div class="font-bold">Cantidad:</div>
            <div class="ml-2">{{ product.pivot.quantity }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { integerToCurrency } from "../../../utils/currency";
export default {
  name: "Detalle",

  data() {
    return {
      compound_product: null,
    };
  },

  created() {
    this.compound_product = this.$route.params.compound_product;
  },

  methods: {
    formatDate(date) {
      return moment(date).locale("es").format("LLL");
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>