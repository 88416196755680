// Finances
import FinanceRouter from "./../views/dashboard/finances/FinanceRouter";

// Income
import IncomeRouter from "./../views/dashboard/finances/income/IncomeRouter";
import IncomeIndex from "./../views/dashboard/finances/income/Index";
import IncomeForm from "./../views/dashboard/finances/income/Form";
import IncomeDetail from "./../views/dashboard/finances/income/Detail";

// Expenses
import ExpensesRouter from "./../views/dashboard/finances/expenses/ExpensesRouter";
import ExpensesIndex from "./../views/dashboard/finances/expenses/Index";
import ExpensesForm from "./../views/dashboard/finances/expenses/Form";
import ExpensesDetail from "./../views/dashboard/finances/expenses/Detail";

// Categories
import CategoriesRouter from "./../views/dashboard/finances/categories/CategoriesRouter";
import CategoriesIndex from "./../views/dashboard/finances/categories/Index";
import CategoriesForm from "./../views/dashboard/finances/categories/Form";

//(new)

// Subcategories
import SubcategoriesRouter from "./../views/dashboard/finances/subcategories/SubcategoriesRouter";
import SubcategoriesIndex from "./../views/dashboard/finances/subcategories/Index";
import SubcategoriesForm from "./../views/dashboard/finances/subcategories/Form";

// Bank Accounts
import BankAccountsRouter from "./../views/dashboard/finances/bank-accounts/BankAccountsRouter";
import BankAccountsIndex from "./../views/dashboard/finances/bank-accounts/Index";
import BankAccountsForm from "./../views/dashboard/finances/bank-accounts/Form";

// Departments
import DepartmentsRouter from "./../views/dashboard/finances/departments/DepartmentsRouter";
import DepartmentsIndex from "./../views/dashboard/finances/departments/Index";
import DepartmentsForm from "./../views/dashboard/finances/departments/Form";

import BankAccountsTransfersRouter from "./../views/dashboard/finances/transfers/TransfersRouter";
import BankAccountsTransfersIndex from "./../views/dashboard/finances/transfers/Index";
import BankAccountsTransfersForm from "./../views/dashboard/finances/transfers/Form";

import BalancesRouter from "./../views/dashboard/finances/balances/BalancesRouter";
import BalancesIndex from "./../views/dashboard/finances/balances/Index";
import IncomeOutcome from "./../views/dashboard/finances/balances/IncomeOutcome.vue";

import EventsCollectionRouter from "./../views/dashboard/finances/events_collection/EventsCollectionRouter";
import EventsCollectionIndex from "./../views/dashboard/finances/events_collection/Index";

import AccountsPayableRouter from "./../views/dashboard/finances/accounts_payable/AccountsPayableRouter";
import AccountsPayableIndex from "./../views/dashboard/finances/accounts_payable/Index";

export const finances_routes = {
    path: "/finanzas",
    component: FinanceRouter,
    name: "finances",
    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
    children: [
        {
            path: "ingresos",
            component: IncomeRouter,
            name: "income",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
            redirect: { name: "income_index" },
            children: [
                {
                    path: "indice",
                    component: IncomeIndex,
                    name: "income_index",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                },
                {
                    path: "agregar",
                    component: IncomeForm,
                    name: "income_add",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                },
                {
                    path: "editar/:id",
                    component: IncomeForm,
                    name: "income_edit",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
                {
                    path: "detalle/:id",
                    component: IncomeDetail,
                    name: "income_detail",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                }
            ]
        },
        {
            path: "egresos",
            component: ExpensesRouter,
            name: "expenses",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
            redirect: { name: "expenses_index" },
            children: [
                {
                    path: "indice",
                    component: ExpensesIndex,
                    name: "expenses_index",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                },
                {
                    path: "agregar",
                    component: ExpensesForm,
                    name: "expenses_add",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                },
                {
                    path: "editar/:id",
                    component: ExpensesForm,
                    name: "expenses_edit",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
                {
                    path: "detalle/:id",
                    component: ExpensesDetail,
                    name: "expenses_detail",
                    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement'] },
                }
            ]
        },
        {
            path: "categorias",
            component: CategoriesRouter,
            name: "categories",
            meta: { requiresLogin: true, roles: ['admin'] },
            redirect: { name: "categories_index" },
            children: [
                {
                    path: "indice",
                    component: CategoriesIndex,
                    name: "categories_index",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "agregar",
                    component: CategoriesForm,
                    name: "categories_add",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "editar/:id",
                    component: CategoriesForm,
                    name: "categories_edit",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
            ]
        },
        {
            path: "subcategorias",
            component: SubcategoriesRouter,
            name: "subcategories",
            meta: { requiresLogin: true, roles: ['admin'] },
            redirect: { name: "subcategories_index" },
            children: [
                {
                    path: "indice",
                    component: SubcategoriesIndex,
                    name: "subcategories_index",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "agregar",
                    component: SubcategoriesForm,
                    name: "subcategories_add",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "editar/:id",
                    component: SubcategoriesForm,
                    name: "subcategories_edit",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
            ]
        },
        {
            path: "balances",
            component: BalancesRouter,
            name: "balances",
            meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
            redirect: { name: "balances_index" },
            children: [
                {
                    path: "indice",
                    component: BalancesIndex,
                    name: "balances_index",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
                {
                    path: "ingreso_egreso",
                    component: IncomeOutcome,
                    name: "income_outcome",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
            ]
        },
        {
            path: "transferencias",
            component: BankAccountsTransfersRouter,
            name: "bank_account_transfers",
            meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
            redirect: { name: "bank_account_transfers_index" },
            children: [
                {
                    path: "indice",
                    component: BankAccountsTransfersIndex,
                    name: "bank_account_transfers_index",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
                {
                    path: "agregar",
                    component: BankAccountsTransfersForm,
                    name: "bank_account_transfers_add",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
                {
                    path: "editar/:id",
                    component: BankAccountsTransfersForm,
                    name: "bank_account_transfers_edit",
                    meta: { requiresLogin: true, roles: ['admin', 'procurement'] },
                },
            ]
        },
        //(new)
        {
            path: "cuentas_bancarias",
            component: BankAccountsRouter,
            name: "bank_accounts",
            meta: { requiresLogin: true, roles: ['admin'] },
            redirect: { name: "bank_accounts_index" },
            children: [
                {
                    path: "indice",
                    component: BankAccountsIndex,
                    name: "bank_accounts_index",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "agregar",
                    component: BankAccountsForm,
                    name: "bank_accounts_add",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "editar/:id",
                    component: BankAccountsForm,
                    name: "bank_accounts_edit",
                    meta: { requiresLogin: true, roles: ['admin'] },
                }
            ]
        },
        {
            path: "departamentos",
            component: DepartmentsRouter,
            name: "departments",
            meta: { requiresLogin: true, roles: ['admin'] },
            redirect: { name: "departments_index" },
            children: [
                {
                    path: "indice",
                    component: DepartmentsIndex,
                    name: "departments_index",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "agregar",
                    component: DepartmentsForm,
                    name: "departments_add",
                    meta: { requiresLogin: true, roles: ['admin'] },
                },
                {
                    path: "editar/:id",
                    component: DepartmentsForm,
                    name: "departments_edit",
                    meta: { requiresLogin: true, roles: ['admin'] },
                }
            ]
        },
        {
            path: "eventos-por-cobrar",
            component: EventsCollectionRouter,
            name: "events_collection",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
            redirect: { name: "events_collection_index" },
            children: [
                {
                    path: "indice",
                    component: EventsCollectionIndex,
                    name: "events_collection_index",
                    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                },
            ]
        },
        {
            path: "cuentas-por-pagar",
            component: AccountsPayableRouter,
            name: "accounts_payable",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
            redirect: { name: "accounts_payable_index" },
            children: [
                {
                    path: "indice",
                    component: AccountsPayableIndex,
                    name: "accounts_payable_index",
                    meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
                },
            ]
        },
    ]
};