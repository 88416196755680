<template>
  <router-link
    v-if="to"
    type="submit"
    class="fill-current bg-black text-white w-8 h-8 button-interactive p-1"
    :to="to"
    @click.prevent="buttonClicked"
  >
    <detail-icon />
  </router-link>
  <button
    v-else
    type="button"
    class="fill-current bg-black text-white w-8 h-8 button-interactive p-1"
    @click.prevent="buttonClicked"
  >
    <detail-icon />
  </button>
</template>

<script>
import DetailIcon from "../icons/DetailIcon";
export default {
  components: {
    DetailIcon,
  },

  props: {
    to: Object
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>