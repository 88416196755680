<template>
    <div>
      <transition name="modal">
        <delete-modal
          v-if="showDeleteModal"
          @close="closeDeleteModal"
          @confirmDelete="deleteTransfer"
        ></delete-modal>
      </transition>
  
      <!-- <date-filter /> -->
  
      <!-- <charts class="mt-12" /> -->
  
      <div class="flex justify-between items-center mt-12">
        <router-link
          class="primary-button w-72"
          :to="{ name: 'bank_account_transfers_add' }"
        >
          <span class="text-xl mr-2">+</span>Registrar nueva transferencia</router-link
        >
  
        <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
          Descargar Excel
        </button> -->
      </div>
  
      <table class="table-auto mt-8 text-sm md:text-base w-full">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Fecha</th>
            <th class="p-2 border-x">Origen</th>
            <th class="p-2 border-x">Destino</th>
            <th class="p-2 border-x">Monto</th>
            <th class="p-2 border-x">Ingresado Por</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="transfer in bank_account_transfers" :key="transfer.id">
            <td class="p-2 border-r">{{ transfer.id }}</td>
            <td class="p-2 border-x">{{ formatDate(transfer.transfered_at) }}</td>
            <td class="p-2 border-r">{{ transfer.origin_bank_account.name }}</td>
            <td class="p-2 border-r">{{ transfer.destination_bank_account.name }}</td>
            <td class="p-2 border-x">{{ formatPrice(transfer.amount) }}</td>
            <td class="p-2 border-x">{{ transfer.created_by ? transfer.created_by.name : ''}}</td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'bank_account_transfers_edit',
                    params: {
                      id: transfer.id,
                      bank_account_transfer: transfer,
                    },
                  }"
                />
  
                <r-e-delete-button @buttonClicked="openDelete(transfer)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import clientRequest from "../../../../api/clientRequest";
  import deleteMixin from "../../../../mixins/deleteMixin";
  import { integerToCurrency } from "../../../../utils/currency";
  export default {
    name: "Indice",
  
    components: {
    },
  
    data() {
      return {
        bank_account_transfers: [],
        selectedTransfer: {},
        loading: false,
      };
    },
  
    mixins: [deleteMixin],
    created() {
      this.$store.commit("setFinancesSelectedTab", "bank_account_transfers");
      this.loadBankAccountTransfers();
    },
    methods: {
      async loadBankAccountTransfers() {
        this.loading = true;
  
        try {
          this.bank_account_transfers = (await clientRequest.getBankAccountTransfers()).data.data;
        } catch (error) {
          console.log('error', error)
        }
  
        this.loading = false;
      },
  
      openDelete(transfer) {
        this.selectedTransfer = transfer;
        this.openDeleteModal();
      },
  
      async deleteTransfer() {
        this.loading = true;

        try {
          await clientRequest.deleteBankAccountTransfer(this.selectedTransfer.id);
          this.bank_account_transfers.splice(this.bank_account_transfers.indexOf(this.selectedTransfer), 1);
          this.selectedTransfer = {};
        } catch (error) {
          console.log(error);
        }

        this.loading = false;
        this.closeDeleteModal();
      },
  
      formatDate(date) {
        return moment(date).locale("es").format("LL");
      },
  
      formatPrice(price) {
        return integerToCurrency(price);
      },
  
      formatBool(val) {
        return val ? "Si" : "No";
      },
    },
  };
  </script>