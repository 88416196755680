<template>
  <div class="flex flex-col w-72 mt-6 pr-4">
    <r-e-label for="client" :value="placeholder" />
    <div class="relative">
      <select
        class="border border-gray-700 py-2 px-3 outline-none focus:border-secondary-500 smooth appearance-none w-full"
        id="type"
        v-model="innerModelValue"
        @change="emitChange"
      >
        <slot></slot>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderIcon from "../icons/LoaderIcon";
export default {
  components: {
    // LoaderIcon,
  },

  props: {
    loading: Boolean,
    placeholder: String,
    modelValue: null,
  },

  data() {
     return {
        innerModelValue: null,
      }
   },

   created() {
    this.innerModelValue = this.modelValue;
   },

   watch: {
        'modelValue': function(value) {
            if (value !== this.innerModelValue) {
                this.innerModelValue = value;
            }
        }
    },

  methods: {
    emitChange() {
      this.$emit("update:modelValue", this.innerModelValue);
    },
  },
};
</script>