<template>
    <div>
        <h3>No tienes permiso para entrar a esta sección :(</h3>
        <img src="/broken-light-bulb.jpg">
    </div>
</template>

<script>
export default {
    components: {
    },

    data() {
        return {
        };
    },

    created() {
    },
};
</script>