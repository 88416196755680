import ClientApi from "./clientApi";

export default {
  saleChannel(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/sale_channel", {params: params});
  },
  salesMonths(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/sales_months", {params: params});
  },
  eventsByType(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/by_type", {params: params});
  },
  eventsByCustomer(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/by_customer", {params: params});
  },
  incomeByMonth(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/payments/income", {params: params});
  },
  quotesByDate(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/quotes_by_date", {params: params});
  },
  acceptedByDate(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/accepted_by_date", {params: params});
  },
  acceptedBySeller(params) {
    return ClientApi().get("https://api.relumbra.com/api/reports/events/accepted_by_seller", {params: params});
  },
}