<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteAccount"
      ></delete-modal>
    </transition>

    <date-filter />

    <charts class="mt-12" />

    <div class="flex justify-between items-center mt-12">
      <router-link
        class="primary-button w-88"
        :to="{ name: 'bank_accounts_add' }"
      >
        <span class="text-xl mr-2">+</span>Registrar una nueva Cuenta
        Bancaria</router-link
      >

      <button class="button-interactive p-2 bg-complement-500 text-white">
        Descargar Excel
      </button>
    </div>

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Cuenta Bancaria</th>
          <th class="p-2 border-x">Total calculado</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="account in accounts" :key="account.id">
          <td class="p-2 border-r">{{ account.id }}</td>
          <td class="p-2 border-x">{{ account.name }}</td>
          <td class="p-2 border-x">{{ formatPrice(account.total) }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'bank_accounts_edit',
                  params: {
                    id: account.id,
                    account: account,
                  },
                }"
              />

              <r-e-delete-button @buttonClicked="openDelete(account.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Charts from "./components/Charts";
import clientRequest from "../../../../api/clientRequest";
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
export default {
  name: "Indice",

  components: {
    Charts,
  },

  data() {
    return {
      accounts: [
        // {
        //   id: 1,
        //   name: "BANORTE",
        //   total: 15782,
        // },
        // {
        //   id: 1,
        //   name: "AMEX",
        //   total: -10987,
        // },
        // {
        //   id: 1,
        //   name: "EFECTIVO",
        //   total: 55002,
        // },
      ],
      loading: false,
    };
  },

  mixins: [deleteMixin],
  created() {
    this.loadAccounts();
  },
  methods: {
    async loadAccounts() {
      this.loading = true;

      try {
        this.clients = (await clientRequest.getAccounts()).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    openDelete(id) {
      this.openDeleteModal();
    },

    deleteAccount() {
      this.closeDeleteModal();
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>