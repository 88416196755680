<template>
  <div class="flex justify-evenly">
    <div>
      <div class="font-bold text-lg">Ingresos por Departamento</div>
      <Pie
        :chart-options="chartOptions"
        :chart-data="accounts"
        :width="300"
        :height="300"
      />
      <div class="font-bold text-lg mt-4">Total: $150,000.00</div>
    </div>
    <div>
      <div class="font-bold text-lg">Egresos por Departamento</div>
      <Pie
        :chart-options="chartOptions"
        :chart-data="clients"
        :width="300"
        :height="300"
      />
      <div class="font-bold text-lg mt-4">Total: $100,000.00</div>
    </div>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
);
export default {
  components: { 
    Pie,
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
      },
      accounts: {
        labels: ["Departamento Uno", "Departamento Dos", "Departamento Tres"],
        datasets: [
          {
            backgroundColor: "#5a54fc",
            data: [10, 40, 50],
          },
        ],
      },
      clients: {
        labels: ["Departamento Uno", "Departamento Dos", "Departamento Tres"],
        datasets: [
          {
            label: "$MXN",
            backgroundColor: "#fc54a3",
            data: [1000, 2023, 1233],
          },
        ],
      },
    };
  },
};
</script>
