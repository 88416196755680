<template>
<div>
  <date-filters v-model="date_filters" @onLoad="loadEvents()"></date-filters>
  <div class="w-full flex flex-wrap">
    <select-input v-model="filterStatuses">
      <option value="">Todos</option>
      <option :value="['accepted', 'mounted']">Aceptados/Montados</option>
      <option :value="['unmounted']">Desmontados/Finalizados</option>
      <option :value="['cancelled', 'rejected']">Cancelados/Rechazados</option>
    </select-input>
  </div>
  <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadEvents()">Filtrar</r-e-primary-button>

  <table v-if="!loading" class="table-auto mt-8 w-full text-sm md:text-base">
    <thead class="text-left">
      <tr class="border-b">
        <th class="p-2 border-r">ID</th>
        <th class="p-2 border-x">Fecha Evento</th>
        <th class="p-2 border-x">
          Nombre del cliente<br>
          <span style="font-size: 12px;">Vendedor</span>
        </th>
        <th class="p-2 border-x">Tipo del evento</th>
        <th class="p-2 border-x">Cat. de productos</th>
        <th class="p-2 border-x">Nombre evento</th>
        <th class="p-2 border-x">Monto</th>
        <th class="p-2 border-x">Restante por pagar</th>
        <th class="p-2 border-l">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr :class="{ 'event-row': true , 'yellow-background': event.status.name == 'mounted', 'gray-background': event.status.name == 'unmounted', 'negativeDifference': calculateDifference(event) >= 100 }" v-for="event in events" :key="event.id">
        <td class="p-2 border-r">{{ event.id }}</td>
        <td class="p-2 border-r">{{ moment(event.event_date).format('DD MMM YYYY') }}</td>
        <td class="p-2 border-r">
          {{ event.customer ? event.customer.full_name : null }}<br>
          <span style="font-size: 12px;" v-if="event.seller">{{ event.seller.name }}</span>
        </td>
        <td class="p-2 border-r">{{ event.event_type }}</td>
        <td class="p-2 border-r">
          <ul>
            <li v-for="category in event.categories_grouped" :key="category.id">{{ category.name }}</li>
          </ul>
        </td>
        <td class="p-2 border-r">{{ event.name }}</td>
        <td class="p-2 border-r">{{ formatPrice(event.total) }}</td>
        <td class="p-2 border-r">{{ formatPrice(calculateDifference(event)) }}</td>
        <td class="p-2 border-l">
          <div class="flex">
            <r-e-detail-button
              class="mr-2"
              :to="{
                name: 'events_dashboard',
                params: {
                  id: event.id,
                },
              }"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
  </div>
</div>
</template>

<script>
import clientRequest from "@/api/clientRequest";
import deleteMixin from "@/mixins/deleteMixin";
import { integerToCurrency } from "@/utils/currency";
import moment from 'moment';
import SelectInput from '@/components/SelectInput.vue';
import activeFiltersMixin from "@/mixins/activeFilters";
import { is422 } from "@/utils/response";
import DateFilters from '@/components/DateFilters.vue';

export default {
  name: "Indice",
  components: { SelectInput, DateFilters },

  data() {
    return {
      user: {},
      loading: false,
      events: [],
      moment: null,
      search: '',
      filterStatuses: '',
      date_filters: {},
    };
  },

  mixins: [deleteMixin, activeFiltersMixin],

  created() {
    this.user = this.$store.state.user;
    this.getFilters();
    // this.loadClients();
    this.moment = moment;
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.filterStatuses = filters.statuses || '';
    },
    async loadEvents() {
      this.loading = true;

      try {
        let params = {
          start_date: this.date_filters.filter_start,
          end_date: this.date_filters.filter_end,
          statuses: this.filterStatuses,
          pending_to_pay: true,
        };
        this.setActiveFilters({
          ...params,
          ...this.date_filters,
        });

        this.events = (await clientRequest.getEvents({status: 'after_quote', query_params: params})).data.data;
      } catch (error) {
        console.log(error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    
    async loadClients() {
      this.loading = true;

      try {
        this.clients = (await clientRequest.getClients()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    calculateTotalPayments(event) {
      return event.payments.reduce((total, payment) => total + payment.amount, 0);
    },
    calculateDifference(event) {
      return event.total - this.calculateTotalPayments(event);
    },

    openDelete(event) {
      this.selectedEvent = event;
      this.openDeleteModal();
    },

    deleteQuote() {
      this.selectedEvent = {};
      this.closeDeleteModal();
    },

    async deleteEvent() {
      this.loading = true;

      try {
          let data = await clientRequest.deleteEvent(this.selectedEvent.id);

          this.$store.dispatch("successSnack", "Evento eliminado");
          this.events.splice(this.events.indexOf(this.selectedEvent), 1);
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
      this.loading = false;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>

<style scoped>
.yellow-background {
  background-color: #f4fd68;
}

.negativeDifference {
  background-color: #00ffff !important;
  color: black;
}

.pink-background {
  background-color: #ff577f;
}

.gray-background {
  background-color: #f1f1f1;
}

.event-row {
  border: 1px solid #484848;
}
</style>
