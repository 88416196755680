<template>
  <div class="w-full">
    <div class="flex w-full justify-evenly py-4">
      <router-link
        class="title button-interactive"
        v-for="tab in tabs"
        :key="tab.route"
        :to="{ name: tab.route }"
        :class="[
          { 'text-secondary-500 underline': tab.route === financesSelectedTab },
        ]"
        >{{ tab.name }}</router-link
      >
    </div>
    <div class="flex w-full justify-evenly py-4">
      <template  v-for="tab in tabs2" :key="tab.route">
        <router-link
          class="title button-interactive"
          :to="{ name: tab.route }"
          :class="[
            { 'text-secondary-500 underline': tab.route === financesSelectedTab },
          ]"
          >{{ tab.name }}</router-link
        >
        <div v-if="tab.route == 'accounts_payable'" style="position: relative;">
          <span v-if="expiredPaymentsCount > 0"
            style="position: absolute; right: 0; top: 3px; margin-right: -10px;"
            class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
            {{ expiredPaymentsCount }}
          </span>
        </div>
      </template>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Finanzas",

  data() {
    return {
      tabs: [
        {
          name: "Ingresos",
          route: "income",
        },
        {
          name: "Egresos",
          route: "expenses",
        },
        {
          name: "Transferencias",
          route: "bank_account_transfers",
        },
        {
          name: "Balances",
          route: "balances",
        },
        // {
        //   name: "Cuentas Bancarias",
        //   route: "bank_accounts",
        // },
        // {
        //   name: "Departamentos",
        //   route: "departments",
        // },
      ],
      tabs2: [
        {
          name: "Eventos por cobrar",
          route: "events_collection",
        },
        {
          name: "Cuentas por pagar",
          route: "accounts_payable",
        },
        {
          name: "Finanzas global",
          route: "income_outcome",
        },
        {
          name: "Categorías",
          route: "categories",
        },
        {
          name: "Subcategorías",
          route: "subcategories",
        },
      ]
    };
  },

  computed: {
    ...mapState({
      financesSelectedTab: "financesSelectedTab",
      expiredPaymentsCount: "expiredPaymentsCount",
    }),
  },

  created() {
    this.$store.commit("setSelectedTab", "finances");
    // this.$router.push({ name: this.financesSelectedTab });
  },
};
</script>