import ClientApi from "./clientApi";

export default {
    getEvents({status, statuses, products, no_past, query_params}) {
    if (statuses) {
      return ClientApi().get("/projects", {
        params: { 
          statuses: statuses,
          products: products ?? false,
          no_past: no_past ?? false,
          ...query_params
        }});
    } else if (status) {
      return ClientApi().get("/projects?status=" + status, {params: {products: products ?? false, no_past: no_past ?? false, ...query_params }});
    } else {
      return ClientApi().get("/projects", {params: query_params});
    }
  },

  getEventsFromClient(client_id) {
    return ClientApi().get("/projects?customer_id=" + client_id);
  },

  storeEvent(form) {
    return ClientApi().post("/projects", form);
  },

  updateEvent(id, form) {
    return ClientApi().put("/projects/"+id, form);
  },

  changeEventStatus(id, newStatus) {
    return ClientApi().post("/projects/change_status/"+id, newStatus);
  },

  getEvent(id) {
    return ClientApi().get("/projects/" + id);
  },

  deleteEvent(id) {
    return ClientApi().delete("/projects/"+id);
  },

  generateEventPDF(id) {
    return ClientApi().get("https://api.relumbra.com/api/projects/print/" + id, {
      responseType: 'blob',
    });
  },

  updateEventBackgroundColor(id, background_color) {
    return ClientApi().post("https://api.relumbra.com/api/projects/background_color/" + id, background_color);
  },

  cloneEvent(form) {
    return ClientApi().post("/clone_project", form);
  },
  getEventsIds() {
    return ClientApi().get("/projects_names_id");
  }
}