<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="installer" value="Instalador encargado" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.installer"
            >
              <option
                v-for="installer in installers"
                :key="installer.id"
                :value="installer.name"
              >
                {{ installer.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Direccion del evento (escrita)" />
          <r-e-input id="name" type="text" v-model="form.event_address" />
        </div>
        <div class="flex flex-col w-100 mt-6 pr-4">
          <r-e-label
            for="location_url"
            value="Dirección del evento (URL de Google Maps)"
          />
          <r-e-input
            id="location_url"
            type="text"
            v-model="form.location_url"
          />
        </div>
        
      </div>
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="setup_dates" value="Fecha montaje" />
          <vue-date-picker
            name="setup_dates"
            v-model="form.setup_dates"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="dismount_dates" value="Fecha desmontaje" />
          <vue-date-picker
            name="setup_dates"
            v-model="form.setup_dates"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        
      <div class="flex flex-col mt-6">
          <r-e-label for="setup_instructions" value="Instrucciones para montaje" />
          <textarea
            class="
              border border-gray-700
              py-2
              px-3
              outline-none
              hover:border-secondary-500
              focus:border-secondary-500
              smooth
            "
            v-model="form.setup_instructions"
            id="setup_instructions"
          ></textarea>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="editEvent">Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import validationErrors from "../../../mixins/validationErrors";

export default {
  name: "Formulario",

  components: {
  },

  mixins: [validationErrors],

  data() {
    return {
      isEditing: false,
      form: {
        event_date: null,
        setup_dates: null,
        dismount_dates: null,
        location_url: null,
        description: "",
        event_address: "",
        installer: "",
        setup_instructions: "",
      },
      timePickerOptions: {
        start: "00:00",
        step: "01:00",
        end: "23:00",
        format: "HH:mm",
      },
      branches: [],
      clients: [],
      installers: [],
      sellers: [],
      event_types: [],
    };
  },



  computed: {
    getContactsOfClient() {
      if (!this.form.client.id) {
        return [];
      }

      return this.clients.filter((client) => {
        return client.id === this.form.client.id;
      })[0].contacts;
    },
  },

  created() {
    // if (this.$route.params.events) {
    //   this.isEditing = true;
    //   this.form = JSON.parse(JSON.stringify(this.$route.params.events));
    // }
    this.loadEvent();
  },

  methods: {
    async loadEvent() {
      this.loading = true;
      let event_id = this.$route.params.id;

      try {
        this.form = (await clientRequest.getEvent(event_id)).data.data;

        this.form.setup_dates = [this.form.setup_date_start, this.form.setup_date_end];
        this.form.dismount_dates = [this.form.dismount_date_start, this.form.dismount_date_end];

      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    // async next() {
    //   this.$router.push({ name: "events_add_final" });
    // },

    async editEvent() {
      this.loading = true;

      try {
          let data = await clientRequest.updateEvent(this.form.id, this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "events_dashboard", params: { id: this.form.id } });
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    }
  },
};
</script>