<template>
  <div class="">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteIncome"
      ></delete-modal>
    </transition>

    <div v-if="event">
      <div class="">
        <detail :set-event="event" @reload="loadEvent"></detail>
        <event-files :set-event="event"></event-files>
      </div>

      <div class="flex-col shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Pagos</h3>

        <div class="flex justify-between items-center">
          <router-link class="primary-button" 
            :to="{ 
              name: 'income_add',
              query: {
                event_id: this.event.id
              }
            }">
            <span class="text-xl mr-2">+</span>Agregar pago
          </router-link>
        </div>

        <table class="table-auto w-full text-sm md:text-base">
          <thead class="text-left">
            <tr class="border-b">
              <th class="p-2 border-r">Fecha de pago</th>
              <th class="p-2 border-x">Cantidad pagada</th>
              <th class="p-2 border-x">Cuenta de pago</th>
              <th class="p-2 border-x">Nota de pago</th>
              <th class="p-2 border-l">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="payment in event.payments" :key="payment.id">
              <td class="p-2 border-r">{{ formatDate(payment.payed_at) }}</td>
              <td class="p-2 border-r">{{ formatPrice(payment.amount) }}</td>
              <td class="p-2 border-r">{{ payment.bank_account ? payment.bank_account.name : 'sin cuenta' }}</td>
              <td class="p-2 border-r">{{ payment.description }}</td>
              <td class="p-2 border-l">
                <div class="flex">
                  <r-e-edit-button
                    class="mr-2"
                    :to="{
                      name: 'income_edit',
                      params: {
                        id: payment.id,
                        payment: payment,
                      },
                      query: {
                        event_id: this.event.id
                      }
                    }"
                  />

                  <r-e-delete-button @buttonClicked="openDelete(payment)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-col shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Productos para el evento</h3>

        <div class="flex mt-3 justify-between items-center">
          <button class="primary-button" @click="showInventoryModal = true">Ver inventario</button>
        </div>

        <table class="table-auto w-full text-sm md:text-base">
          <thead class="text-left">
            <tr class="border-b">
              <th class="p-2 border-r">SKU</th>
              <th class="p-2 border-r">Nombre</th>
              <th class="p-2 border-x">Precio</th>
              <th class="p-2 border-x">Color</th>
              <th class="p-2 border-x">Cantidad</th>
              <th class="p-2 border-x">Subtotal</th>
              <th class="p-2 border-l">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="{'error-message': product.error_state}" v-for="product in event.leasing.products" :key="product.id">
              <td class="p-2 border-r">{{ product.compound_product ? product.compound_product.id : 'borrado' }}</td>
              <td class="p-2 border-r">{{ product.compound_product ? product.compound_product.name : "borrado" }}</td>
              <td class="p-2 border-r">{{ formatPrice(product.price) }}</td>
              <td class="p-2 border-r">{{ product.color }}</td>
              <td class="p-2 border-r">{{ product.quantity }}</td>
              <td class="p-2 border-r">{{ formatPrice(product.price * product.quantity) }}</td>
              <td class="p-2 border-r">
                <span v-if="product.error_state">
                  <button class="primary-button danger-button" @click="showInventoryModal = true">Ver inventario faltante</button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-col shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Egresos</h3>

        <!-- <div class="flex justify-between items-center">
          <router-link class="primary-button" :to="{ name: 'events_add' }">
            <span class="text-xl mr-2">+</span>Agregar egreso</router-link
          >
        </div> -->

        <table class="table-auto w-full text-sm md:text-base">
          <thead class="text-left">
            <tr class="border-b">
              <th class="p-2 border-r">Fecha de egreso</th>
              <th class="p-2 border-x">Cantidad pagada</th>
              <th class="p-2 border-x">Método de pago</th>
              <th class="p-2 border-x">Concepto</th>
              <th class="p-2 border-l">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="expense in expenses" :key="expense.id">
              <td class="p-2 border-r">{{ expense.date }}</td>
              <td class="p-2 border-r">{{ expense.amount }}</td>
              <td class="p-2 border-r">{{ expense.method }}</td>
              <td class="p-2 border-r">{{ expense.concept }}</td>
              <td class="p-2 border-l">
                <div class="flex">
                  <r-e-edit-button
                    class="mr-2"
                    :to="{
                      name: 'expenses_edit',
                      params: {
                        id: expense.id,
                        expense: expense,
                      },
                    }"
                  />

                  <!-- <r-e-delete-button @buttonClicked="openDelete(expense.id)" /> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="showInventoryModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
        <div class="form relative">
          <div class="w-full">
            <button class="link-button mt-4 float-right" style="width: fit-content;" @click.prevent="showInventoryModal = false">
              Cerrar
            </button>
          </div>
          <table class="table-auto w-full text-sm md:text-base">
            <thead class="text-left">
              <tr class="border-b">
                <th class="p-2 border-r">SKU</th>
                <th class="p-2 border-r">Nombre</th>
                <th class="p-2 border-x">Color</th>
                <th class="p-2 border-x">Cantidad</th>
                <th class="p-2 border-x">Items</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in event.leasing.products" :key="product.id">
                <td :class="{'error-message': product.error_state, 'p-2 border': true}">{{ product.compound_product ? product.compound_product.id : 'borrado' }}</td>
                <td :class="{'error-message': product.error_state, 'p-2 border': true}">{{ product.compound_product ? product.compound_product.name : "borrado" }}</td>
                <td :class="{'error-message': product.error_state, 'p-2 border': true}">{{ product.color }}</td>
                <td :class="{'error-message': product.error_state, 'p-2 border': true}">{{ product.quantity }}</td>
                <td class="p-2 border">
                  <ul class="ul-list" v-for="item in product.items" :key="item.id">
                    <li :class="{'error-message': item.total_needed > item.available}">{{ item.name }}: necesarios: {{ item.total_needed }} <br>disponibles: {{ item.available }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import clientRequest from "../../../api/clientRequest";
import Detail from "./dashboard/Detail"
import EventFiles from "./dashboard/Files"
import { integerToCurrency } from "../../../utils/currency";
import deleteMixin from "../../../mixins/deleteMixin";
import { is422 } from "../../../utils/response";

export default {
  name: "Dashboard",

  components: {
    Detail,
    EventFiles
  },

  mixins: [deleteMixin],

  data() {
    return {
      event: null,
      expenses: [],
      showInventoryModal: false,
    };
  },

  created() {
    // this.event = this.$route.params.event;
    this.loadEvent();
  },

  methods: {
    async loadEvent(reload_event_id) {
      this.event = null;
      this.loading = true;
      let event_id = this.$route.params.id;
      if (reload_event_id && reload_event_id.event_id) {
        event_id = reload_event_id.event_id;
      }

      try {
        this.event = (await clientRequest.getEvent(event_id)).data.data;
        this.checkAvailability();
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async checkAvailability() {
      this.loading = true;

      let params = {
        leasing_id: this.event.leasing_id,
        warehouse_id: 2, // todo: quitar hardcode del warehouse de bodega
        start_date: this.event.setup_date_start,
        end_date: this.event.dismount_date_end,
        compound_products: this.event.leasing.products,
        throw_exception: false,
      }

      try {
          let response = await clientRequest.checkProductAvailabilityForEvent(params);
          if (response.data.data.compound_products) { 
            this.event.leasing.products.forEach((leased_product) => {
              response.data.data.compound_products.forEach((compound_product) => {
                if(compound_product.id == leased_product.compound_product.id) {
                  leased_product.items = compound_product.products;

                  if(compound_product.error_state) {
                    leased_product.error_state = true;
                  }
                }
              })
            });
          }
          this.showChangeStatusModal = false;
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            if(error.response.data.message) {
              this.error_message = error.response.data.message;
            }
          }
        }
    },

    openDelete(payment) {
      this.selectedPayment = payment;
      this.openDeleteModal();
    },

    async deleteIncome() {
      this.loading = true;

      try {
        await clientRequest.deletePayment(this.selectedPayment.id);
        this.loadEvent();
        this.selectedPayment = {};
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.closeDeleteModal();
    },

    formatDate(date) {
      return moment(date).locale("es").format("LL");
    },
    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>

<style scoped>
.danger-button {
  height: 2em;
  background-color: #ff6b6b;
}
</style>