<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteRole"
      ></delete-modal>
    </transition>

    <div v-if="!loading">
      <router-link class="primary-button mt-6 w-48" :to="{ name: 'role_add' }">
        <span class="text-xl mr-2">+</span>Agregar Rol</router-link
      >

      <table class="table-auto mt-8 text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Rol</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="role in roles" :key="role.id">
            <td class="p-2 border-r">{{ role.id }}</td>
            <td class="p-2 border-x">{{ role.name }}</td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'role_edit',
                    params: {
                      id: role.id,
                      role: role,
                    },
                  }"
                />
                <r-e-delete-button @buttonClicked="openDelete(role.id)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import deleteMixin from "../../../../mixins/deleteMixin";
import clientRequest from "../../../../api/clientRequest";
export default {
  name: "Indice",

  data() {
    return {
      roles: [],
      loading: false,
    };
  },

  mixins: [deleteMixin],

  created() {
    this.loadRoles();
  },

  methods: {
    async loadRoles() {
      this.loading = true;

      try {
        this.roles = (await clientRequest.getRoles()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    openDelete(id) {
      this.openDeleteModal();
    },

    deleteRole() {
      this.closeDeleteModal();
    },
  },
};
</script>