<template>
  <div class="">
    <div v-if="product">
      <div class="flex-col shadow-lg p-4 rounded w-full">
        <h3 class="font-bold text-lg">Detalles del item</h3>

        <div class="flex mt-3">
          <div class="font-bold">Nombre:</div>
          <div class="ml-2">{{ product.name }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">SKU:</div>
          <div class="ml-2">{{ product.sku }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Color:</div>
          <div class="ml-2">{{ product.color }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Descripción:</div>
          <div class="ml-2">{{ product.description }}</div>
        </div>

        <div class="flex mt-3">
          <div class="font-bold">Fecha de creación:</div>
          <div class="ml-2">{{ formatDate(product.created_at) }}</div>
        </div>

        <h3 class="font-bold text-lg mt-3">Metas</h3>

        <div
          v-for="meta in product.metas"
          :key="meta.id"
          class="flex flex-col mt-3 border p-3"
        >
          <div class="flex">
            <div class="font-bold">Meta:</div>
            <div class="ml-2">{{ meta.meta_name }}</div>
          </div>

          <div class="flex mt-3">
            <div class="font-bold">Valor:</div>
            <div class="ml-2">{{ meta.value }}</div>
          </div>

          <div class="flex mt-3">
            <div class="font-bold">Descripción:</div>
            <div class="ml-2">{{ meta.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { integerToCurrency } from "../../../utils/currency";
export default {
  name: "Detalle",

  data() {
    return {
      product: null,
    };
  },

  created() {
    this.product = this.$route.params.product;
  },

  methods: {
    formatDate(date) {
      return moment(date).locale("es").format("LLL");
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>