<template>
    <div class="w-full flex flex-wrap">
        <div class="">
          <date-filters v-model="date_filters" @onLoad="loadReport()"></date-filters>

          <select-input v-model="filter_status_id" placeholder="Selecciona un estatus">
            <option value="1,7">Cotizaciones</option>
            <option value="2,3,4">Aceptados</option>
            <option value="5">Rechazados</option>
            <option value="6">Cancelados</option>
          </select-input>

          <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadReport()">Filtrar</r-e-primary-button>
        </div>
      </div>
      <div class="w-full flex flex-wrap">
        <div class="w-full graph_container">
            <div class="font-bold text-lg">Eventos por tipo</div>
            <Bar
                :options="chartOptions"
                :data="report"
            />
        </div>
    </div>
</template>

<script>
  import { integerToCurrency } from "@/utils/currency";
  import clientRequest from "@/api/clientRequest";
  import moment from "moment";
  import { Bar } from "vue-chartjs";
  import SelectInput from '@/components/SelectInput.vue';
  import DateFilters from '@/components/DateFilters.vue';
  
  export default {
    name: "EventsByType",

    components: {
        Bar,
        SelectInput,
        DateFilters
    },
  
    data() {
      return {
        report: {labels: ['a'], datasets:[ {data: [1]}]},
        chartOptions: {
            responsive: true,
        },
        filter_status_id: null,
        date_filters: {},
      };
    },
  
    created() {
    },
  
    methods: {
        async loadReport() {
        this.loading = true;

        let params = {
          start_date: this.date_filters.filter_start,
          end_date: this.date_filters.filter_end,
          status_ids: this.filter_status_id,
        };
  
        try {
          this.report = (await clientRequest.ReportRequests.eventsByType(params)).data.data;
        } catch (error) {
          console.log('error', error)
        }
  
        this.loading = false;
      },

      formatPrice(price) {
        return integerToCurrency(price);
      },
      formatDate(date) {
        return moment(date).locale("es").format("LL");
      },
      formatBool(val) {
        return val ? "Si" : "No";
      },
    },
  };
</script>
