<template>
  <label class="text-sm font-bold">
    <span v-if="value">{{ value }}</span>
    <span v-if="required" class="text-red-500">*</span>
  </label>
</template>

<script>
    export default {
        props: ['value', 'required']
    }
</script>

