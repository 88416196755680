<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="email" value="Correo electrónico" />
          <r-e-input id="email" type="text" v-model="form.email" />
          <v-errors :errors="errorFor('email')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="password" value="Contraseña" />
          <r-e-input id="password" type="password" v-model="form.password" />
          <v-errors :errors="errorFor('password')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="password_confirmation" value="Confirmar contraseña" />
          <r-e-input
            id="password_confirmation"
            type="password"
            v-model="form.password_confirmation"
          />
          <v-errors :errors="errorFor('password_confirmation')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="name" value="Nombre completo" />
          <r-e-input id="name" type="text" v-model="form.name" />
          <v-errors :errors="errorFor('name')"></v-errors>
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="rfc" value="RFC" />
          <r-e-input id="rfc" type="text" v-model="form.rfc" />
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="NSS" value="NSS" />
          <r-e-input id="NSS" type="text" v-model="form.nss" />
        </div>

        <div class="flex flex-col w-72 mt-6 mr-4">
          <r-e-label for="CURP" value="CURP" />
          <r-e-input id="CURP" type="text" v-model="form.curp" />
        </div>

        <select-input v-model="form.role_id" placeholder="Selecciona un rol">
          <option v-for="role in roles" :key="role.id" :value="role.id">
            {{ role.display_name }}
            </option>
        </select-input>

        <select-input v-model="form.branch_id" placeholder="Selecciona una sucursal">
          <option v-for="branch in branches" :key="branch.id" :value="branch.id">
            {{ branch.name }}
            </option>
        </select-input>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from '@/api/clientRequest';
import { is422 } from '@/utils/response';
import validationErrors from '@/mixins/validationErrors';
import SelectInput from '@/components/SelectInput.vue';
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "Formulario",

  components: {
    SelectInput
  },

  mixins: [validationErrors],

  data() {
    return {
      isEditing: false,
      dropzoneOptions: {
        url: "placeholder",
        addRemoveLinks: true,
        maxFiles: 1,
        autoProcessQueue: false,
      },
      form: {},
      roles: [],
      branches: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadUser(this.$route.params.id);
    }

    this.loadBranches();
    this.loadRoles();
  },

  methods: {
    async loadUser(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getUser(id)).data.data;
        this.form.role_id = this.form.roles[0].id;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadBranches() {
      this.loading = true;

      try {
        this.branches = (await clientRequest.getBranches()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadRoles() {
      this.loading = true;

      try {
        this.roles = (await clientRequest.getRoles()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    save() {
      if (!this.isEditing) {
        this.saveUser();
      } else {
        this.updateUser();
      }
    },

    async saveUser() {
      this.loading = true;

      try {
        await clientRequest.storeUser(this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "user_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },

    async updateUser() {
      this.loading = true;

      try {
        await clientRequest.updateUser(this.form.id, this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "user_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
  },
};
</script>