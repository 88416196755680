<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteExpense"
      ></delete-modal>
    </transition>

    <div v-if="expense">
      <div class="flex mt-3">
        <div class="font-bold">Fecha de registro de egreso:</div>
        <div class="ml-2">2022-04-12</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Fecha de egreso:</div>
        <div class="ml-2">{{ expense.date }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Egreso registrado por:</div>
        <div class="ml-2">{{ expense.registrant }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Forma de pago:</div>
        <div class="ml-2">{{ expense.account.name }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Monto del egreso:</div>
        <div class="ml-2">{{ formatPrice(expense.amount) }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Departamento:</div>
        <div class="ml-2">{{ expense.department.name }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Lugar:</div>
        <div class="ml-2">{{ expense.seller }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Descripción:</div>
        <div class="ml-2">{{ expense.description }}</div>
      </div>

      <button class="button-interactive p-2 bg-complement-500 text-white mt-8 mr-4">
        Descargar XML
      </button>

      <button class="button-interactive p-2 bg-complement-500 text-white mt-8">
        Descargar PDF
      </button>

      <div class="flex mt-6">
        <r-e-edit-button
          class="mr-2"
          :to="{
            name: 'expenses_edit',
            params: {
              id: expense.id,
              expense: expense,
            },
          }"
        />

        <r-e-delete-button @buttonClicked="openDeleteModal" />
      </div>
    </div>
  </div>
</template>

<script>
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
export default {
  name: "Detalle",

  data() {
    return {
      expense: null,
    };
  },

  mixins: [deleteMixin],

  created() {
    if (this.$route.params.expense) {
      this.expense = JSON.parse(JSON.stringify(this.$route.params.expense));
    }
  },

  methods: {
    formatPrice(price) {
      return integerToCurrency(price);
    },

    deleteExpense() {
      this.closeDeleteModal();
    },
  },
};
</script>