<template>
    <div>
      <transition name="modal">
      </transition>
      <LoadingModal v-if="loadingUpdate"></LoadingModal>

      <div v-if="showConflictModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
        <div class="form relative">
            <div>Evento: {{ selectedConflict.project.name }} <span v-if="selectedConflict.project.deleted_at">(Borrado)</span></div>
            <div>Evento ID: {{ selectedConflict.project.id }}</div>
            <div>Detalle: <pre>{{ selectedConflict.message }}</pre></div>

            <div class="flex flex-col mt-6">
            <r-e-label for="solution" value="Solución" />
            <textarea
              class="border border-gray-700 py-2 px-3 outline-none hover:border-secondary-500 focus:border-secondary-500 smooth" v-model="selectedConflict.solution" id="solution"></textarea>
          </div>

            <r-e-primary-button
              class="w-full mt-4"
              @buttonClicked="solveConflict"
              >Resolver
            </r-e-primary-button>

            <button class="link-button mt-4 mx-auto w-full" @click.prevent="showConflictModal = false">
              Cancelar
            </button>
        </div>
      </div>
  
      <div v-if="!loading">
        <r-e-input
          class="w-full mt-6"
          placeholder="Buscar"
          v-model="search"
          clear-text="true"
        />
  
        <table class="table-auto mt-8 w-full text-sm md:text-base">
          <thead class="text-left">
            <tr class="border-b">
              <th class="p-2 border-r">ID</th>
              <th class="p-2 border-x">Evento</th>
              <th class="p-2 border-x">Conflictos</th>
              <!-- <th class="p-2 border-x">Precio</th> -->
              <th class="p-2 border-l">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="conflict in conflicts" :key="conflict.id" :class="{'solved': conflict.is_dismissed}">
              <td class="p-2 border-r">{{ conflict.id }}</td>
              <td class="p-2 border-r">{{ conflict.project?.name }} <span v-if="conflict.project?.deleted_at"><br>(Borrado)</span></td>
              <td class="p-2 border-r conflict-message"><pre class="">{{ conflict.message }}</pre></td>
              <!-- <td class="p-2 border-r">{{ formatPrice(product.price) }}</td> -->
              <td class="p-2 border-l">
                <div class="flex">
                  <router-link
                    v-if="conflict.project"
                    class="mr-2 link-button"
                    :to="{
                      name: 'events_dashboard',
                      params: {
                        id: conflict.project.id,
                      },
                    }"
                  >Ver Evento</router-link>
                  <r-e-detail-button class="mr-2" @buttonClicked="openSummary(conflict)"/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-else class="flex w-full h-full items-center">
        <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
      </div>
    </div>
</template>

<script>
  import clientRequest from "../../../api/clientRequest";
  import deleteMixin from "../../../mixins/deleteMixin";
  import { integerToCurrency } from "../../../utils/currency";
  import activeFiltersMixin from "@/mixins/activeFilters";
  import { is422 } from "../../../utils/response";
  import LoadingModal from "@/components/LoadingModal";
  
  export default {
    components: {LoadingModal},

    name: "Indice",
  
    data() {
      return {
        loading: false,
        loadingUpdate: false,
        conflicts: null,
        search: '',
        selectedConflict: {},
        showConflictModal: false,
      };
    },
  
    mixins: [deleteMixin, activeFiltersMixin],
  
    created() {
    //   this.getFilters();
      this.loadConflicts();
    },
  
    computed: {
    },
  
    methods: {
      getFilters() {
        let filters = this.getActiveFilters();
        this.search = filters.query_text || '';
      },

      openSummary(conflict) {
        this.selectedConflict = conflict;
        this.showConflictModal = true;
      },
  
      async loadConflicts() {
        this.loading = true;
  
        try {
          this.conflicts = (await clientRequest.getInventoryConflicts()).data.data;
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
  
        this.loading = false;
      },

      async solveConflict() {
        this.loadingUpdate = true;

        try {
          let response = await clientRequest.updateInventoryConflict(this.selectedConflict.id, this.selectedConflict);
          this.$store.dispatch("successSnack", "Guardado exitosamente.");
          this.selectedConflict.is_dismissed = true;
          this.selectedConflict = null;
          this.showConflictModal = false;
          const event = new Event("refreshInventoryConflicts");
          document.dispatchEvent(event);
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }

        this.loadingUpdate = false;
      },
    },
  };
</script>

<style scoped>
.solved {
  background-color: #c0c0c0;
}

.conflict-message {
  max-width: 50vw;
  overflow-x: scroll;
}
</style>