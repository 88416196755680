<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre del producto" />
          <r-e-input id="name" type="text" v-model="form.name" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="price" value="Precio" />
          <r-e-input id="price" type="number" v-model="form.price" />
        </div>
      </div>

      <div class="w-full flex flex-wrap">
        <select-input v-model="form.category_id" placeholder="Selecciona una categoria">
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select-input>

        <select-input v-model="form.taxonomy_id" placeholder="Selecciona una familia">
          <option v-for="taxonomy in taxonomies" :key="taxonomy.id" :value="taxonomy.id">
            {{ taxonomy.name }}
          </option>
        </select-input>
      </div>

      <div class="flex flex-col mt-6">
        <vue3-simple-typeahead
          class="border border-gray-700 py-2 px-3"
          placeholder="Buscar item"
          :items="products"
          :itemProjection="(item) => {return item.name}"
          @selectItem="selectProduct"
        ></vue3-simple-typeahead>
      </div>

      <div class="flex flex-col mt-6">
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li v-for="product in selectedProducts" :key="product.id">
              <a href="#" class="block hover:bg-gray-50">
                <div class="px-4 py-4 flex items-center sm:px-6">
                  <div
                    class="
                      min-w-0
                      flex-1
                      sm:flex sm:items-center sm:justify-between
                    "
                  >
                    <div class="truncate">
                      <div class="flex text-sm">
                        <p class="font-medium text-indigo-600 truncate">
                          {{ product.sku }}
                        </p>
                        ,
                        <p class="ml-1 flex-shrink-0 font-normal text-gray-500">
                          {{ product.name }}
                        </p>
                      </div>
                      <div class="mt-2 flex">
                        <div class="flex items-center text-sm text-gray-500">
                          <p>Cantidad requerida para producto:</p>
                          <div class="ml-4 flex justify-around items-center">
                            <button
                              class="
                                w-6
                                h-6
                                text-white
                                font-bold
                                bg-gray-500
                                rounded
                              "
                              @click.prevent="substractItem(product)"
                            >
                              -
                            </button>
                            <p class="text-sm text-gray-500 text-center mx-3">
                              <input v-model="product.quantity" type="number" step="1">
                            </p>
                            <button
                              class="
                                w-6
                                h-6
                                text-white
                                font-bold
                                bg-gray-500
                                rounded
                              "
                              @click.prevent="addItem(product)"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                      <div class="flex overflow-hidden -space-x-1"></div>
                    </div>
                  </div>
                  <div class="ml-5 flex-shrink-0"></div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <r-e-primary-button
        class="w-72 mt-6"
        :loading="loading"
        @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Formulario",

  components: {
    SelectInput,
  },

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        price: "",
        description: "",
        product_ids: [],
        category_id: null,
      },
      selectedProducts: [],
      loading: false,
      categories: [],
      taxonomies: [],
      products: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadCompoundProduct(this.$route.params.id);
    }
    this.loadCategories();
    this.loadProducts();
    this.loadTaxonomies();
  },

  methods: {
    async loadCompoundProduct(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getCompoundProduct(id)).data.data;
        if(this.form.category) {
          this.form.category_id = this.form.category.id;
        }
        this.form.products.forEach((item) => {
          item.quantity = item.pivot.quantity;
          this.selectedProducts.push(JSON.parse(JSON.stringify(item)));
        })
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    // search(input) {
    //   if (input.length < 1) {
    //     return [];
    //   }
    //   return this.products.filter((product) => {
    //     return (
    //       product.name.toLowerCase().includes(input.toLowerCase()) &&
    //       this.selectedProducts.filter((sp) => {
    //         return sp.id === product.id;
    //       }).length === 0
    //     );
    //   });
    // },

    // getResultValue(result) {
    //   return result.name;
    // },

    selectProduct(product) {
      var newProduct = JSON.parse(JSON.stringify(product));
      newProduct.quantity = 1;
      this.selectedProducts.push(newProduct);
    },

    addItem(product) {
      // console.log(product);
      product.quantity = parseInt(product.quantity) + 1;
    },

    substractItem(product) {
      if (product.quantity === 1) {
        this.removeProduct(product);
        return;
      }

      product.quantity = parseInt(product.quantity) - 1;
    },

    removeProduct(product) {
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
    },

    getQuantity(product) {
      return product.quantity;
    },

    async loadCategories() {
      this.loading = true;

      try {
        this.categories = (
          await clientRequest.getCategories("Hagane\\Ichizen\\Models\\Product")
        ).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadTaxonomies() {
      this.loading = true;

      try {
        this.taxonomies = (
          await clientRequest.getTaxonomies("Hagane\\Ichizen\\Models\\CompoundProduct")
        ).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadProducts() {
      this.loading = true;

      try {
        this.products = (await clientRequest.getProducts()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async save() {
      if(this.isEditing) {
        this.update();
        return;
      }
      this.loading = true;

      this.form.product_ids = {};
      this.selectedProducts.forEach((product) => {
        this.form.product_ids[product.id] = { quantity: product.quantity };
      });

      try {
        await clientRequest.storeCompoundProduct(this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "compound_products_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
    async update() {
      this.loading = true;

      this.form.product_ids = {};
      this.selectedProducts.forEach((product) => {
        this.form.product_ids[product.id] = { quantity: product.quantity };
      });

      try {
        await clientRequest.updateCompoundProduct(this.form.id, this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "compound_products_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
  },
};
</script>