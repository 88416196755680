<template>
    <div class="flex flex-col justify-center items-center fixed inset-0 bg-opacity-25 bg-black absolute" style="z-index: 10000;">
      <div class="form relative loading-modal">
        <img src="/loading.gif">
      </div>
    </div>
</template>

<script>
export default {
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.loading-modal {
    width: 100px;
    height: 117px;
}
</style>