<template>
  <div class="container">
  <input 
    :id="id"
    :type="type"
    class=" text_input
      border
      border-gray-700
      py-2
      px-3
      outline-none
      hover:border-secondary-500
      focus:border-secondary-500
      smooth
    " v-model="innerModelValue" @input="updateValue($event.target.value)" ref="input" :placeholder="placeholder"/>
  <span v-if="clearText" class="clear_text" @click="clearInput()">X</span>
  </div>
</template>

<style scoped>
.container {
  position: relative;
  display: flex;
  width: 100%;

}
.clear_text {
  float: right;
  margin: 5px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 22px;
}
.text_input {
  float: left;
  width: 100%;
}
</style>

<script>
export default {
  props: ["modelValue", 'placeholder', 'clearText', 'id', 'type'],

  data() {
    return {
      innerModelValue: null,
    }
  },

  created() {
    this.innerModelValue = this.modelValue;
  },

  watch: {
    'modelValue': function (value) {
      if (value !== this.innerModelValue) {
        this.innerModelValue = value;
      }
    }
  },

  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
    },
    clearInput() {
      this.innerModelValue = '';
      this.updateValue(this.innerModelValue);
    }
  },
};
</script>