<template>
  <div>
    <div class="w-full flex flex-wrap">
    <div class="flex flex-col w-72 mt-6 pr-4">
        <select-input v-model="form.branch_id" placeholder="Selecciona una sucursal">
          <option v-for="branch in branches" :key="branch.id" :value="branch.id">
            {{ branch.description }}
            </option>
        </select-input>
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <select-input v-model="form.customer_id" placeholder="Selecciona un cliente" @change="setDefaultDiscount">
          <option v-for="customer in customers" :key="customer.id" :value="customer.id">
            {{ customer.full_name }}
            </option>
        </select-input>
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <select-input v-model="form.seller_id" placeholder="Selecciona un vendedor">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
            </option>
        </select-input>
    </div>

    <div v-if="!isEditing" class="flex flex-col w-72 items-center justify-center">
      <toggle-input v-model="form.create_new_customer" @input="toggleNewCustomer">Crear cliente nuevo</toggle-input>
    </div>
    
    <div v-if="form.create_new_customer" class="border p-5 m-5">
      <h5>Datos del cliente nuevo</h5>
      <create-client v-model="form.customer"></create-client>
    </div>
    
    
    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="event_type" value="Selecciona un tipo de evento" />
        <div class="relative">
        <select class=" border border-gray-700 py-2 px-3 outline-none focus:border-secondary-500 smooth appearance-none w-full" id="type" v-model="form.event_type">
            <option v-for="event_type in event_types" :key="event_type.id" :value="event_type.name">
              {{ event_type.name }}
            </option>
        </select>
        <div class=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </div>
        </div>
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="name" value="Nombre del evento" />
        <r-e-input id="name" type="text" v-model="form.name" />
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="name" value="Direccion del evento (escrita)" />
        <r-e-input id="name" type="text" v-model="form.event_address" />
    </div>
    </div>
    <div class="w-full flex flex-wrap">
    <div class="flex flex-col mt-6 pr-4">
        <r-e-label for="event_date" value="Fecha del evento" />
        <vue-date-picker
          name="event_date"
          v-model="form.event_date"
          model-type="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd h:mm:ss a"
          :enable-time-picker="true"
          :is-24="false"
          locale="es"
          input-class-name="w-full">
        </vue-date-picker>
    </div>
    </div>
    <div class="w-full flex flex-wrap">
    <div class="flex flex-col mt-6 pr-4">
        <r-e-label for="setup_date_start" value="Fecha montaje" />
        <vue-date-picker
          name="setup_date_start"
          v-model="form.setup_date_start"
          model-type="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd h:mm:ss a"
          :enable-time-picker="true"
          :is-24="false"
          locale="es"
          input-class-name="w-full">
        </vue-date-picker>
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="start_time" value="Tiempo (horas) de montaje" />
        <r-e-input id="start_time" type="number" v-model="form.setup_time" />
    </div>
    </div>
    <div class="w-full flex flex-wrap">
    <div class="flex flex-col mt-6 pr-4">
        <r-e-label for="dismount_date_start" value="Fecha desmontaje" />
        <vue-date-picker
          name="dismount_date_start"
          v-model="form.dismount_date_start"
          model-type="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd h:mm:ss a"
          :enable-time-picker="true"
          :is-24="false"
          locale="es"
          input-class-name="w-full">
        </vue-date-picker>
    </div>
    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="end_time" value="Tiempo (horas) de desmontaje" />
        <r-e-input id="end_time" type="number" v-model="form.dismount_time" />
    </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="end_time" value="Días del evento" />
        <r-e-input id="end_time" type="number" v-model="form.event_days" @input="calculateTotals()" />
      </div>
    </div>
    <div class="flex flex-col mt-6">
      <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="background_color" value="Color del evento" />
        <Vue3ColorPicker format="rgb" shape="square" v-model:pureColor="form.background_color" />
      </div>
    </div>
    <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción del evento (interna)" />
        <textarea class="border border-gray-700 py-2 px-3 outline-none hover:border-secondary-500 focus:border-secondary-500 smooth" v-model="form.setup_instructions" id="setup_instructions"></textarea>
    </div>
    <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción del evento" />
        <textarea class="border border-gray-700 py-2 px-3 outline-none hover:border-secondary-500 focus:border-secondary-500 smooth" v-model="form.description" id="description"></textarea>
    </div>

    <div class="w-full mt-6 relative z-50">
    <r-e-input class="w-full" v-model="search" placeholder="Buscar producto" clear-text="true">
    </r-e-input>
    <div class="flex flex-col justify-between absolute top-12 left-0 right-0 bg-white border rounded-lg" v-closable="{ handler: 'closeSearch'}">
        <div class="p-3 flex justify-between items-center" v-for="product in filteredProducts" :key="product.id">
        <div>{{ product.name }}</div>

        <button class="primary-button" @click.prevent="addProduct(product)">
            Agregar
        </button>
        </div>
    </div>
    </div>

    <table class="table-auto mt-8 w-full text-sm md:text-base">
    <thead class="text-left">
        <tr class="border-b">
        <th class="p-2 border-r">ID</th>
        <th class="p-2 border-x">Nombre del producto</th>
        <th class="p-2 border-x">Precio</th>
        <th class="p-2 border-x">Color</th>
        <th class="p-2 border-x">Cantidad</th>
        <th class="p-2 border-x">Descripción</th>
        <th class="p-2 border-x">Subtotal</th>
        <th class="p-2 border-l">Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr
        class=""
        v-for="(product, index) in selectedProducts"
        :key="product.id"
        >
        <td class="p-2 border-r">{{ product.id }}</td>
        <td class="p-2 border-r">{{ product.name }}</td>
        <td class="p-2 border-r">{{ formatPrice(product.price) }}</td>
        <td class="p-2 border-r">{{ product.color }}</td>
        <td class="p-2 border-r flex items-center" v-if="!product.is_deleted">
            <button
            class="w-6 h-6 bg-gray-100 button-interactive rounded"
            @click.prevent="substractQuantity(product)"
            >
            -
            </button>
            <div class="mx-3"><input type="number" v-model="product.quantity" @input="calculateTotals()" /></div>
            <button
            class="w-6 h-6 bg-gray-100 button-interactive rounded"
            @click.prevent="addQuantity(product)"
            >
            +
            </button>
        </td>
        <td class="p-2 border-r" v-else></td>
        <td class="p-2 border-r">{{ product.description }}</td>
        <td class="p-2 border-r">{{ formatPrice(product.price * product.quantity) }}</td>
        <td class="p-2 border-l">
            <div class="flex">
            <r-e-delete-button @buttonClicked="removeProduct(product)" />
            </div>
        </td>
        </tr>
    </tbody>
    </table>

    <div v-if="form.event_days > 1" class="">
      <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="additional_day_discount" value="Descuento día subsecuente" />
        <r-e-input id="additional_day_discount" type="number" v-model="form.additional_day_discount" @input="calculateTotals()" />
      </div>
      <table class="table-auto mt-8 justify-end text-sm md:text-base">
      <tbody>
          <tr class="border-b">
          <td class="p-2 border-r font-bold">Subtotal primer dia</td>
          <td class="p-2">{{ formatPrice(this.form.first_day_price) }}</td>
          </tr>
          <tr class="border-b">
          <td class="p-2 border-r font-bold">Precio con descuento dias subsecuentes</td>
          <td class="p-2">{{ formatPrice(this.form.additional_day_price) }}</td>
          </tr>
          <tr class="border-b">
          <td class="p-2 border-r font-bold">Subtotal días subsecuentes</td>
          <td class="p-2">{{ formatPrice(this.form.additional_day_subtotal) }}</td>
          </tr>
      </tbody>
      </table>
    </div>

    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="cfdi_required" value="Requiere factura" />
        <input id="cfdi_required" type="checkbox" v-model="form.cfdi_required" :value="true" @change="calculateTotals()"/>
    </div>

    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="cfdi_required" value="Retención de impuestos" />
        <input id="tax_retention_required" type="checkbox" v-model="form.tax_retention_required" :value="true" @change="calculateTotals()"/>
    </div>

    <div class="flex flex-col w-72 mt-6 pr-4">
        <r-e-label for="is_repse" value="Es REPSE" />
        <input id="is_repse" type="checkbox" v-model="form.is_repse" :value="true"/>
    </div>

    <br> 

    <table class="table-auto mt-8 justify-end text-sm md:text-base">
    <tbody>
        <tr class="border-b">
        <td class="p-2 border-r font-bold">Envío</td>
        <td class="p-2">
          <div class="flex flex-col w-72 mt-6 pr-4">
            <r-e-input id="shipping_total" type="number" v-model="form.shipping_total" @input="calculateTotals()" />
          </div>
        </td>
        </tr>
        <tr class="border-b">
        <td class="p-2 border-r font-bold">Descuento</td>
        <td class="p-2">
          <div class="w-full flex flex-wrap">
          <div class="flex flex-col w-72 mt-6 pr-4" style="max-width: 150px;">
            <r-e-input id="discount_percentage" type="number" v-model="form.discount_percentage" @input="calculateTotals()" />
          </div>
          <div class="flex flex-col w-72 mt-6 pr-4">
          ({{ formatPrice(form.discount) }})
          </div>
          </div>
        </td>
        </tr>
        <tr class="border-b">
        <td class="p-2 border-r font-bold">Subtotal</td>
        <td class="p-2">{{ formatPrice(this.form.subtotal) }}</td>
        </tr>
        <tr class="border-b">
        <td class="p-2 border-r font-bold">IVA</td>
        <td class="p-2">{{ formatPrice(this.form.taxes) }}</td>
        </tr>
        <tr class="border-b" v-if="this.form.tax_retention_required">
        <td class="p-2 border-r font-bold">Retención de Impuestos</td>
        <td class="p-2">{{ formatPrice(this.form.tax_retention) }}</td>
        </tr>
        <tr class="border-b">
        <td class="p-2 border-r font-bold">Total</td>
        <td class="p-2">{{ formatPrice(this.form.total) }}</td>
        </tr>
    </tbody>
    </table>


    <r-e-primary-button class="w-72 mt-6" @buttonClicked="saveEvent">Guardar</r-e-primary-button>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import validationErrors from "../../../mixins/validationErrors";
import { integerToCurrency } from "../../../utils/currency";
import moment from 'moment';
import { mapState } from "vuex";

import CreateClient from './CreateClient.vue';
import ToggleInput from '../../../components/ToggleInput.vue';
import SelectInput from '../../../components/SelectInput.vue';
import preset_colors from "@/utils/colors";
import stringFilters from "@/mixins/stringFilters";

export default {
  name: "Formulario",

  components: {
    CreateClient,
    ToggleInput,
    SelectInput,
  },

  mixins: [validationErrors, stringFilters],

  data() {
    return {
      isEditing: false,
      form: {
        event_date: null,
        setup_date_start: null,
        dismount_finish_date: null,
        location_url: null,
        customer_id: 0,
        branch_id: 0,
        name: "",
        seller: "",
        phone: "",
        description: "",
        event_address: "",
        event_type: "",
        contact_method: "",
        installer: "",
        setup_instructions: "",
        customer: {},
        total: 0,
        subtotal: 0,
        taxes: 0,
        discount: 0,
        additional_day_discount: 30,
        additional_days: 0,
        additional_day_price: 0,
        additional_day_subtotal: 0,
        discount_percentage: 0,
        shipping_total: 0,
        first_day_price: 0,
        cfdi_required: false,
        create_new_customer: false,
        event_days: 1,
        background_color: 'rgba(255, 0, 0, 1)'
      },
      showAllInventory: false,
      products: [],
      selectedProducts: [],
      search: "",
      users: [],
      branches: [],
      customers: [],
      installers: [
        {
          id: 0,
          name: "TBD",
        },
        {
          id: 1,
          name: "Mario",
        },
        {
          id: 2,
          name: "Juan",
        },
        {
          id: 3,
          name: "Don Pepe",
        },
      ],
      event_types: [
        { id: 1, name: "Boda", },
        { id: 2, name: "Corporativo", },
        { id: 3, name: "XV", },
        { id: 4, name: "Baby Shower", },
        { id: 5, name: "Religioso", },
        { id: 6, name: "Posada", },
        { id: 7, name: "Gubernamental", },
        { id: 8, name: "Propuesta", },
        { id: 9, name: "Cumpleaños", },
        { id: 10, name: "Estudiantil", },
        { id: 11, name: "Despedida", },
        { id: 12, name: "Festival", },
        { id: 13, name: "Graduación", },
        { id: 14, name: "Gender reveal", },
        { id: 15, name: "Engagement party", },
        { id: 16, name: "Concierto", },
        { id: 17, name: "Aniversario", },
        { id: 18, name: "Expo", },
        { id: 18, name: "Otro", },
      ],
    };
  },

  computed: {
    ...mapState({
      user: "user",
    }),
    filteredProducts() {
      var filteredProducts = this.products;

      if (this.search.length > 0) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            this.stringExists(product.name, this.search)
            // product.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });

        return filteredProducts;
      } else {
        return [];
      }
    },
    getContactsOfClient() {
      if (!this.form.client.id) {
        return [];
      }

      return this.clients.filter((client) => {
        return client.id === this.form.client.id;
      })[0].contacts;
    },
  },

  created() {
    // set random color for event
    this.form.background_color = preset_colors[Math.floor(Math.random() * (preset_colors.length-1))];

    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadEvent(this.$route.params.id);
    }

    // if (this.$route.params.event) {
    //   this.isEditing = true;
    //   let event = JSON.parse(JSON.stringify(this.$route.params.event));
    //   this.form = event;
    // } else {
      
    // }

    this.loadClients();
    this.loadBranches();
    this.loadProducts();
    this.loadUsers();
  },

  methods: {
    async loadEvent(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getEvent(id)).data.data;
        this.form.leasing.products.forEach((item) => {
          let compound_product = item.compound_product;
          if (!compound_product) {
            compound_product = {
              id: item.compound_product_id,
              name: 'producto eliminado',
              price: item.price,
              color: 'producto eliminado',
              description: '',
              is_deleted: true,
            };
          }
          compound_product.quantity = item.quantity;
          this.selectedProducts.push(JSON.parse(JSON.stringify(compound_product)));
        })
        if (this.form.tax_retention) {
          this.form.tax_retention_required = true;
        }
      } catch (error) {
        console.log('error', error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadClients() {
      this.loading = true;

      try {
        this.customers = (await clientRequest.getClients()).data.data;
        if (this.$route.query.customer_id) {
          this.form.customer_id = this.$route.query.customer_id;
          if (!this.isEditing) {
            this.setDefaultDiscount();
          }
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    setDefaultDiscount() {
      let customer = this.customers.filter((customer) => customer.id == this.form.customer_id);
      this.form.discount_percentage = customer[0].default_discount || 0;
    },

    async loadBranches() {
      this.loading = true;

      try {
        this.branches = (await clientRequest.getBranches()).data.data;
        if (!this.isEditing) {
          this.form.branch_id = this.user.branch_id || 1;
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    // async next() {
    //   this.$router.push({ name: "events_add_final" });
    // },

    async loadUsers() {
      this.loading = true;

      let params = {
        filter_roles: ['seller', 'admin', 'procurement'],
      };

      try {
        this.users = (await clientRequest.getUsers(params)).data.data;
        if (!this.isEditing) {
          this.form.seller_id = this.user.id;
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadProducts() {
      this.loading = true;

      try {
        let productsResponse = (await clientRequest.getCompoundProducts()).data.data;
        productsResponse.forEach((product) => {
          product.quantity = parseInt(1);
        });
        this.products = productsResponse;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    substractQuantity(product) {
      if (product.quantity === 1) {
        this.removeProduct(product);
        return;
      }

      product.quantity = parseInt(product.quantity) - 1;
      this.calculateTotals();
    },

    addQuantity(product) {
      product.quantity = parseInt(product.quantity) + 1;
      this.calculateTotals();
    },

    addProduct(product) {
      this.closeSearch();
      let product_already_in_list = false;
      this.selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.id == product.id) {
          selectedProduct.quantity = parseInt(product.quantity) + 1;
          product_already_in_list = true;
        }
      });
      if (!product_already_in_list) {
        this.selectedProducts.push(product);
      }
      this.calculateTotals();
    },

    removeProduct(product) {
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
      this.calculateTotals();
    },

    closeSearch() {
      this.search = "";
    },

    toggleNewCustomer() {
      if (this.form.create_new_customer) {
        this.form.customer_id = null;
      }
    },

    // calculateDays() {
    //     if (this.form.setup_date_start && this.form.dismount_date_start) {
    //         let setup_date_start = moment(this.form.setup_date_start).startOf('day');
    //         let dismount_date_start = moment(this.form.dismount_date_start).startOf('day');

    //         let days_difference = dismount_date_start.diff(setup_date_start, 'days')
    //         this.form.event_days = Math.abs(days_difference);
    //     }

    //     this.calculateTotals();
    // },

    calculateTotals () {
      this.form.subtotal = 0;
      this.selectedProducts.forEach((product) => {
        this.form.subtotal += (product.price * product.quantity);
      });

      if(this.form.event_days > 1) {
        this.form.additional_days = this.form.event_days - 1;
        this.form.first_day_price = this.form.subtotal;
        this.form.additional_day_price = this.form.subtotal * (1 - (this.form.additional_day_discount / 100));
        this.form.additional_day_subtotal = this.form.additional_day_price * this.form.additional_days;

        let new_subtotal = this.form.first_day_price + this.form.additional_day_subtotal;
        this.form.subtotal = new_subtotal;
      } else {
        this.form.additional_day_discount = 0;
        this.form.additional_day_price = 0;
        this.form.additional_day_subtotal = 0;
        this.form.additional_days = 0;
      }

      if (this.form.shipping_total) {
        this.form.subtotal += parseFloat(this.form.shipping_total);
      }

      if (this.form.discount_percentage > 0) {
        this.form.discount = this.form.subtotal * (this.form.discount_percentage / 100);
      } else {
        this.form.discount = 0;
      }

      let real_subtotal =this.form.subtotal - this.form.discount;

      if (this.form.cfdi_required || this.form.tax_retention_required) {
        this.form.taxes = real_subtotal * 0.16;
      } else {
        this.form.taxes = 0;
      }

      let total = real_subtotal + this.form.taxes;

      // retencion de impuestos
      if (this.form.tax_retention_required) {
        this.form.cfdi_required = true;
        this.form.tax_retention = real_subtotal * 0.1067;
        total = total - this.form.tax_retention;
      } else {
        this.form.tax_retention = 0;
      }

      this.form.total = total;
    },

    isValid() {
      if (moment(this.form.setup_date_start).isAfter(this.form.dismount_date_start
      )) {
        this.$store.dispatch(
          "errorSnack",
          "La fecha montaje no puede ser despues del desmontaje"
        );
        return false;
      }

      return true;
    },

    async saveEvent() {
      if (!this.isValid()) {
        return;
      }
      this.loading = true;

      try {
          this.form.selectedProducts = this.selectedProducts;

          if (!this.isEditing) {
            let data = await clientRequest.storeEvent(this.form);
            this.$store.dispatch("successSnack", "Guardado exitosamente.");
            this.$router.push({ name: "events_dashboard", params: { id: data.data.data.id} });
          } else {
            let data = await clientRequest.updateEvent(this.form.id, this.form);
            this.$store.dispatch("successSnack", "Actualizado exitosamente.");
            this.$router.push({ name: "events_dashboard", params: { id: this.form.id} });
          }

          
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            console.log('error', error);
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    }
  },
};
</script>
