<template>
  <div class="h-screen w-full h-min-screen flex bg-gray-50 items-center">
    <div class="w-96 p-8 shadow-lg mx-auto bg-white">
      <div class="bg-black w-48 mx-auto">
        <img class="w-full" src="../../assets/relumbra_logo.png" alt="" />
      </div>

      <form action="">
        <div class="flex flex-col w-full mt-6">
          <r-e-label for="email" value="Correo electrónico" />
          <r-e-input id="email" type="text" v-model="form.email" />
          <v-errors :errors="errorFor('email')"></v-errors>
        </div>

        <div class="flex flex-col w-full mt-4">
          <r-e-label for="password" value="Contraseña" />
          <r-e-input id="password" type="password" v-model="form.password" />
          <v-errors :errors="errorFor('password')"></v-errors>
        </div>

        <r-e-primary-button
          class="w-full mt-6"
          :loading="loading"
          @buttonClicked="login"
          >Iniciar Sesión</r-e-primary-button
        >
      </form>
    </div>
  </div>
</template>

<script>
import clientRequest from "../../api/clientRequest";
import { logIn } from "../../utils/auth";
import { is422 } from "../../utils/response";
import validationErrors from "../../mixins/validationErrors";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  methods: {
    async login() {
      this.errors = {};
      
      this.loading = true;

      try {
        const data = (await clientRequest.login(this.form)).data;

        logIn(data.access_token);

        let user = data.user;
        user.role = user.roles[0].name;
        this.$store.commit("setUser", user);
        this.$store.commit("setLoggedIn", true);

        this.$router.push({
          name: "home",
        });

      } catch (error) {
        console.log(error)
        if (is422(error)) { // Agregar esta validación siempre que se envíe un formulario
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>