<template>
  <div class="flex flex-col absolute inset-0">
    <router-view></router-view>

    <transition name="snack">
      <snack v-if="snack" :snack="snack" />
    </transition>
  </div>
</template>

<script>
import Snack from "./components/Snack";
import { mapState } from "vuex";

export default {
  components: {
    Snack
  },
  created () {
    if (this.snack) {
      this.snack.type = null;
      this.snack.message = null;
    }
  },
  computed: {
    ...mapState({
      snack: "snack",
    }),
  },
}
</script>

<style >
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.snack-enter-active,
.snack-leave-active {
  transition: all 0.3s ease;
}
.snack-enter,
.snack-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>