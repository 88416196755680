<template>
    <div class="w-full flex flex-wrap">
        <div class="">
          <date-filters v-model="date_filters" @onLoad="loadReports()"></date-filters>

          <div class="w-full flex flex-wrap">
            <select-input v-model="filter_user_id" placeholder="Vendedor">
              <option value="">Todos</option>
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }}
              </option>
            </select-input>
          </div>

          <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadReports()">Filtrar</r-e-primary-button>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <div class="grid graph_container">
          <div class="font-bold text-lg">Cotizaciones y Contrataciones</div>
          <div class="">Cantidad de cotizaciones y contrataciones por mes (se toma la fecha de creacion de cotizacion) (este toma el primer pago de la contratación para saber)</div>
          <Bar
              :key="quotesAndAccepted"
              :options="chartOptions"
              :data="quotesAndAcceptedByDate"
          />
        </div>
        <div class="graph_container">
          <div class="font-bold text-lg">Ventas</div>
          <div class="">Cantidad de ventas mensual y anual (este toma todos los pagos ingresados de eventos por mes)</div>
          <Bar
              :options="chartOptions"
              :data="incomeByDate"
          />
        </div>
        <div class="graph_container">
          <div class="font-bold text-lg">Por Vendedor</div>
          <div class="">Cantidad de contrataciones por vendedor</div>
          <Pie
            :chart-options="chartOptions"
            :data="acceptedBySeller"
          />
        </div>
    </div>
</template>
  
<script>
import { integerToCurrency } from "@/utils/currency";
import clientRequest from "@/api/clientRequest";
import moment from "moment";
import { Bar, Pie } from "vue-chartjs";
import SelectInput from '@/components/SelectInput.vue';
import DateFilters from '@/components/DateFilters.vue';
import { setTransitionHooks } from "vue";

export default {
  name: "SaleChannel",

  components: {
      Bar,
      Pie,
      SelectInput,
      DateFilters
  },

  data() {
    return {
      quotesAndAccepted: true,
      report: {labels: ['a'], datasets:[ {data: [1]}]},
      quotesBydate: {labels: ['a'], datasets:[ {data: [1]}]},
      acceptedByDate: {labels: ['a'], datasets:[ {data: [1]}]},
      acceptedBySeller: {labels: ['a'], datasets:[ {data: [1]}]},
      incomeByDate: {labels: ['a'], datasets:[ {data: [1]}]},
      quotesAndAcceptedByDate: {labels: ['a'], datasets:[]},
      sales: {labels: ['a'], datasets:[ {data: [1]}]},
      sellers: {labels: ['a'], datasets:[ {data: [1]}]},
      chartOptions: {
          responsive: true,
          scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      filter_status_id: [],
      date_filters: {},
      filter_user_id: 0,
      users: [],
    };
  },

  created() {
  },

  methods: {
    async loadReports() {
      this.quotesAndAcceptedByDate.datasets = [];
      this.loadUsers();
      this.loadQuotes();
      this.loadAccepted();
      this.loadIncome();
      this.loadSellers();
    },
    async loadQuotes() {
      this.loading = true;

      let params = {
        start_date: this.date_filters.filter_start,
        end_date: this.date_filters.filter_end,
        status_ids: this.filter_status_id,
        user_id: this.filter_user_id
      };

      try {
        let response = (await clientRequest.ReportRequests.quotesByDate(params)).data.data;
        this.quotesAndAcceptedByDate.labels = response.labels;
        this.quotesAndAcceptedByDate.datasets.push(response.datasets[0]);
        this.quotesAndAccepted = !this.quotesAndAccepted;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },
    async loadAccepted() {
      this.loading = true;

      let params = {
        start_date: this.date_filters.filter_start,
        end_date: this.date_filters.filter_end,
        status_ids: this.filter_status_id,
        user_id: this.filter_user_id
      };

      try {
        let response = (await clientRequest.ReportRequests.acceptedByDate(params)).data.data;
        // this.quotesAndAcceptedByDate.labels = response.labels;
        this.quotesAndAcceptedByDate.datasets.push(response.datasets[0]);
        this.quotesAndAccepted = !this.quotesAndAccepted;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },


    async loadIncome() {
      this.loading = true;

      let params = {
        start_date: this.date_filters.filter_start,
        end_date: this.date_filters.filter_end,
        status_ids: this.filter_status_id,
        user_id: this.filter_user_id
      };

      try {
        this.incomeByDate = (await clientRequest.ReportRequests.incomeByMonth(params)).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    async loadSellers() {
      this.loading = true;

      let params = {
        start_date: this.date_filters.filter_start,
        end_date: this.date_filters.filter_end,
        status_ids: this.filter_status_id,
        user_id: this.filter_user_id
      };

      try {
        this.acceptedBySeller = (await clientRequest.ReportRequests.acceptedBySeller(params)).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    async loadUsers() {
      this.loading = true;

      let params = {
        filter_roles: ['seller', 'admin', 'procurement'],
      };

      try {
        this.users = (await clientRequest.getUsers(params)).data.data;
      } catch (error) {
        console.log('error', error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
    formatDate(date) {
      return moment(date).locale("es").format("LL");
    },
    formatBool(val) {
      return val ? "Si" : "No";
    },
  },
};
</script>
  
<style>
.bold-text {
  font-weight: bold;
}
</style>