<template>
  <div>
    <div
      class="text-sm text-error-500"
      v-for="(error, index) in errors"
      :key="key(index)"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["errors"],

  methods: {
    key(index) {
      return `validation_error_${index}_${Math.random()}`;
    },
  },
};
</script>