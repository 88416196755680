<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteUser"
      ></delete-modal>
    </transition>

    <div v-if="!loading">
      <div class="flex justify-between items-center">
        <router-link class="link-button" :to="{ name: 'roles' }"
          >Administrar Roles</router-link
        >

        <router-link class="primary-button" :to="{ name: 'user_add' }">
          <span class="text-xl mr-2">+</span>Agregar Usuario</router-link
        >
      </div>

      <!-- <r-e-input class="w-full mt-6" placeholder="Buscar un usuario por correo" /> -->

      <table class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Nombre completo</th>
            <th class="p-2 border-x">Correo electrónico</th>
            <th class="p-2 border-x">Rol</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="user in users" :key="user.id">
            <td class="p-2 border-r">{{ user.id }}</td>
            <td class="p-2 border-x">{{ user.name }} {{ user.last_name }}</td>
            <td class="p-2 border-x">{{ user.email }}</td>
            <td class="p-2 border-x">
              {{ user.roles[0] ? user.roles[0].display_name : "sin rol" }}
            </td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'user_edit',
                    params: {
                      id: user.id,
                      data: JSON.stringify(user)
                    },
                  }"
                />
                <r-e-delete-button @buttonClicked="openDelete(user)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import deleteMixin from "../../../mixins/deleteMixin";
import clientRequest from "../../../api/clientRequest";
export default {
  name: "Indice",

  data() {
    return {
      loading: false,
      users: null,
      selectedUser: {},
    };
  },

  mixins: [deleteMixin],

  created() {
    this.loadUsers();
  },

  methods: {
    openDelete(user) {
      this.selectedUser = user;
      this.openDeleteModal();
    },

    async deleteUser() {
      this.loading = true;

      try {
        await clientRequest.deleteUser(this.selectedUser.id);
        this.users.splice(this.users.indexOf(this.selectedUser), 1);
        this.selectedUser = {};
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.closeDeleteModal();
    },
    
    async loadUsers() {
      this.loading = true;

      try {
        this.users = (await clientRequest.getUsers()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>