<template>
  <div>
    <router-link class="" :to="{ 
                      name: 'events_dashboard', 
                      params: {
                        id: form.id
                      }
                    }">
      <back-icon class="w-6 fill-current" />
    </router-link>

    <form action="">
      <div class="w-full flex flex-wrap">
        <!-- <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="mounting_date" value="Fecha de montaje" />
          <r-e-input
            id="mounting_date"
            type="date"
            v-model="form.mounting_date"
          />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="event_date" value="Fecha de evento" />
          <r-e-input id="event_date" type="date" v-model="form.event_date" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="dismounting_date" value="Fecha de desmontaje" />
          <r-e-input
            id="dismounting_date"
            type="date"
            v-model="form.dismounting_date"
          />
        </div> -->

        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="mounting_date" value="Fecha de montaje" />
          <vue-date-picker
            name="mounting_date"
            v-model="form.mounting_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>

        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="event_date" value="Fecha de evento" />
          <vue-date-picker
            name="event_date"
            v-model="form.event_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>

        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="dismounting_date" value="Fecha de desmontaje" />
          <vue-date-picker
            name="dismounting_date"
            v-model="form.dismounting_date"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <div class="flex items-center">
          <input
            class="mr-2"
            type="radio"
            v-model="showAllInventory"
            :value="true"
          />Sólo mostrar inventario disponible en estas fechas
        </div>
        <div class="flex items-center mt-2">
          <input
            class="mr-2"
            type="radio"
            v-model="showAllInventory"
            :value="false"
          />Mostrar todo el inventario aunque no esté disponible
        </div>
      </div>

      <div class="w-full mt-6 relative z-50">
        <r-e-input
          class="w-full"
          v-model="search"
          placeholder="Buscar producto"
          clear-text="true"
        >
        </r-e-input>
        <div
          class="
            flex flex-col
            justify-between
            absolute
            top-12
            left-0
            right-0
            bg-white
            border
            rounded-lg
          "
          v-closable="{
            handler: 'closeSearch',
          }"
        >
          <div
            class="p-3 flex justify-between items-center"
            v-for="product in filteredProducts"
            :key="product.id"
          >
            <div>{{ product.name }}</div>

            <button
              class="primary-button"
              @click.prevent="addProduct(product)"
            >
              Agregar
            </button>
          </div>
        </div>
      </div>

      <table class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Nombre del producto</th>
            <th class="p-2 border-x">Precio</th>
            <th class="p-2 border-x">Color</th>
            <th class="p-2 border-x">Cantidad</th>
            <th class="p-2 border-x">Descripción</th>
            <th class="p-2 border-x">Subtotal</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class=""
            v-for="(product, index) in selectedProducts"
            :key="product.id"
          >
            <td class="p-2 border-r">{{ product.id }}</td>
            <td class="p-2 border-r">{{ product.name }}</td>
            <td class="p-2 border-r">{{ formatPrice(product.price) }}</td>
            <td class="p-2 border-r">{{ product.color }}</td>
            <td class="p-2 border-r flex items-center">
              <button
                class="w-6 h-6 bg-gray-100 button-interactive rounded"
                @click.prevent="substractQuantity(product)"
              >
                -
              </button>
              <div class="mx-3"><input type="number" v-model="products.quantity"></div>
              <button
                class="w-6 h-6 bg-gray-100 button-interactive rounded"
                @click.prevent="addQuantity(product)"
              >
                +
              </button>
            </td>
            <td class="p-2 border-r">{{ product.description }}</td>
            <td class="p-2 border-r">{{ formatPrice(product.price * product.quantity) }}</td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-delete-button @buttonClicked="removeProduct(product)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table-auto mt-8 justify-end text-sm md:text-base">
        <tbody>
          <tr class="border-b">
            <td class="p-2 border-r font-bold">Subtotal</td>
            <td class="p-2">{{ formatPrice(this.subtotal) }}</td>
          </tr>
          <tr class="border-b">
            <td class="p-2 border-r font-bold">IVA</td>
            <td class="p-2">{{ formatPrice(this.iva) }}</td>
          </tr>
          <tr class="border-b">
            <td class="p-2 border-r font-bold">Descuento</td>
            <td class="p-2">-</td>
          </tr>
          <tr class="border-b">
            <td class="p-2 border-r font-bold">Envío</td>
            <td class="p-2">-</td>
          </tr>
          <tr class="border-b">
            <td class="p-2 border-r font-bold">Total</td>
            <td class="p-2">{{ formatPrice(this.total) }}</td>
          </tr>
        </tbody>
      </table>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import BackIcon from "../../../icons/BackIcon";
import { integerToCurrency } from "../../../utils/currency";
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";

export default {
  name: "EditProducts",

  components: {
    BackIcon,
  },

  data() {
    return {
      form: {
        id: null,
        mounting_date: null,
        event_date: null,
        dismounting_date: null,
        requires_shipment: false,
        shipment_cost: null,
        requires_invoice: false,
        has_discount: false,
        discount_amount: null,
      },
      total: 0,
      subtotal: 0,
      iva: 0,
      discount: 0,
      timePickerOptions: {
        start: "00:00",
        step: "01:00",
        end: "23:00",
        format: "HH:mm",
      },
      showAllInventory: false,
      products: [],
      selectedProducts: [],
      search: "",
    };
  },

  computed: {
    filteredProducts() {
      var filteredProducts = this.products;

      if (this.search.length > 0) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            product.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });

        return filteredProducts;
      } else {
        return [];
      }
    },
  },

  created() {
    if (this.$route.params.event) {
      this.form = JSON.parse(JSON.stringify(this.$route.params.event));
      this.form.products.forEach((item) => {
        item.quantity = item.pivot.quantity;
        this.selectedProducts.push(JSON.parse(JSON.stringify(item)));
      })
    }
    this.loadProducts();
  },

  methods: {
    async loadProducts() {
      this.loading = true;

      try {
        let productsResponse = (await clientRequest.getCompoundProducts()).data.data;
        productsResponse.forEach((product) => {
          product.quantity = 1;
        });
        this.products = productsResponse;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    substractQuantity(product) {
      if (product.quantity === 1) {
        this.removeProduct(product);
        return;
      }

      product.quantity -= 1;
      this.calculateTotals();
    },

    addQuantity(product) {
      product.quantity += 1;
      this.calculateTotals();
    },

    addProduct(product) {
      this.closeSearch();
      this.selectedProducts.push(product);
      this.calculateTotals();
    },

    removeProduct(product) {
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
      this.calculateTotals();
    },

    closeSearch() {
      this.search = "";
    },

    calculateTotals () {
      this.selectedProducts.forEach((product) => {
        this.subtotal += product.price;
      }); 

      this.iva = this.subtotal * 0.16;
      this.total = this.subtotal + this.iva;
    },

    save() {

    }
  },
};
</script>