<template>
  <router-link
    type="submit"
    class="fill-current bg-complement-500 text-white w-8 h-8 button-interactive p-1"
    :to="to"
    @click.prevent="buttonClicked"
  >
    <edit-icon />
  </router-link>
</template>

<script>
import EditIcon from "../icons/EditIcon";
export default {
  components: {
    EditIcon,
  },

  props: {
    to: Object
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>