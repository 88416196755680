<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre de la cuenta" />
          <r-e-input id="name" type="text" v-model="form.name" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="email" value="Correo electrónico" />
          <r-e-input id="email" type="text" v-model="form.email" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="phone" value="# celular" />
          <r-e-input id="phone " type="text" v-model="form.phone" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="contact_method" value="Forma de contacto" />
          <r-e-input id="contact_method" type="text" v-model="form.contact_method" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="job_title" value="Puesto" />
          <r-e-input id="job_title" type="text" v-model="form.job_title" />
        </div>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        email: "",
        phone: "",
        contact_form: "",
        job_title: "",
      },
    };
  },

  created() {
    if (this.$route.params.contact) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.contact));
    }
  },

  methods: {
    async save() {
      this.$router.push({ name: "contacts" });
    },
  },
};
</script>