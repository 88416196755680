<template>
  <div>
    <!-- <date-filter />

    <charts class="mt-12" /> -->

    <next-events class="mt-12" />
  </div>
</template>

<script>
import Charts from "./components/Charts";
import NextEvents from "./components/NextEvents";
export default {
  components: {
    // Charts,
    NextEvents,
  },

  data() {
    return {
      
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "home");
  },
};
</script>