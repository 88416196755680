<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteEvent"
      ></delete-modal>
    </transition>

    <div>
      <div class="flex justify-between items-center">
        <router-link class="primary-button" :to="{ name: 'quotes_quote' }">
          <span class="text-xl mr-2">+</span>Crear nueva cotización</router-link
        >
      </div>

      <div class="w-full flex flex-wrap">
        <select-input v-model="filterStatuses">
          <option value="">Todos</option>
          <option :value="['accepted', 'mounted', 'unmounted']">Contratados</option>
          <option :value="['accepted', 'mounted']">Aceptados/Montados</option>
          <option :value="['unmounted']">Desmontados/Finalizados</option>
          <option :value="['cancelled', 'rejected']">Cancelados/Rechazados</option>
        </select-input>
      </div>
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="filter_start" value="inicio" />
          <vue-date-picker
            name="filter_start"
            v-model="filter_start"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>
        <div class="flex flex-col mt-6 pr-4">
          <r-e-label for="filter_end" value="fin" />
          <vue-date-picker
            name="filter_end"
            v-model="filter_end"
            model-type="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            locale="es"
            input-class-name="w-full">
          </vue-date-picker>
        </div>

        <select-input v-model="filter_month" @change="setMonthFilter()" placeholder="Mes">
          <option value="">Filtrar Mes</option>
          <option value="01">Enero</option>
          <option value="02">Febrero</option>
          <option value="03">Marzo</option>
          <option value="04">Abril</option>
          <option value="05">Mayo</option>
          <option value="06">Junio</option>
          <option value="07">Julio</option>
          <option value="08">Agosto</option>
          <option value="09">Septiembre</option>
          <option value="10">Octubre</option>
          <option value="11">Noviembre</option>
          <option value="12">Diciembre</option>
          <option value="13">Todo el año</option>
        </select-input>

        <select-input v-model="filter_year" @change="setMonthFilter()" placeholder="Año">
          <option value="">Filtrar año</option>
          <option v-for="year in available_years" :key="year" :value="year">{{ year }}</option>
        </select-input>
      </div>

      <r-e-input class="w-full mt-6" placeholder="Buscar por nombre, cliente, monto " v-model="search" clear-text="true"/>

      <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadEvents()">Filtrar</r-e-primary-button>

      <table v-if="!loading" class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Fecha Evento</th>
            <th class="p-2 border-x">
              Nombre del cliente<br>
              <span style="font-size: 12px;">Vendedor</span>
            </th>
            <th class="p-2 border-x">Tipo del evento</th>
            <th class="p-2 border-x">Cat. de productos</th>
            <th class="p-2 border-x">Nombre evento<br>
              <span style="font-size: 12px;">Lugar</span>
            </th>
            <th class="p-2 border-x">Monto</th>
            <th class="p-2 border-x">Restante por pagar</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ 
            'event-row': true ,
            'yellow-background': event.status.name == 'mounted',
            'gray-background': event.status.name == 'unmounted',
            'negativeDifference': calculateDifference(event) >= 100,
            'cancelled-background': event.status.name == 'rejected' || event.status.name == 'cancelled',
            }" v-for="event in events" :key="event.id">
            <td class="p-2 border-r">{{ event.id }}</td>
            <td class="p-2 border-r">{{ moment(event.event_date).format('DD MMM YYYY') }}</td>
            <td class="p-2 border-r">
              {{ event.customer ? event.customer.full_name : null }}<br>
              <span style="font-size: 12px;" v-if="event.seller">{{ event.seller.name }}</span>
            </td>
            <td class="p-2 border-r">{{ event.event_type }}</td>
            <td class="p-2 border-r">
              <ul>
                <li v-for="category in event.categories_grouped" :key="category.id">{{ category.name }}</li>
              </ul>
            </td>
            <td class="p-2 border-r">{{ event.name }}<br>
              <span style="font-size: 12px;" v-if="event.event_address">{{ event.event_address }}</span>
            </td>
            <td class="p-2 border-r">{{ formatPrice(event.total) }}</td>
            <td class="p-2 border-r">{{ formatPrice(calculateDifference(event)) }}</td>
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-detail-button
                  class="mr-2"
                  :to="{
                    name: 'events_dashboard',
                    params: {
                      id: event.id,
                    },
                  }"
                />

                <r-e-delete-button v-if="user.role == 'admin'" @buttonClicked="openDelete(event)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex w-full h-full items-center">
        <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
      </div>
    </div>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import deleteMixin from "../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../utils/currency";
import moment from 'moment';
import SelectInput from '../../../components/SelectInput.vue';
import activeFiltersMixin from "@/mixins/activeFilters";
import { is422 } from "@/utils/response";

export default {
  components: { SelectInput },
  name: "Indice",

  data() {
    return {
      user: {},
      loading: false,
      events: [],
      moment: null,
      search: '',
      filterStatuses: 'non-finished',
    };
  },

  mixins: [deleteMixin, activeFiltersMixin],

  created() {
    this.user = this.$store.state.user;
    this.getFilters();
    this.loadEvents();
    this.loadClients();
    this.moment = moment;
  },

  computed: {
    // filteredEvents() {
    //   var filteredEvents = this.events;

    //   // this.setActiveFilters({status: this.filterStatuses, query_text: this.search});

    //   return filteredEvents;
    // },
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.filter_start = filters.start_date || moment().startOf('year').format('yyyy-MM-DD');
      this.filter_end = filters.end_date || moment().endOf('year').format('yyyy-MM-DD');
      this.search = filters.query_text || '';
      this.filterStatuses = filters.statuses || '';
      this.filter_month = filters.filter_month || 13;
      if(filters.filter_year) this.filter_year = filters.filter_year;
    },
    async loadEvents() {
      this.loading = true;
      // this.filterStatuses = 'non-finished';

      try {
        let params = {
          start_date: this.filter_start,
          end_date: this.filter_end,
          query_text: this.search,
          statuses: this.filterStatuses,
          filter_month: this.filter_month,
          filter_year: this.filter_year,
        };
        this.setActiveFilters(params);

        this.events = (await clientRequest.getEvents({status: 'after_quote', query_params: params})).data.data;
      } catch (error) {
        console.log(error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    
    async loadClients() {
      this.loading = true;

      try {
        this.clients = (await clientRequest.getClients()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    calculateTotalPayments(event) {
      return event.payments.reduce((total, payment) => total + payment.amount, 0);
    },
    calculateDifference(event) {
      return event.total - this.calculateTotalPayments(event);
    },

    openDelete(event) {
      this.selectedEvent = event;
      this.openDeleteModal();
    },

    deleteQuote() {
      this.selectedEvent = {};
      this.closeDeleteModal();
    },

    async deleteEvent() {
      this.loading = true;

      try {
          let data = await clientRequest.deleteEvent(this.selectedEvent.id);

          this.$store.dispatch("successSnack", "Evento eliminado");
          this.events.splice(this.events.indexOf(this.selectedEvent), 1);
        } catch (error) {
          console.log(error);
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
      this.loading = false;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>

<style scoped>
.yellow-background {
  background-color: #f4fd68;
}

.negativeDifference {
  background-color: #00ffff !important;
  color: black;
}

.pink-background {
  background-color: #ff577f !important;
}

.cancelled-background {
  background-color: #5d5d5d !important;
  color: #fff !important;
}

.gray-background {
  background-color: #f1f1f1;
}

.event-row {
  border: 1px solid #484848;
}
</style>
