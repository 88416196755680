<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="amount" value="Monto" />
          <r-e-input id="amount" type="number" min="1" step="any" v-model="form.amount" />
        </div>

        <select-input v-model="form.origin_bank_account_id" placeholder="Cuenta origen">
          <option v-for="bank_account in bank_accounts" :key="bank_account.id" :value="bank_account.id">
            {{ bank_account.name }}
          </option>
        </select-input>
        <select-input v-model="form.destination_bank_account_id" placeholder="Cuenta destino">
          <option v-for="bank_account in bank_accounts" :key="bank_account.id" :value="bank_account.id">
            {{ bank_account.name }}
          </option>
        </select-input>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="date" value="Fecha de transferencia" />
          <r-e-input id="date" type="date" v-model="form.transfered_at" />
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="comments" value="Comentarios" />
        <textarea
          class="border border-gray-700 py-2 px-3 outline-none hover:border-secondary-500 focus:border-secondary-500 smooth"
          v-model="form.comments" id="comments"></textarea>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save">Guardar</r-e-primary-button>
    </form>
  </div>
</template>
  
<script>
import clientRequest from "../../../../api/clientRequest";
import { is422 } from "../../../../utils/response";
import SelectInput from '../../../../components/SelectInput.vue';

export default {
  name: "Formulario",

  components: {
    SelectInput
  },

  data() {
    return {
      isEditing: false,
      form: {
        amount: 0,
        origin_bank_account_id: 0,
        destination_bank_account_id: 0,
        comments: "",
      },
      bank_accounts: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadTransfer(this.$route.params.id);
    }

    this.loadBankAccounts();
  },

  methods: {
    async loadTransfer(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getBankAccountTransfer(id)).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadBankAccounts() {
      this.loading = true;

      try {
        this.bank_accounts = (await clientRequest.getBankAccounts()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async save() {
      this.loading = true;

      try {
        if (!this.isEditing) {
          await clientRequest.storeBankAccountTransfer(this.form);
        } else {
          await clientRequest.updateBankAccountTransfer(this.form.id, this.form);
        }

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "bank_account_transfers" });
      } catch (error) {
        console.log('error', error);
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }
    },
  },
};
</script>
