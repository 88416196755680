<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteProduct"
      ></delete-modal>
    </transition>

    <div v-if="showDetailsModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
      <div class="form relative">
          <div>
            <button class="link-button mt-4 float-right" style="width: fit-content;" @click.prevent="showDetailsModal = false">
              Cerrar
            </button>
            <table class="details-table">
              <tbody>
                <tr>
                  <td class="p-2 border-r">Nombre</td>
                  <td class="p-2 border-r">{{ selectedProduct.name }}</td>
                </tr>
                <tr>
                  <td class="p-2 border-r">SKU</td>
                  <td class="p-2 border-r">{{ selectedProduct.sku }}</td>
                </tr>
                <tr>
                  <td class="p-2 border-r">Costo</td>
                  <td class="p-2 border-r">{{ formatPrice(selectedProduct.cost) }}</td>
                </tr>
                <tr>
                  <td class="p-2 border-r">Color</td>
                  <td class="p-2 border-r">{{ selectedProduct.color }}</td>
                </tr>
                <tr>
                  <td class="p-2 border-r">Categoría</td>
                  <td class="p-2 border-r" v-if="selectedProduct.category">{{ selectedProduct.category.name }}</td>
                  <td class="p-2 border-r" v-else></td>
                </tr>
                <tr>
                  <td class="p-2 border-r">Descripción</td>
                  <td class="p-2 border-r">{{ selectedProduct.description }}</td>
                </tr>
                <tr>
                  <td class="p-2 border-r">Inventario</td>
                  <td class="p-2 border-r">
                    <ul>
                      <li v-for="inventory in selectedProduct.inventory" :key="inventory.id">{{ inventory.name }}: {{ inventory.pivot.quantity }}</li>
                    </ul>
                  </td>
                </tr>
            </tbody>
            </table>
          </div>
      </div>
    </div>

    <div v-if="!loading">
      <div class="flex justify-between items-center">
        <router-link class="primary-button" :to="{ name: 'items_add' }">
          <span class="text-xl mr-2">+</span>Crear nuevo item</router-link
        >
      </div>

      <r-e-input
        class="w-full mt-6"
        placeholder="Buscar"
        v-model="search"
        clear-text="true"
      />

      <div class="w-full flex flex-wrap">
        <select-input v-model="filterCategory" placeholder="Categoría">
          <option :value="null">Seleccionar categoría</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
        </select-input>
      </div>

      <table class="table-auto mt-8 w-full text-sm md:text-base">
        <thead class="text-left">
          <tr class="border-b">
            <th class="p-2 border-r">ID</th>
            <th class="p-2 border-x">Nombre</th>
            <th class="p-2 border-x">SKU</th>
            <th class="p-2 border-x">Categoria</th>
            <th class="p-2 border-x">Costo</th>
            <th class="p-2 border-l">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="product in filteredItems" :key="product.id">
            <td class="p-2 border-r">{{ product.id }}</td>
            <td class="p-2 border-r">{{ product.name }}</td>
            <td class="p-2 border-r">{{ product.sku }}</td>
            <td class="p-2 border-r">{{ product.category ? product.category.name : 'Sin categoria' }}</td>
            <td class="p-2 border-r">{{ formatPrice(product.cost) }}</td>
            <td class="p-2 border-r">
              <ul>
                <li v-for="inventory in product.inventory" :key="inventory.id">{{ inventory.name }}: {{ inventory.pivot.quantity }}</li>
              </ul>
            </td>
            <!-- <td class="p-2 border-r">{{ formatPrice(product.price) }}</td> -->
            <td class="p-2 border-l">
              <div class="flex">
                <r-e-edit-button
                  class="mr-2"
                  :to="{
                    name: 'items_edit',
                    params: {
                      id: product.id,
                      product: product,
                    },
                  }"
                />

                <r-e-detail-button class="mr-2" @buttonClicked="openDetails(product)"/>

                <r-e-delete-button @buttonClicked="openDelete(product)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-8 w-8 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import deleteMixin from "../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../utils/currency";
import activeFiltersMixin from "@/mixins/activeFilters";
import stringFilters from "@/mixins/stringFilters";
import SelectInput from '../../../components/SelectInput.vue';

export default {
  components: { SelectInput },
  name: "Indice",

  data() {
    return {
      loading: false,
      products: null,
      search: '',
      filteredProducts: [],
      selectedProduct: {},
      categories: [],
      filterCategory: null,
      showDetailsModal: false,
    };
  },

  mixins: [deleteMixin, activeFiltersMixin, stringFilters],

  created() {
    this.getFilters();
    this.loadCategories();
    this.loadProducts();
  },

  computed: {
    filteredItems() {
      var filteredItems = this.products;
      this.setActiveFilters({query_text: this.search});

      if (this.filterCategory) {
        filteredItems = filteredItems.filter((product) => {
          return (
            product.category_id == this.filterCategory
          );
        });
      }

      if (this.search.length > 0) {
        filteredItems = filteredItems.filter((product) => {
          return (
            this.stringExists(product.name, this.search)
            // product.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').indexOf(this.search.toLowerCase()) > -1
          );
        });
      }

      return filteredItems;
    },
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.search = filters.query_text || '';
    },
    openDetails(product) {
      this.selectedProduct = product;
      this.showDetailsModal = true;
    },
    openDelete(product) {
      this.selectedProduct = product;
      this.openDeleteModal();
    },

    async deleteProduct() {
      this.loading = true;

      try {
        await clientRequest.deleteProduct(this.selectedProduct.id);
        this.products.splice(this.products.indexOf(this.selectedProduct), 1);
        this.selectedProduct = {};
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.closeDeleteModal();
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    async loadProducts() {
      this.loading = true;

      try {
        this.products = (await clientRequest.getProducts()).data.data;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadCategories() {
      this.loading = true;

      try {
        this.categories = (await clientRequest.getCategories('Hagane\\Ichizen\\Models\\Product')).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>