<template>
  <div class="flex flex-col relative h-full">
    <header class="bg-black shadow">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center">
          <div class="text-2xl font-semibold text-gray-900">
            <router-link class="w-20" :to="{ name: 'home' }">
              <img class="w-full" style="max-width: 150px;" src="../../assets/relumbra_logo.png" alt="" />
            </router-link>
          </div>
          <div class="hidden md:flex space-x-8">
            <button class="button-interactive" @click="logout">
              Cerrar Sesión
            </button>
          </div>
          <div class="-mr-2 flex md:hidden">
            <button
              @click="toggleMenu"
              class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900 focus:outline-none">
              Menu
            </button>
          </div>
        </div>
      </div>
    </header>
    <div v-show="isMenuOpen" class="bg-white shadow-md md:hidden absolute top-20 left-0 right-0 z-10">
      <nav class="px-2 pt-2 pb-4 space-y-1">
        <div v-for="tab in tabs" :key="tab.id" @click="toggleMenu" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900">
          <router-link
            class="my-3 text-lg"
            :class="[
              { 'font-bold text-secondary-500': selectedTab === tab.tab },
              {
                'button-interactive': selectedTab !== tab.tab,
              },
            ]"
            :to="{ name: tab.route }"
            >{{ tab.text }}
          </router-link>
        </div>
      </nav>
    </div>

    <div class="flex w-full h-full">
      <div class="flex flex-col w-56 px-8 bg-gray-50 hidden md:flex">
        <router-link
          v-for="tab in tabs"
          :key="tab.id"
          class="my-3 text-lg relative"
          :class="[
            { 'font-bold text-secondary-500': selectedTab === tab.tab },
            {
              'button-interactive': selectedTab !== tab.tab,
            },
          ]"
          :to="{ name: tab.route }"
          >{{ tab.text }}
          <div v-if="tab.tab == 'inventory_conflicts'">
            <span v-if="inventory_conflicts_count > 0"
              style="position: absolute; right: 0; top: 15px; margin-right: -15px;"
              class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {{ inventory_conflicts_count }}
            </span>
          </div>
          <div v-if="tab.tab == 'finances'">
            <span v-if="expiredPaymentsCount > 0"
              style="position: absolute; right: 0; top: 3px; margin-right: -15px;"
              class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {{ expiredPaymentsCount }}
            </span>
          </div>
        </router-link>
      </div>

      <router-view class="bg-white w-full pt-3 pb-8 px-6 relative"></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { routeNameHasRole, routeNameHasEmail } from "../../router";
import clientRequest from "@/api/clientRequest";

export default {
  name: "Dashboard",

  components: {},

  data() {
    return {
      tabs: [],
      isMenuOpen: false,
      inventory_conflicts_count: 0,
      refreshInventoryConflicts: null,
      refreshExpiredPaymentsCount: null,
    };
  },

  computed: {
    ...mapState({
      user: "user",
      selectedTab: "selectedTab",
      expiredPaymentsCount: "expiredPaymentsCount",
    }),

    userIsLoaded() {
      return true;
      // return Object.entries(this.user).length > 0;
    },
  },

  created() {
    if (this.userIsLoaded) {
      this.loadTabs();
      this.loadInventoryConflicts();
      this.loadExpiredPaymentsCount();

      document.addEventListener('refreshInventoryConflicts', () => {
        this.loadInventoryConflicts();
      });
      document.addEventListener('refreshExpiredPaymentsCount', () => {
        this.loadExpiredPaymentsCount();
      });

      this.refreshInventoryConflicts = setInterval(() => {
        this.loadInventoryConflicts();
      }, 1000 * 60 * 5);

      this.refreshExpiredPaymentsCount = setInterval(() => {
        this.loadExpiredPaymentsCount();
      }, 1000 * 60 * 60);
    }
  },

  beforeUnmount () {
    clearInterval(this.refreshInventoryConflicts);
    clearInterval(this.refreshExpiredPaymentsCount);
  },

  methods: {
    toggleMenu() {
      console.log('toggle')
      this.isMenuOpen = !this.isMenuOpen;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },

    async loadInventoryConflicts() {
      try {
        let response = await clientRequest.getInventoryConflictsSummary();
        this.inventory_conflicts_count = response.data.data.inventory_conflicts_count;
      } catch (error) {
        console.log(error);
      }
    },

    async loadExpiredPaymentsCount() {
      try {
        let response = await clientRequest.expiredPaymentsCount();
        this.$store.commit('setExpiredPaymentsCount', response.data.data.count);
      } catch (error) {
        console.log(error);
      }
    },

    loadTabs() {
      this.tabs = [];

      if (routeNameHasRole('home', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Inicio",
          tab: "home",
          route: "home",
        });
      }

      if (routeNameHasEmail('users', this.user.email)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Usuarios",
          tab: "users",
          route: "users",
        });
      }

      if (routeNameHasRole('finances', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Finanzas",
          tab: "finances",
          route: "finances",
        });
      }

      if (routeNameHasRole('reports', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Reportes",
          tab: "reports",
          route: "reports",
        });
      }
      
      if (routeNameHasRole('clients', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Clientes",
          tab: "clients",
          route: "clients",
        });
      }
      
      if (routeNameHasRole('agenda', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Horarios",
          tab: "agenda",
          route: "agenda",
        });
      }
      
      if (routeNameHasRole('items', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Inventario",
          tab: "items",
          route: "items",
        });
      }
      
      if (routeNameHasRole('compound_products', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Productos",
          tab: "compound_products",
          route: "compound_products",
        });
      }
      
      if (routeNameHasRole('quotes', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Cotizaciones",
          tab: "quotes",
          route: "quotes",
        });
      }
      
      if (routeNameHasRole('events', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Eventos",
          tab: "events",
          route: "events",
        });
      }

      if (routeNameHasRole('inventory_conflicts', this.user.role)) {
        this.tabs.push({
          id: this.tabs.length,
          text: "Conflictos \n Inventario",
          tab: "inventory_conflicts",
          route: "inventory_conflicts",
        });
      }

      if (this.$route.name === "dashboard") {
        this.$router.push({ name: this.tabs[0].route });
      }
    },
  },

  watch: {
    userIsLoaded: function (val) {
      if (val) {
        this.loadTabs();
      }
    },
  },
};
</script>