<template>
  <div class="flex justify-evenly">
    <div>
      <div class="font-bold text-lg">Por Cuenta</div>
      <Pie
        :chart-options="chartOptions"
        :chart-data="accounts"
        :width="300"
        :height="300"
      />
      <div class="font-bold text-lg mt-4">Total: $ 150,000.00</div>
    </div>
    <div>
      <div class="font-bold text-lg">Por Cliente</div>
      <Bar
        :chart-options="chartOptions"
        :chart-data="clients"
        :width="300"
        :height="300"
      />
      <div class="font-bold text-lg mt-4">Top Seller: Cliente Uno</div>
    </div>
  </div>
</template>

<script>
import { Bar, Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
);
export default {
  components: { 
    Bar, 
    Pie,
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
      },
      accounts: {
        labels: ["Cuenta Uno", "Cuenta Dos", "Cuenta Tres"],
        datasets: [
          {
            backgroundColor: "#5a54fc",
            data: [10, 40, 50],
          },
        ],
      },
      clients: {
        labels: ["Cliente Uno", "Cliente Dos", "Cliente Tres"],
        datasets: [
          {
            label: "$MXN",
            backgroundColor: "#fc54a3",
            data: [1000, 2023, 1233],
          },
        ],
      },
    };
  },
};
</script>
