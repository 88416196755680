import ReportsRouter from "@/views/dashboard/reports/ReportsRouter";
import ReportsIndex from "@/views/dashboard/reports/Index";
import SaleChannel from "@/views/dashboard/reports/SaleChannel.vue";
import SalesMonths from "@/views/dashboard/reports/SalesMonths.vue";
import EventsByType from "@/views/dashboard/reports/EventsByType.vue";
import EventsByClient from "@/views/dashboard/reports/EventsByClient.vue";
import IncomeByMonth from "@/views/dashboard/reports/IncomeByMonth.vue";
import SalesReports from "@/views/dashboard/reports/SalesReports.vue";

export const reports_routes = {
    path: "/reportes",
    component: ReportsRouter,
    name: "reports",
    meta: { requiresLogin: true, roles: ['admin'] },
    children: [
        {
            path: "canal-de-ventas",
            component: SaleChannel,
            name: "sale_channel",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
        {
            path: "reportes-de-ventas",
            component: SalesReports,
            name: "sales_reports",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
        {
            path: "ventas-por-mes",
            component: SalesMonths,
            name: "sales_months",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
        {
            path: "ingresos",
            component: IncomeByMonth,
            name: "income_by_month",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
        {
            path: "eventos-por-tipo",
            component: EventsByType,
            name: "events_by_type",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
        {
            path: "eventos-por-cliente",
            component: EventsByClient,
            name: "events_by_client",
            meta: { requiresLogin: true, roles: ['admin'] },
        },
    ]
}