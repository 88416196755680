<template>
    <div class="w-full">
      <div class="w-full flex flex-wrap items-center">
        <router-link
          class="title mr-2 my-6"
          v-for="(route, index) in breadcrumRoutes"
          :key="route.name"
          :to="{ name: route.name }"
          >/
          <span
            :class="[{ 'text-blue-500 underline': index + 1 !== breadcrumRoutes.length }]"
            >{{ route.components.default.name }}</span
          ></router-link
        >
      </div>
  
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: "Transfers",
  
    computed: {
      breadcrumRoutes() {
        var routes = [];
        this.$route.matched.forEach((route, index) => {
          if (index !== 0 && index !== 1) {
            routes.push(route);
          }
        });
        return routes;
      },
    },
  
    created() {
      this.$store.commit("setFinancesSelectedTab", "transfers");
    },
  };
  </script>