<template>
  <div class="flex justify-center">

    <label for="toggle" class="flex items-center cursor-pointer">
        <!-- toggle -->
        <div class="relative">
        <!-- input -->
        <input v-model="innerModelValue" @change="emitChange" id="toggle" type="checkbox" class="sr-only" />
        <!-- line -->
        <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner change-background"></div>
        <!-- dot -->
        <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
        </div>
        <!-- label -->
        <div class="ml-3 text-gray-700 font-medium select-none">
            <slot></slot>
        </div>
    </label>
    
  </div>
</template>

<script>
import LoaderIcon from "../icons/LoaderIcon";
export default {
  components: {
    // LoaderIcon,
  },

  props: {
    loading: Boolean,
    modelValue: null
  },

  data() {
    return {
      innerModelValue: null,
    }
  },

  created() {
    this.innerModelValue = this.modelValue;
  },

  watch: {
      'modelValue': function(value) {
          if (value !== this.innerModelValue) {
              this.innerModelValue = value;
          }
      }
  },

  methods: {
    emitChange() {
      this.$emit("update:modelValue", this.innerModelValue);
    },
  },
};
</script>

<style scoped>
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f852a0;
}

input:checked ~ .change-background {
  background-color: #f852a0;
}
</style>