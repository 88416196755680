<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="amount" value="Monto" />
          <r-e-input
            id="amount"
            type="number"
            min="1"
            step="any"
            v-model="form.amount"
          />
        </div>

        <select-input placeholder="Cuenta Bancaria" v-model="form.bank_account_id">
          <option v-for="bank_account in bank_accounts" :key="bank_account.id" :value="bank_account.id">{{ bank_account.name }}</option>
        </select-input>

        <select-input placeholder="Categoría" v-model="form.category">
          <option v-for="category in categories" :key="category.id" :value="category">{{ category.name }}</option>
        </select-input>

        <select-input v-if="form.category" placeholder="SubCategoría" v-model="form.subcategory_id">
          <option v-for="subcategory in form.category.subcategories" :key="subcategory.id" :value="subcategory.id">{{ subcategory.name }}</option>
        </select-input>
        <select-input v-else placeholder="SubCategoría">
          <option>Escoge una categoria</option>
        </select-input>
        

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="date" value="Fecha en que se efectuó el egreso" />
          <r-e-input id="date" type="date" v-model="form.payed_at" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Facturado" />
          <div class="flex items-center">
            <input
              class="mr-2"
              type="radio"
              v-model="form.taxable"
              :value="true"
            />Si
          </div>
          <div class="flex items-center mt-2">
            <input
              class="mr-2"
              type="radio"
              v-model="form.taxable"
              :value="false"
            />No
          </div>
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="destination" value="Destino" />
          <r-e-input
            id="destination"
            type="text"
            v-model="form.destination"
          />
        </div>
      </div>

      <div class="w-full flex flex-wrap">
        <div class="flex flex-row mt-6 pr-4">
          <toggle-input v-model="is_to_pay" @change="toggleIsToPay">Por pagar</toggle-input>
          <div v-if="is_to_pay" class="flex flex-col w-72 ml-5 pr-4">
            <r-e-label for="date" value="Pago programado para fecha" />
            <r-e-input id="date" type="date" v-model="form.will_pay_at" />
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <!-- <div v-if="form.invoice" class="mt-6">
        <span class="mr-2">Subir XML</span>

        <input
          class=""
          type="file"
          name="xml"
          id="xml"
          ref="xml"
          accept="text/xml"
        />
      </div>

      <div v-if="form.invoice" class="mt-6">
        <span class="mr-2">Subir PDF</span>

        <input
          class=""
          type="file"
          name="pdf"
          id="pdf"
          ref="pdf"
          accept="application/pdf"
        />
      </div> -->

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
  <div v-if="showDuplicatedModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
    <div class="form relative">
        <p>Pago posiblemente duplicado.</p>

        <p>Relación con el pago ID: {{ duplicatedPayment.id }} <br>
            <router-link
              class="mr-2 link-button"
              :to="{
                name: 'expenses_edit',
                params: {
                  id: duplicatedPayment.id,
                },
              }"
            >Ver Pago</router-link>
          </p>

        <r-e-primary-button
          class="w-full mt-4"
          @buttonClicked="saveOutcome(true)"
          >Guardar de todos modos
        </r-e-primary-button>

        <button class="link-button mt-4 mx-auto w-full" @click.prevent="showDuplicatedModal = false">
          Cancelar
        </button>
    </div>
  </div>
</template>

<script>
import { is422 } from '../../../..//utils/response';
import clientRequest from "../../../../api/clientRequest";
import SelectInput from '../../../../components/SelectInput.vue';
import ToggleInput from '../../../../components/ToggleInput.vue';

export default {
  components: {
    SelectInput,
    ToggleInput
  },
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {},
      bank_accounts: [],
      tags: [],
      categories: [],
      subcategories: [],
      is_to_pay: false,
      showDuplicatedModal: false,
      duplicatedPayment: null,
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadPayment(this.$route.params.id);
    }
    this.loadBankAccounts();
    this.loadCategories();
  },

  methods: {
    async loadPayment(id) {
      this.loading = true;

      try {
        this.form = (await clientRequest.getPayment(id)).data.data;
        this.invoice = this.cfdi_required;
        this.form.subcategory_id = this.form.subcategory.id;
        if (this.form.will_pay_at) {
          this.is_to_pay = true;
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    getImage(e) {
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.user_image = e.target.result;
      };
    },

    async loadBankAccounts() {
      this.loading = true;

      try {
        this.bank_accounts = (await clientRequest.getBankAccounts({type: 'both'})).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadCategories() {
      this.loading = true;

      try {
        this.categories = (await clientRequest.getPaymentCategories()).data.data;

      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    toggleIsToPay () {
      if(this.is_to_pay == false) {
        this.form.will_pay_at = null;
      }
    },

    isValid() {
      if(this.is_to_pay && !this.form.will_pay_at) {
        this.$store.dispatch(
          "errorSnack",
          "Se tiene que ingresar una fecha de pago programada"
        );
        return false;
      }

      if(!this.form.bank_account_id) {
        this.$store.dispatch(
          "errorSnack",
          "Se tiene que especificar una cuenta de egreso"
        );
        return false;
      }

      if(!this.form.category) {
        this.$store.dispatch(
          "errorSnack",
          "Se tiene que especificar una categoria"
        );
        return false;
      }


      return true;
    },

    save() {
      if (!this.isValid()) {
        return;
      }

      if (!this.isEditing) {
        this.saveOutcome();
      } else {
        this.updateOutcome();
      }
    },

    async saveOutcome(override) {
      this.loading = true;
      this.form.is_income = false;
      this.form.category_id = this.form.category.id;
      if (override) {
        this.form.override_check = true;
      }

      try {
          await clientRequest.storePayment(this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "expenses_index" });
        } catch (error) {
          if (error.response.data.duplicated) {
            this.showDuplicatedModal = true;
            this.duplicatedPayment = error.response.data.duplicated;
          }
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    },

    async updateOutcome() {
      this.loading = true;
      this.form.is_income = false;
      this.form.category_id = this.form.category.id;
      
      try {
          await clientRequest.updatePayment(this.form.id, this.form);

          this.$store.dispatch("successSnack", "Guardado exitosamente.");

          this.$router.push({ name: "expenses_index" });
        } catch (error) {
          if (is422(error)) {
            this.errors = error.response && error.response.data.errors;
          } else {
            this.$store.dispatch(
              "errorSnack",
              "Error, favor de contactar a soporte."
            );
          }
        }
    }
  },
};
</script>