<template>
  <div>
    <div class="w-full flex flex-wrap">
      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="full_name" value="Nombre del cliente" />
        <r-e-input id="full_name" type="text" v-model="form.full_name" />
        <v-errors :errors="errorFor('full_name')"></v-errors>
      </div>

      <select-input v-model="form.channel" placeholder="Forma de contacto">
        <option v-for="sale_channel in sale_channels" :key="sale_channel" :value="sale_channel">
          {{ sale_channel }}
        </option>
      </select-input>

      <div class="flex flex-col w-72 mt-6 mr-4" v-if="form.channel == 'Otro' || form.channel == 'Expo'">
        <r-e-label for="channel" value="Detalle de forma de contacto" />
        <r-e-input id="channel" type="text" v-model="form.channel_other" />
        <v-errors :errors="errorFor('channel')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="phone" value="Telefono del cliente" />
        <r-e-input id="phone" type="text" v-model="form.phone" />
        <v-errors :errors="errorFor('phone')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="email" value="Correo electrónico" />
        <r-e-input id="email" type="text" v-model="form.email" />
        <v-errors :errors="errorFor('email')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="referred_by" value="Referencia" />
        <r-e-input id="referred_by" type="text" v-model="form.referred_by" />
        <v-errors :errors="errorFor('referred_by')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="type" value="Tipo de cliente" />
        <r-e-input id="type" type="text" v-model="form.type" />
        <v-errors :errors="errorFor('type')"></v-errors>
      </div>
    </div>

    <div class="w-full flex flex-wrap">
      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="full_name" value="Nombre del planner" />
        <r-e-input id="full_name" type="text" v-model="form.planner_name" />
        <v-errors :errors="errorFor('full_name')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="phone" value="Telefono del planner" />
        <r-e-input id="phone" type="text" v-model="form.planner_phone" />
        <v-errors :errors="errorFor('phone')"></v-errors>
      </div>

      <div class="flex flex-col w-72 mt-6 mr-4">
        <r-e-label for="default_discount" value="Descuento Default" />
        <r-e-input id="default_discount" type="number" min="0" max="100" step="0.01" v-model="form.default_discount" />
        <v-errors :errors="errorFor('default_discount')"></v-errors>
      </div>
    </div>
  </div>
</template>

<script>
import validationErrors from "../../../mixins/validationErrors";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Formulario",

  props: [
    'modelValue'
  ],

  components: {SelectInput},

  data() {
    return {
      isEditing: false,
      form: {
        full_name: "",
        business_as: "",
        channel: "",
        rfc: "",
        phone: "",
        email: "",
        billing_email: "",
        referred_by: "",
        type: "",
        billing_address: "",
        billing_zipcode: "",
        client_image: "",
      },
      loading: false,
      sale_channels: [
        'Instagram',
        'Facebook',
        'Recomendación',
        'Pacto',
        'Google',
        'Página web',
        'Mail',
        'Expo',
        'Otro',
      ],
    };
  },

  mixins: [validationErrors],

  computed: {
    formStringyfied: function () {
      return JSON.stringify(this.form);
    }
  },
  emits: ['update:modelValue'],

  watch: {
    'formStringyfied': function () {
      this.$emit('update:modelValue', this.form);
    }
  },

  methods: {

  },
};
</script>