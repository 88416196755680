<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteIncome"
      ></delete-modal>
    </transition>

    <transition name="modal">
      <delete-modal
        v-if="showDeleteFileModal"
        @close="showDeleteFileModal = false"
        @confirmDelete="deleteFile"
      ></delete-modal>
    </transition>

    <div v-if="showDetailsModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
      <div class="form relative">
          <div>
            <button class="link-button mt-4 float-right" style="width: fit-content;" @click.prevent="showDetailsModal = false">
              Cerrar
            </button>
            <table class="details-table">
              <tbody>
              <tr>
                <td>Id del pago</td>
                <td>{{ selectedPayment.id }}</td>
              </tr>
              <tr>
                <td>Evento</td>
                <td>{{ selectedPayment.project.name }}</td>
              </tr>
              <tr v-if="selectedPayment.project">
                <td>Cliente</td>
                <td>{{ selectedPayment.project.customer.full_name }}</td>
              </tr>
              <tr>
                <td>Fecha de creación</td>
                <td>{{ formatDate(selectedPayment.created_at) }}</td>
              </tr>
              <tr>
                <td>Fecha de pago</td>
                <td>{{ formatDate(selectedPayment.payed_at) }}</td>
              </tr>
              <tr v-if="selectedPayment.project">
                <td>Fecha del evento</td>
                <td>{{ formatDate(selectedPayment.project.event_date) }}</td>
              </tr>
              <tr>
                <td>Cuenta</td>
                <td>{{ selectedPayment.bank_account.name }}</td>
              </tr>
              <tr>
                <td>Monto</td>
                <td>{{ formatPrice(selectedPayment.amount) }}</td>
              </tr>
              <tr>
                <td>Descripción</td>
                <td>{{ selectedPayment.description }}</td>
              </tr>
              <tr>
                <td>Ingresado por</td>
                <td>{{ selectedPayment.created_by.name }}</td>
              </tr>
            </tbody>
            </table>

            <div class="flex mt-3 justify-between items-center">
              <router-link 
                v-if="selectedPayment.project"
                class="primary-button"
                :to="{
                  name: 'events_dashboard',
                  params: {
                    id: selectedPayment.project.id,
                  },
                }"
              >Ir a evento</router-link>
            </div>

            <br><br>

            Archivos
            <ul class="file-list">
              <li v-for="file in selectedPayment.files" v-bind:key="file.id">
                <a :href="file.url" target="_blank">{{ file.name }}</a>
                &nbsp;<r-e-delete-button @buttonClicked="openDeleteFile(file)" />
              </li>
            </ul>

            <div>
              <input type="file" @change="handleFileUpload($event)">
              <r-e-primary-button
                class="mt-4"
                @buttonClicked="submitFile"
                >Subir archivo
              </r-e-primary-button>
            </div>
          </div>
      </div>
    </div>

    <div class="w-full flex flex-wrap">
      <div class="flex flex-col mt-6 pr-4">
        <date-filters v-model="date_filters" @onLoad="loadIncomes()"></date-filters>
      </div>
    </div>
    <r-e-input class="w-full mt-6" placeholder="Buscar por nombre, cliente, monto " v-model="search" clear-text="true"/>
    <div class="w-full flex flex-wrap">
      <select-input v-model="bank_account_is_cash" placeholder="Tipo de cuenta de ingreso">
        <option value="0">Todo</option>
        <option value="1">Efectivo</option>
        <option value="2">Cuenta bancaria</option>
        <option value="3">Reinversión</option>
      </select-input>
      <toggle-input class="float-left mt-6 ml-3" v-model="to_pay">Cuentas por cobrar</toggle-input>
    </div>
    <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadIncomes()">Filtrar</r-e-primary-button>

    <div class="flex justify-between items-center mt-12">
      <router-link
        class="primary-button w-72"
        :to="{ name: 'income_add' }"
      >
        <span class="text-xl mr-2">+</span>Registrar un nuevo
        Ingreso</router-link
      >

      <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
        Descargar Excel
      </button> -->
    </div>

    <div class="w-full flex flex-wrap">
      <div class="flex flex-col mt-6 pr-4">
        Monto Total: {{ formatPrice(totalSum) }}
      </div>
    </div>

    <loader-icon v-if="loading" class="h-8 w-8 mx-auto"></loader-icon>
    <table v-else class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x cursor-pointer" @click="orderColumn('amount')">*Monto</th>
          <th class="p-2 border-x">Cuenta de ingreso</th>
          <th class="p-2 border-x">Cliente</th>
          <th class="p-2 border-x">Fecha de pago</th>
          <th class="p-2 border-x">Fecha del evento</th>
          <th class="p-2 border-x">¿Facturado?</th>
          <th class="p-2 border-x">Ingresado Por</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="income in incomes" :key="income.id">
          <td class="p-2 border-r">{{ income.id }}</td>
          <td class="p-2 border-x">{{ formatPrice(income.amount) }}</td>
          <td class="p-2 border-x">{{ income.bank_account ? income.bank_account.name : '' }}</td>
          <td class="p-2 border-x">{{ income.project && income.project.customer ? income.project.customer.full_name : 'sin nombre' }}</td>
          <td class="p-2 border-x" v-if="income.payed_at">{{ formatDate( income.payed_at) }}</td>
          <td class="p-2 border-x" v-else></td>
          <td class="p-2 border-x" v-if="income.project">{{ formatDate( income.project.event_date) }}</td>
          <td class="p-2 border-x" v-else>Sin evento</td>
          <td class="p-2 border-x">{{ formatBool(income.taxable) }}</td>
          <td class="p-2 border-x" style="font-size: 12px;">{{ income.created_by ? income.created_by.name : '' }} <br> <span>{{ formatDate(income.created_at) }}</span></td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'income_edit',
                  params: {
                    id: income.id,
                  },
                }"
              />

              <r-e-detail-button class="mr-2" @buttonClicked="openDetails(income)"/>

              <r-e-delete-button @buttonClicked="openDelete(income)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import Charts from "./components/Charts";
import ToggleInput from "@/components/ToggleInput";
import deleteMixin from "@/mixins/deleteMixin";
import { integerToCurrency } from "@/utils/currency";
import clientRequest from "@/api/clientRequest";
import SelectInput from '@/components/SelectInput.vue';
import { isLoggedIn } from "@/utils/auth";
import activeFiltersMixin from "@/mixins/activeFilters";
import DateFilters from '@/components/DateFilters.vue';

export default {
  name: "Indice",

  components: {
    // Charts,
    ToggleInput,
    SelectInput,
    DateFilters,
  },

  data() {
    return {
      incomes: [],
      loading: false,
      search: '',
      selectedPayment: {},
      selectedFile: {},
      bank_account_is_cash: 0,
      to_pay: false,
      showDetailsModal: false,
      showDeleteFileModal: false,
      date_filters: {},
      orientationAsc: true,
    };
  },

  computed: {
    totalSum() {
      if(!this.incomes) return;
      if(this.incomes.length <= 0) return;
      return this.incomes.map((item) => item.amount).reduce((total, item) => total + parseFloat(item));
    }
  },
  mixins: [deleteMixin, activeFiltersMixin],
  created() {
    this.getFilters();
  },
  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
      this.date_filters.filter_start = filters.start_date || moment().startOf('month').format('yyyy-MM-DD');
      this.date_filters.filter_end = filters.end_date || moment().endOf('month').format('yyyy-MM-DD');
      this.search = filters.query_text || '';
      this.to_pay = filters.to_pay || false;
      this.bank_account_is_cash = filters.bank_account_is_cash || 0;
    },
    async loadIncomes() {
      this.loading = true;

      let params = {
        start_date: this.date_filters.filter_start,
        end_date: this.date_filters.filter_end,
        query_text: this.search,
        to_pay: this.to_pay,
        bank_account_is_cash: this.bank_account_is_cash,
      };
      this.setActiveFilters(params);

      try {
        this.incomes = (await clientRequest.getIncomes(params)).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },
    orderColumn(field) {
      this.orientationAsc = !this.orientationAsc;
      if (this.orientationAsc) {
        this.incomes.sort((a, b) => {
          if (a[field] < b[field]) return -1
          else return 1;
        });
      } else {
        this.incomes.sort((a, b) => {
          if (a[field] > b[field]) return -1
          else return 1;
        })
      }
    },

    openDetails(payment) {
      this.selectedPayment = payment;
      this.showDetailsModal = true;
    },

    openDelete(payment) {
      this.selectedPayment = payment;
      this.openDeleteModal();
    },

    async deleteIncome() {
      this.loading = true;

      try {
        await clientRequest.deletePayment(this.selectedPayment.id);
        this.incomes.splice(this.incomes.indexOf(this.selectedPayment), 1);
        this.selectedPayment = {};
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.closeDeleteModal();
    },

    formatDate(date) {
      return moment(date).locale("es").format("LL");
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    formatBool(val) {
      return val ? "Si" : "No";
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    async submitFile() {
      try{
        let response = await clientRequest.uploadPaymentFile(this.selectedPayment.id, this.file);
        this.selectedPayment.files = response.data.data.files;
      } catch (error) {
        console.log(error);
      }
    },

    openDeleteFile(file) {
      this.selectedFile = file;
      this.showDeleteFileModal = true;
    },

    async deleteFile() {
      this.loading = true;

      try {
        let response = await clientRequest.deletePaymentFile(this.selectedFile.id);
        this.selectedPayment.files = response.data.data.files;
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.showDeleteFileModal = false;
    },
  },
};
</script>

<style scoped>
table.details-table td {
  padding: 10px;
}
ul.file-list {
  list-style: disc;
  margin-left: 25px;
  margin-bottom: 15px;
}
ul.file-list li {
  padding: 5px;
}
</style>