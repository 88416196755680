<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="name" value="Nombre de la cuenta" />
          <r-e-input id="name" type="text" v-model="form.name" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="color" value="Color" />
          <r-e-input id="color" type="text" v-model="form.color" />
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="foreign_exchange" value="Divisas" />
          <r-e-input
            id="foreign_exchange"
            type="text"
            v-model="form.foreign_exchange"
          />
        </div>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        color: "",
        foreign_exchange: "",
      },
    };
  },

  created() {
    if (this.$route.params.account) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.account));
    }
  },

  methods: {
    async save() {
      this.$router.push({ name: "bank_accounts_index" });
    },
  },
};
</script>