<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteIncome"
      ></delete-modal>
    </transition>

    <div v-if="income">
      <div class="flex mt-3">
        <div class="font-bold">Fecha de registro de ingreso:</div>
        <div class="ml-2">2022-04-12</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Fecha de ingreso:</div>
        <div class="ml-2">{{ income.date }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Ingreso registrado por:</div>
        <div class="ml-2">{{ income.registrant }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Cuenta a la que se asigno el registro:</div>
        <div class="ml-2">{{ income.account.name }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Monto del ingreso:</div>
        <div class="ml-2">{{ formatPrice(income.amount) }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Viene del cliente:</div>
        <div class="ml-2">{{ income.client }}</div>
      </div>

      <div class="flex mt-3">
        <div class="font-bold">Descripción:</div>
        <div class="ml-2">{{ income.description }}</div>
      </div>

      <button class="button-interactive p-2 bg-complement-500 text-white mt-8 mr-4">
        Descargar XML
      </button>

      <button class="button-interactive p-2 bg-complement-500 text-white mt-8">
        Descargar PDF
      </button>

      <div class="flex mt-6">
        <r-e-edit-button
          class="mr-2"
          :to="{
            name: 'income_edit',
            params: {
              id: income.id,
              income: income,
            },
          }"
        />

        <r-e-delete-button @buttonClicked="openDeleteModal" />
      </div>
    </div>
  </div>
</template>

<script>
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
export default {
  name: "Detalle",

  data() {
    return {
      income: null,
    };
  },

  mixins: [deleteMixin],

  created() {
    if (this.$route.params.income) {
      this.income = JSON.parse(JSON.stringify(this.$route.params.income));
    }
  },

  methods: {
    formatPrice(price) {
      return integerToCurrency(price);
    },

    deleteIncome() {
      this.closeDeleteModal();
    },
  },
};
</script>