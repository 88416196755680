export function isLoggedIn() {
  return localStorage.getItem("isLoggedIn") == 'true';
}

export function logIn(data) {
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("token", data);
}

export function logOut() {
  localStorage.setItem("isLoggedIn", false);
  localStorage.removeItem("token");
}
