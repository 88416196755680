<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteSubcategory"
      ></delete-modal>
    </transition>

    <div class="flex justify-between items-center mt-12">
      <router-link
        class="primary-button"
        :to="{ name: 'subcategories_add' }"
      >
        <span class="text-xl mr-2">+</span>Registrar una nueva
        Subcategoría</router-link
      >

      <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
        Descargar Excel
      </button> -->
    </div>

    <r-e-input
        class="w-full mt-6"
        placeholder="Buscar"
        v-model="search"
        clear-text="true"
      />

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Categoría</th>
          <th class="p-2 border-x">Subcategoría</th>
          <th class="p-2 border-x">Descripción</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="subcategory in filteredSubcategories" :key="subcategory.id">
          <td class="p-2 border-r">{{ subcategory.id }}</td>
          <td class="p-2 border-x">{{ subcategory.category.name }}</td>
          <td class="p-2 border-x">{{ subcategory.name }}</td>
          <td class="p-2 border-x">{{ subcategory.description }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'subcategories_edit',
                  params: {
                    id: subcategory.id,
                    subcategory: subcategory,
                  },
                }"
              />

              <r-e-delete-button @buttonClicked="openDelete(subcategory)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import clientRequest from "../../../../api/clientRequest";
import deleteMixin from "../../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../../utils/currency";
export default {
  name: "Indice",

  data() {
    return {
      subcategories: [],
      loading: false,
      search: '',
      selectedSubcategory: {}
    };
  },

  mixins: [deleteMixin],

  created() {
    this.loadSubcategories();
  },

  computed: {
    filteredSubcategories() {
      var filteredSubcategories = this.subcategories;

      if (this.search.length > 0) {
        filteredSubcategories = filteredSubcategories.filter((subcategory) => {
          return (
            subcategory.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            || subcategory.category.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });

        return filteredSubcategories;
      } else {
        return this.subcategories;
      }
    },
  },

  methods: {
    async loadSubcategories() {
      this.loading = true;

      try {
        this.subcategories = (await clientRequest.getPaymentSubcategories()).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    openDelete(subcategory) {
      this.selectedSubcategory = subcategory;
      this.openDeleteModal();
    },

    async deleteSubcategory() {
      this.loading = true;

      try {
        await clientRequest.deletePaymentSubcategories(this.selectedSubcategory.id);
        this.subcategories.splice(this.subcategories.indexOf(this.selectedSubcategory), 1);
        this.selectedSubcategory = {};
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.closeDeleteModal();
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },

    formatBool(val) {
      return val ? "Si" : "No";
    },
  },
};
</script>