<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="warehouse" value="Ubicación" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.warehouse_id"
            >
              <option
                v-for="warehouse in warehouses"
                :key="warehouse.id"
                :value="warehouse.id"
              >
                {{ warehouse.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="product" value="Item" />
          <div class="relative">
            <select
              class="
                border border-gray-700
                py-2
                px-3
                outline-none
                focus:border-secondary-500
                smooth
                appearance-none
                w-full
              "
              id="type"
              v-model="form.product_id"
            >
              <option
                v-for="product in products"
                :key="product.id"
                :value="product.id"
              >
                {{ product.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="stock" value="Cantidad" />
          <r-e-input
            id="quantity"
            type="number"
            min="1"
            step="1"
            v-model="form.quantity"
          />
        </div>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";

export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        type: "",
        warehouse: {},
        product: {},
        quantity: 0,
      },
      warehouses: [],
      products: [],
    };
  },

  created() {
      if (this.$route.params.warehouse_product) {
        this.isEditing = true;
        this.form = JSON.parse(JSON.stringify(this.$route.params.warehouse_product));
        this.warehouses.push(this.form.warehouse);
        this.products.push(this.form.product);
        this.form.warehouse_id = this.form.warehouse.id;
        this.form.product_id = this.form.product.id;
      } else {
        this.loadProducts();
        this.loadWarehouses();
      }
  },

  methods: {
    async loadProducts() {
      this.loading = true;

      try {
        this.products = (await clientRequest.getProducts()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async loadWarehouses() {
      this.loading = true;

      try {
        this.warehouses = (await clientRequest.getWarehouses()).data.data;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
    async save() {
      if(this.isEditing) {
        this.update();
        return;
      }
      this.loading = true;

      try {
        await clientRequest.storeWarehouseProduct(this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "inventory_index" });
      } catch(error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          console.log('error', error);
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
    async update() {
      this.loading = true;

      try {
        await clientRequest.updateWarehouseProduct(this.form.id, this.form);
        this.$store.dispatch("successSnack", "Guardado exitosamente.");
        this.$router.push({ name: "inventory_index" });
      } catch(error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          console.log('error', error);
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }

      this.loading = false;
    },
  },
};
</script>