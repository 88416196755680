import moment from "moment";

export default {
    data() {
        return {
            filter_start: null,
            filter_end: null,
            filter_month: moment().format('MM'),
            filter_year: moment().format('yyyy'),
            available_years: this.getAvailableYearsArray(),
        }
    },

    methods: {
        getAllActiveFilters() {
            let active_filters = localStorage.getItem('active_filters');
            return JSON.parse(active_filters) || {};
        },
        getActiveFilters() {
            let url_path = this.$route.path;
            let active_filters = JSON.parse(localStorage.getItem('active_filters'));
            if (!active_filters) {
                return {};
            }
            return active_filters[url_path] || {};
        },
        setActiveFilters(params) {
            let url_path = this.$route.path;
            let active_filters = this.getAllActiveFilters();
            active_filters[url_path] = params;
            localStorage.setItem('active_filters', JSON.stringify(active_filters));
        },
        resetActiveFilters() {
            localStorage.removeItem('active_filters')
        },
        setMonthFilter(callback) {
            if (!this.filter_month) {
              this.filter_month = moment().format('MM');
            }
            if (this.filter_month == 13) {
                this.filter_start = moment(this.filter_year, 'yyyy').startOf('year').format('yyyy-MM-DD');
                this.filter_end = moment(this.filter_year, 'yyyy').endOf('year').format('yyyy-MM-DD');

                if (callback) {
                    callback();
                }
                return;
            }
            let filter = this.filter_month + '-' + this.filter_year;

            this.filter_start = moment(filter, 'MM-yyyy').startOf('month').format('yyyy-MM-DD');
            this.filter_end = moment(filter, 'MM-yyyy').endOf('month').format('yyyy-MM-DD');

            if (callback) {
                callback();
            }
        },
        getAvailableYearsArray() {
            let current_date = moment().startOf('year').add(5, 'year');
            let start_year = moment('2022-01-01');
            let available_years = [];

            while(start_year <= current_date) {
                available_years.push(start_year.format('yyyy'));
                start_year.add(1, 'year');
            }

            return available_years;
        },
    }
}