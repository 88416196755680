<template>
  <button
    type="submit"
    class="fill-current bg-error-500 text-white w-8 h-8 button-interactive p-1"
    @click.prevent="buttonClicked"
  >
    <delete-icon />
  </button>
</template>

<script>
import DeleteIcon from "../icons/DeleteIcon";
export default {
  components: {
    DeleteIcon,
  },

  props: {
    route: String
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>