<template>
  <div>
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteProduct"
      ></delete-modal>
    </transition>

    <div class="flex justify-between items-center">
      <!-- <router-link class="link-button" :to="{ name: 'locations' }"
        >Administrar Ubicaciones</router-link
      > -->

      <router-link class="primary-button" :to="{ name: 'inventory_add' }">
        <span class="text-xl mr-2">+</span>Crear nuevo inventario</router-link
      >
    </div>

    <!-- <r-e-input class="w-full mt-6" placeholder="Buscar producto por nombre o id" /> -->

    <table class="table-auto mt-8 w-full text-sm md:text-base">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r">ID</th>
          <th class="p-2 border-x">Item</th>
          <th class="p-2 border-x">Ubicación</th>
          <th class="p-2 border-x">Cantidad</th>
          <th class="p-2 border-l">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="warehouse_product in warehouse_products" :key="warehouse_product.id">
          <td class="p-2 border-r">{{ warehouse_product.id }}</td>
          <td class="p-2 border-r">{{ warehouse_product.product.name }}</td>
          <td class="p-2 border-r">{{ warehouse_product.warehouse.name }}</td>
          <td class="p-2 border-r">{{ warehouse_product.quantity }}</td>
          <td class="p-2 border-l">
            <div class="flex">
              <r-e-edit-button
                class="mr-2"
                :to="{
                  name: 'inventory_edit',
                  params: {
                    id: warehouse_product.id,
                    warehouse_product: warehouse_product,
                  },
                }"
              />
              <r-e-delete-button @buttonClicked="openDelete(product.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import clientRequest from "../../../api/clientRequest";
import { is422 } from "../../../utils/response";
import deleteMixin from "../../../mixins/deleteMixin";
import { integerToCurrency } from "../../../utils/currency";
export default {
  name: "Indice",

  data() {
    return {
      warehouse_products: [],
    };
  },

  mixins: [deleteMixin],


  created() {
    this.loadWarehouseProducts();
  },

  methods: {
    async loadWarehouseProducts() {
      this.loading = true;

      try {
        this.warehouse_products = (await clientRequest.getWarehouseProducts()).data.data;
      } catch (error) {
        console.log(error)
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    openDelete(id) {
      this.openDeleteModal();
    },

    deleteProduct() {
      this.closeDeleteModal();
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
  },
};
</script>