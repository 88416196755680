<template>
  <div>
    <LoadingModal v-if="loading"></LoadingModal>
    <div class="flex justify-between items-center">
      <!-- <date-filter /> -->

      <!-- <button class="button-interactive p-2 bg-complement-500 text-white">
        Descargar desglose de egresos
      </button> -->

      <select-input v-model="filter_year" @change="setMonthFilter()" placeholder="Año">
        <option value="">Filtrar año</option>
        <option v-for="year in available_years" :key="year" :value="year">{{ year }}</option>
      </select-input>
      <r-e-primary-button class="w-24 mt-6" @buttonClicked="loadFinances()">Filtrar</r-e-primary-button>
    </div>

    <!-- <div v-if="showIncomesModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
      <div class="form relative" style="width: 80%;">
          <div>
            <button class="link-button mt-4 float-right" style="width: 100%; clear:both; text-align: right;" @click.prevent="showIncomesModal = false">
              Cerrar
            </button>
            <table class="details-table">
              <tr>
                <th class="p-2 border-r">ID</th>
                <th class="p-2 border-x">Monto</th>
                <th class="p-2 border-x">Cuenta de ingreso</th>
                <th class="p-2 border-x">Cliente</th>
                <th class="p-2 border-x">Fecha de pago</th>
                <th class="p-2 border-x">¿Facturado?</th>
                <th class="p-2 border-x">Ingresado Por</th>
                <th class="p-2 border-l">Acciones</th>
              </tr>
              <tr v-for="income in selectedMonth.income_payments" :key="income.id">
                <td class="p-2 border-r">{{ income.id }}</td>
                <td class="p-2 border-x">{{ formatPrice(income.amount) }}</td>
                <td class="p-2 border-x">{{ income.bank_account ? income.bank_account.name : '' }}</td>
                <td class="p-2 border-x">{{ income.project && income.project.customer ? income.project.customer.full_name : 'sin nombre' }}</td>
                <td class="p-2 border-x" v-if="income.payed_at">{{ formatDate( income.payed_at) }}</td>
                <td class="p-2 border-x" v-else></td>
                <td class="p-2 border-x">{{ formatBool(income.taxable) }}</td>
                <td class="p-2 border-x" style="font-size: 12px;">{{ income.created_by ? income.created_by.name : '' }} <br> <span>{{ formatDate(income.created_at) }}</span></td>
                <td class="p-2 border-l">
                  <div class="flex">
                    <r-e-edit-button
                      class="mr-2"
                      :to="{
                        name: 'income_edit',
                        params: {
                          id: income.id,
                        },
                      }"
                    />

                    <r-e-detail-button class="mr-2" @buttonClicked="openDetails(income)"/>
                  </div>
                </td>
              </tr>
            </table>
          </div>
      </div>
    </div> -->

    <!-- <div v-if="showOutcomesModal" class="flex flex-col justify-center items-center fixed z-20 inset-0 bg-opacity-25 bg-black">
      <div class="form relative" style="width: 80%;">
          <div>
            <button class="link-button mt-4 float-right" style="width: 100%; clear:both; text-align: right;" @click.prevent="showOutcomesModal = false">
              Cerrar
            </button>
            <table class="details-table">
              <thead class="text-left">
                <tr class="border-b">
                  <th class="p-2 border-r">ID</th>
                  <th class="p-2 border-x">Monto</th>
                  <th class="p-2 border-x">Cuenta</th>
                  <th class="p-2 border-x">Categoría</th>
                  <th class="p-2 border-x">Subcategoría</th>
                  <th class="p-2 border-x">Fecha de pago</th>
                  <th class="p-2 border-x">Ingresado Por</th>
                  <th class="p-2 border-l">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="expense in selectedMonth.outcome_payments" :key="expense.id">
                  <td class="p-2 border-r">{{ expense.id }}</td>
                  <td class="p-2 border-x">{{ formatPrice(expense.amount) }}</td>
                  <td class="p-2 border-x">{{ expense.bank_account.name }}</td>
                  <td class="p-2 border-x">{{ expense.category ? expense.category.name : '' }}</td>
                  <td class="p-2 border-x">{{ expense.subcategory ? expense.subcategory.name : ''}}</td>
                  <td class="p-2 border-x" v-if="expense.payed_at">{{ formatDate( expense.payed_at) }}</td>
                  <td class="p-2 border-x" v-else></td>
                  <td class="p-2 border-x" style="font-size: 12px;">{{ expense.created_by ? expense.created_by.name : '' }} <br> <span>{{ formatDate(expense.created_at) }}</span></td>
                  <td class="p-2 border-l">
                    <div class="flex">
                      <r-e-edit-button
                        class="mr-2"
                        :to="{
                          name: 'expenses_edit',
                          params: {
                            id: expense.id,
                            expense: expense,
                          },
                        }"
                      />

                      <r-e-detail-button class="mr-2" @buttonClicked="openDetails(expense)"/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div> -->

    <table class="table-auto mt-8 text-sm md:text-base w-full">
      <thead class="text-left">
        <tr class="border-b">
          <th class="p-2 border-r"></th>
          <th class="p-2 border-x">ENE</th>
          <th class="p-2 border-x">FEB</th>
          <th class="p-2 border-x">MAR</th>
          <th class="p-2 border-x">ABR</th>
          <th class="p-2 border-x">MAY</th>
          <th class="p-2 border-x">JUN</th>
          <th class="p-2 border-x">JUL</th>
          <th class="p-2 border-x">AGO</th>
          <th class="p-2 border-x">SEP</th>
          <th class="p-2 border-x">OCT</th>
          <th class="p-2 border-x">NOV</th>
          <th class="p-2 border-x">DIC</th>
          <th class="p-2 border-x">Total</th>
        </tr>
      </thead>
      <tbody v-if="cuts">
        <tr>
          <td class="p-2 border-r">Ingreso facturado (cuenta)</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_income_taxed) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_income_taxed) }}</td>
        </tr>
        <tr>
          <td class="p-2 border-r">Ingreso No facturado (efectivo)</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_income_no_taxed) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_income_no_taxed) }}</td>
        </tr>
        <tr class="gray-background">
          <td class="p-2 border-r">Venta total</td>
          <td class="p-2 border-r" style="cursor: pointer;" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_sale) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_sale) }}</td>
        </tr>
        <tr>
          <td class="p-2 border-r">Reinversión</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_reinvesment) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_reinvesment) }}</td>
        </tr>
        <tr class="yellow-background">
          <td class="p-2 border-r">Ingreso Total</td>
          <td class="p-2 border-r" style="cursor: pointer;" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_income) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_income) }}</td>
        </tr>
        <tr class="gray-background">
          <td class="p-2 border-r">Egreso Operaciones</td>
          <td class="p-2 border-r" style="cursor: pointer;" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_operation_outcome) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_operation_outcome) }}</td>
        </tr>
        <tr>
          <td class="p-2 border-r">Retorno de Inversión</td>
          <td class="p-2 border-r" style="cursor: pointer;" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_outcome_investment) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_outcome_investment) }}</td>
        </tr>
        <tr class="yellow-background">
          <td class="p-2 border-r">Egreso Total</td>
          <td class="p-2 border-r" style="cursor: pointer;" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_outcome) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_outcome) }}</td>
        </tr>
        <tr class="gray-background">
          <td class="p-2 border-r">Utilidad Bruta</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_brute_utility) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_brute_utility) }}</td>
        </tr>
        <tr class="yellow-background">
          <td class="p-2 border-r">Utilidad Neta</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_net_utility) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_net_utility) }}</td>
        </tr>
        <tr>
          <td class="p-2 border-r">Utilidad Entregada</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_utility_withdrawal) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_utility_withdrawal) }}</td>
        </tr>
        <tr>
          <td class="p-2 border-r">Utilidad Total (balance)</td>
          <td class="p-2 border-r" v-for="month in cuts.months" :key="month">{{ formatPrice(month.total_utility) }}</td>
          <td class="p-2 border-r bold-text">{{ formatPrice(cuts.year.total_utility) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { integerToCurrency } from "@/utils/currency";
import clientRequest from "@/api/clientRequest";
import LoadingModal from "@/components/LoadingModal.vue";
import moment from "moment";
import activeFiltersMixin from "@/mixins/activeFilters";
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: "Ingresos y Egresos",

  mixins: [activeFiltersMixin],
  components: {
    LoadingModal,
    SelectInput
  },

  data() {
    return {
      cuts: null,
      showIncomesModal: false,
      showOutcomesModal: false,
    };
  },

  created() {
    this.$store.commit("setFinancesSelectedTab", "income_outcome");
    this.getFilters();
    this.loadFinances();
  },

  methods: {
    getFilters() {
      let filters = this.getActiveFilters();
    },
    async loadFinances() {
      this.loading = true;

      let params = {
        filter_year: this.filter_year,
      };
      this.setActiveFilters(params);

      try {
        this.cuts = null;
        this.cuts = (await clientRequest.getFinancesCuts(params)).data.data;
      } catch (error) {
        console.log('error', error)
      }

      this.loading = false;
    },

    openIncomes(month) {
      this.selectedMonth = month;
      this.showIncomesModal = true;
    },

    openOutcomes(month) {
      this.selectedMonth = month;
      this.showOutcomesModal = true;
    },

    formatPrice(price) {
      return integerToCurrency(price);
    },
    formatDate(date) {
      return moment(date).locale("es").format("LL");
    },
    formatBool(val) {
      return val ? "Si" : "No";
    },
  },
};
</script>

<style>
.bold-text {
  font-weight: bold;
}
.yellow-background {
  background-color: #f4fd68;
}
.gray-background {
  background-color: #ececec;
}
</style>