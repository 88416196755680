<template>
  <div class="w-full">
    <div class="flex w-full justify-evenly py-4 flex-wrap">
      <router-link
        class="title button-interactive m-2"
        v-for="tab in tabs"
        :key="tab.route"
        :to="{ name: tab.route }"
        :class="[
          { 'text-secondary-500 underline': tab.route === reportsSelectedTab },
        ]"
        >{{ tab.name }}</router-link
      >
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Reportes",

  data() {
    return {
      tabs: [
        {
          name: "Canal de ventas",
          route: "sale_channel",
        },
        {
          name: "Ventas",
          route: "sales_months",
        },
        {
          name: "Eventos por tipo",
          route: "events_by_type",
        },
        {
          name: "Eventos por planner",
          route: "events_by_client",
        },
        {
          name: "Número de eventos",
          route: "sales_months",
        },
        {
          name: "Ventas por mes",
          route: "sales_months",
        },
        {
          name: "Ingresos por mes",
          route: "income_by_month",
        },
      ]
    };
  },

  computed: {
    breadcrumRoutes() {
      var routes = [];
      this.$route.matched.forEach((route, index) => {
        if (index !== 0 && index !== 1) {
          routes.push(route);
        }
      });
      return routes;
    },
  },

  created() {
    this.$store.commit('setReportsSelectedTab', 'reports');
    this.$store.commit("setSelectedTab", "reports");
  },
};
</script>