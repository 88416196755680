<template>
  <div>
    <form action="">
      <div class="w-full flex flex-wrap">
        <div class="flex flex-col w-72 mt-6 pr-4">
          <r-e-label for="client" value="Nombre" />
          <r-e-input id="client" type="text" v-model="form.name" />
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <r-e-label for="description" value="Descripción" />
        <textarea
          class="
            border border-gray-700
            py-2
            px-3
            outline-none
            hover:border-secondary-500
            focus:border-secondary-500
            smooth
          "
          v-model="form.description"
          id="description"
        ></textarea>
      </div>

      <r-e-primary-button class="w-72 mt-6" @buttonClicked="save"
        >Guardar</r-e-primary-button
      >
    </form>
  </div>
</template>

<script>
import clientRequest from "../../../../api/clientRequest";
import { is422 } from "../../../../utils/response";

export default {
  name: "Formulario",

  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        description: "",
      },
    };
  },

  created() {
    if (this.$route.params.category) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.$route.params.category));
    }
  },

  methods: {
    save() {
      if (!this.isEditing) {
        this.saveCategory();
      } else {
        this.updateCategory();
      }
    },

    async saveCategory() {
      this.loading = true;

      try {
        await clientRequest.storePaymentCategories(this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "categories_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }
    },

    async updateCategory() {
      this.loading = true;

      try {
        await clientRequest.updatePaymentCategories(this.form.id, this.form);

        this.$store.dispatch("successSnack", "Guardado exitosamente.");

        this.$router.push({ name: "categories_index" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch(
            "errorSnack",
            "Error, favor de contactar a soporte."
          );
        }
      }
    },
  },
};
</script>