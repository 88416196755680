<template>
  <div class="">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteFileModal"
        @close="showDeleteFileModal = false"
        @confirmDelete="deleteFile"
      ></delete-modal>
    </transition>

    <div v-if="event">
      <div class="md:columns-2 sm:columns-1 shadow-lg my-3 p-4 rounded w-full border">
        <h3 class="font-bold text-lg">Archivos</h3>

        <ul class="file-list" style="margin-bottom: 50px;">
          <li v-for="file in event.files" v-bind:key="file.id">
            <a :href="file.url" target="_blank">{{ file.name }}</a>
            &nbsp;<r-e-delete-button @buttonClicked="openDeleteFile(file)" />
          </li>
        </ul>

        <div>
          <input type="file" @change="handleFileUpload($event)">
          <r-e-primary-button
            class="mt-4"
            @buttonClicked="submitFile"
            >Subir archivo
          </r-e-primary-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { integerToCurrency } from "@/utils/currency";
import clientRequest from "@/api/clientRequest";
import { is422 } from "@/utils/response";
import deleteMixin from "@/mixins/deleteMixin";

export default {
  name: "EventFiles",

  props: {
    setEvent: Object,
  },

  mixins: [deleteMixin],

  data() {
    return {
      event: {},
      files: [],
      file: {},
      response: null,
      user: null,
      showDeleteFileModal: false,
      selectedFile: {},
    };
  },

  created() {
    this.user = this.$store.state.user;
    this.event = this.setEvent;
  },

  methods: {
    formatDate(date) {
      return moment(date).locale("es").format("LLL");
    },
    formatPrice(price) {
      return integerToCurrency(price);
    },
    formatPercentage(percentage) {
      return percentage + "%";
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    async submitFile() {
      try{
        let response = await clientRequest.uploadEventFile(this.event.id, this.file);
        this.event.files = response.data.data.files;
      } catch (error) {
        console.log(error);
      }
    },

    openDeleteFile(file) {
      this.selectedFile = file;
      this.showDeleteFileModal = true;
    },

    async deleteFile() {
      this.loading = true;

      try {
        let response = await clientRequest.deleteEventFile(this.selectedFile.id);
        this.event.files = response.data.data.files;
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.showDeleteFileModal = false;
    },
  },
};
</script>